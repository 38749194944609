const LOG_LEVEL: string = process.env.LOG_LEVEL || "INFO"

enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARNING = 2,
  ERROR = 3,
}

const currentLogLevel: LogLevel = LogLevel[LOG_LEVEL.toUpperCase() as keyof typeof LogLevel] || LogLevel.INFO

function logDebug(...args: unknown[]): void {
  if (currentLogLevel <= LogLevel.DEBUG) {
    console.debug("DEBUG:", ...args)
  }
}

function logInfo(...args: unknown[]): void {
  if (currentLogLevel <= LogLevel.INFO) {
    console.info("INFO:", ...args)
  }
}

function logWarning(...args: unknown[]): void {
  if (currentLogLevel <= LogLevel.WARNING) {
    console.warn("WARNING:", ...args)
  }
}

function logError(...args: unknown[]): void {
  if (currentLogLevel <= LogLevel.ERROR) {
    console.error("ERROR:", ...args)
  }
}

export const Logger = {
  debug: logDebug,
  info: logInfo,
  warning: logWarning,
  error: logError,
}
