import { devtoolsExchange } from "@urql/devtools"
import { authExchange } from "@urql/exchange-auth"
import { cacheExchange, Entity, Link } from "@urql/exchange-graphcache"
import { relayPagination } from "@urql/exchange-graphcache/extras"
import { refocusExchange as visibilityChangeExchange } from "@urql/exchange-refocus"
import { parseISO } from "date-fns"
import { IntrospectionQuery } from "graphql"
import { createClient, Exchange, fetchExchange, gql, mapExchange } from "urql"
import customScalarsExchange from "urql-custom-scalars-exchange"
import { refreshSession } from "../data/api"
import { EXPIRED_TOKEN, INVALID_SESSION } from "../graphql/errors"
import {
  AssetAssignableType,
  MutationBulkDeleteAssetsArgs,
  MutationDeleteOneAssetArgs,
  MutationDismissAssetRepairArgs,
  MutationReportAssetRepairArgs,
  MutationSwitchDivisionArgs,
  ProjectStatus,
  QueryAssetsArgs,
} from "../graphql/generated/client-types-and-hooks"
import { GraphCacheConfig, UnitGoal, UserAssignment } from "../graphql/generated/graphcache"
import introspectionSchema from "../graphql/generated/introspection.json"
import { UserSearchFilter } from "../graphql/types/User"
import { Available } from "../helpers/assets/assetStatus"
import { notNil } from "../helpers/util-functions"
import { FeatureFlagContextValue } from "../providers/DevelopmentFeatureFlagProvider"
import { SessionContextValue } from "../providers/SessionProvider"
import { getSessionExp, sessionKeys, shouldRefresh } from "./jwtHelpers"
import { handleDeleteAsset } from "./urql/cache/deleteAssets"
import { insertManyAssetReports } from "./urql/cache/insertManyAssetReports"
import { reassignUser } from "./urql/cache/reassignUser"
import { reassignUsers } from "./urql/cache/reassignUsers"
import { transferAssets } from "./urql/cache/transferAssets"
import { invalidateAssetCache } from "./urql/cache/utils/asset.util"
import { createOrEditDivisionCache } from "./urql/cache/utils/divisions.util"
import { handleAssetRepairReport } from "./urql/cache/utils/handleAssetRepairReport"
import { invalidateProjectCache } from "./urql/cache/utils/project.util"
import { invalidateTaskCache } from "./urql/cache/utils/task.util"
import {
  addManyToQuery,
  addToQuery,
  linkUserAssignment,
  nullToUndefined,
  removeFromQuery,
  updateImageCache,
  updateOrganizationClockedInCount,
} from "./urql/cacheHelpers"
import { refocusExchange } from "./urql/exchanges/focus"

const scalarsExchange = customScalarsExchange({
  schema: introspectionSchema as unknown as IntrospectionQuery,
  scalars: {
    DateTime(value: string) {
      return parseISO(value)
    },
  },
})

export const urqlClient = (
  session: SessionContextValue,
  { flagIsEnabled: _flagIsEnabled }: FeatureFlagContextValue
) => {
  const exchanges = [
    visibilityChangeExchange(),
    scalarsExchange,

    // The `GraphCacheConfig` type was broken recently so it no longer is properly typed for 'not
    // offline' uses.  I've got a bug/comment in w/ the author to see if there's a reason this is the case.
    // https://github.com/dotansimha/graphql-code-generator-community/pull/338
    cacheExchange<GraphCacheConfig>({
      schema: introspectionSchema as unknown as IntrospectionQuery,
      keys: {
        AssetBillingClassification: (data) => data.id || null,
        AssetGroup: (data) =>
          data.compositeKey || [data.assetGroupId, data.assignableId, data.assignableType, data.status].join("|"),
        AssetInventoryRequirements: () => null,
        AssetManufacturer: () => null,
        AssetPurchaseDetails: () => null,
        AssetRentalAgreement: () => null,
        AssetRentalAgreementRate: () => null,
        AssetRepairRequest: (data) => `repair:${data.assetId}:${data.inspectionFieldId}`,
        AssetReportInventoryReport: () => null,
        AssetReportInspectionSubmission: () => null,
        AssetStatusChange: () => null,
        Customer: (data) => data.id || null,
        Division: (data) => `${data.organizationId}:${data.id}`,
        AssetReportTransferAssignment: () => null,
        AssetReportTransferReport: () => null,
        AssetVendorContact: () => null,
        TaskListItem: (data) => `task:${data.taskId};taskGroup:${data.taskGroupId}`,
        QueryUsersConnectionEdge: (data) => data.node!.id,
        UserBillingClassification: (data) => data.id || null,
        // Offline event fields
        ClockInData: () => null,
        ClockOutData: () => null,
        // Task dependency nested data
        TaskDependencyData: () => null,
        // Other fields from the console errors
        MetadataNote: () => null,
        UnitOfMeasure: (data) => data.id?.toString() || null,
        Vendor: (data) => data.id || null,
        VendorContact: (data) => data.id || null,
        ScheduleWorkDay: () => null,
        ScheduleWorkHours: () => null,
      },
      resolvers: {
        Query: {
          assets_2: relayPagination(),
        },
      },
      optimistic: {
        createCustomer: (args) => ({ __typename: "Customer", id: new Date().toISOString(), ...args }),
        createUserAssignment: (args) => ({ __typename: "UserAssignment", id: new Date().toString(), ...args }),
        createVendorContact: (args) => ({ __typename: "VendorContact", id: new Date().toISOString(), ...args }),
        createWorkersCompCode: (args) => ({ __typename: "WorkersCompCode", id: new Date().toString(), ...args }),
        editOrganization: (args, _cache) => ({
          ...nullToUndefined(args),
          __typename: "Organization",
        }),
        editScheduledBreak: (args, _cache) => ({
          ...nullToUndefined(args),
          __typename: "ScheduledBreak",
        }),
        insertOneProject: (args, _cache) => {
          const dateStr = new Date().toISOString()
          return {
            id: dateStr,
            createdAt: dateStr,
            isDefault: false,
            __typename: "Project",
            name: args.name,
            description: args.description,
          }
        },
        insertOneTask: (args, _cache) => ({
          ...args,
          scheduledBreaks: args.scheduledBreaks ? JSON.parse(JSON.stringify(args.scheduledBreaks)) : undefined,
          isDefault: false,
          unitGoals: (args.unitGoals as UnitGoal[]) || undefined,
          schedule: args.schedule ? JSON.parse(JSON.stringify(args.schedule)) : undefined,
          __typename: "Task",
        }),
        reassignUsers: (args, cache) => {
          cache.invalidate("Query", "users", args)

          return args.assignments.map((a) => ({
            __typename: "User",
            id: a.userId,
            taskId: a.taskId,
          }))
        },
        restoreOneAsset: (args, _cache) => {
          return { ...args, updatedAt: new Date(), deletedAt: null, __typename: "Asset" }
        },
        updateOneAsset: (args, _cache, _info) => {
          return {
            ...nullToUndefined(args),
            __typename: "Asset",
            updatedAt: new Date(),
          }
        },
      },
      updates: {
        // TL;DR;
        // - Updates should include as much data as you can in order to "fix" the cache and shouldn't require any update logic here
        // - Deletes should just invalidate which removes it from the cache entirely
        // - Creates can just be "added" by pushing the new thing into anything that renders/links them
        Mutation: {
          insertManyAssetReports,
          reassignUser,
          reassignUsers,
          reportAssetRepair: handleAssetRepairReport<MutationReportAssetRepairArgs>,
          dismissAssetRepair: handleAssetRepairReport<MutationDismissAssetRepairArgs>,
          transferAssets,
          switchDivisionForAsset: handleDeleteAsset<MutationSwitchDivisionArgs>,
          deleteOneAsset: handleDeleteAsset<MutationDeleteOneAssetArgs>,
          bulkDeleteAssets: handleDeleteAsset<MutationBulkDeleteAssetsArgs>,
          createDivision: (_result, _args, cache) => createOrEditDivisionCache(cache),
          editDivision: (_result, _args, cache) => createOrEditDivisionCache(cache),
          editOrganization: (result, args, cache) => {
            cache.invalidate({ __typename: result.editOrganization.__typename, id: args.id })
            cache.invalidate("Query", "settings")

            const organizationEditKey = cache.keyOfEntity({ __typename: "Organization", id: args.id })
            const myOrganizationKey = cache.resolve("Query", "myOrganization")
            if (organizationEditKey === myOrganizationKey) {
              createOrEditDivisionCache(cache)
            }
          },
          grantDivisionAccessToUser: (_, args, cache) => {
            cache.invalidate({ __typename: "User", id: args.userId })
            cache.invalidate({ __typename: "Organization", id: args.organizationId })
            cache.invalidate("Query", "myDivision")
            cache.invalidate("Query", "myDivisions")
          },
          revokeDivisionAccessFromUser: (_, args, cache) => {
            cache.invalidate({ __typename: "User", id: args.userId })
            cache.invalidate({ __typename: "Organization", id: args.organizationId })
            cache.invalidate("Query", "myDivision")
            cache.invalidate("Query", "myDivisions")
          },
          switchDivisionAssignmentForUser: (_, args, cache) => {
            cache.invalidate({ __typename: "User", id: args.userId })
            cache.invalidate({ __typename: "Organization", id: args.organizationId })
            cache.invalidate("Query", "myDivision")
            cache.invalidate("Query", "myDivisions")
          },
          createTaskDependency: (_result, _args, cache) => {
            // From a Gantt perspective, we need to invalidate the project cache because this action can change the task's start/end dates.
            // Since task dates are calculated from a project level, we need to recalculate all dates for every task in the project
            invalidateProjectCache(cache)
          },
          updateTaskDependency: (_result, _args, cache) => {
            // From a Gantt perspective, we need to invalidate the project cache because this action can change the task's start/end dates.
            // Since task dates are calculated from a project level, we need to recalculate all dates for every task in the project
            invalidateProjectCache(cache)
          },
          deleteTaskDependency: (_result, _args, cache) => {
            // From a Gantt perspective, we need to invalidate the project cache because this action can change the task's start/end dates.
            // Since task dates are calculated from a project level, we need to recalculate all dates for every task in the project
            invalidateProjectCache(cache)
          },
          insertOneTimeEntry: (result, args, cache) => {
            cache.invalidate("Query", "user", { id: result.insertOneTimeEntry.userId ?? "" })
            return { ...args, ...result.insertOneTimeEntry }
          },
          activateOrganization: (result, args, cache) => {
            cache.invalidate("Query", "organizations", { archived: true })
            cache.invalidate("Query", "organizations", { archived: false })
            return { ...args, ...result.activateOrganization }
          },
          addOrUpdateNonWorkDay: (result, args, cache) => {
            const updatedTemplate = { ...args, ...result.addOrUpdateNonWorkDay, __typename: "Schedule" }

            const queries = cache.inspectFields("Query").filter((field) => field.fieldName === "schedules")

            queries.forEach((query) => {
              const data = cache.resolve("Query", query.fieldKey)

              if (data && Array.isArray(data)) {
                data.push(updatedTemplate)
                cache.link("Query", query.fieldKey, data as Link<Entity>)
              }
            })
          },
          addQuantitiesToGroup: (result, _args, cache) => {
            const key = cache.keyOfEntity(result.addQuantitiesToGroup)

            const inventoryGroup = cache.resolve(key, "childAssetGroups")

            // updates the inventory group
            if (Array.isArray(inventoryGroup)) {
              inventoryGroup.push(result.addQuantitiesToGroup)
              cache.link(key, "assetGroups", result.addQuantitiesToGroup as Link<Entity>)

              // updates the other groups for this asset
              cache
                .inspectFields("Query")
                .filter(
                  (query) => query.fieldName === "assetGroups" && query.arguments?.assetGroupId === _args.assetGroupId
                )
                // we have to invalidate the specific assetGroup since prisma.createMany() doesn't return the created assets
                .forEach((query) => cache.invalidate("Query", query.fieldKey))
            }
          },
          archiveQuantities: (result, args, cache) => {
            const keys = result.archiveQuantities.map((asset) => ({
              __typename: "Asset",
              id: asset.id || args.assetGroupId,
            }))

            cache
              .inspectFields("Query")
              .filter((query) => query.fieldName === "assetGroups")
              .forEach((query) => {
                addManyToQuery(cache, query, keys)
              })
          },
          archiveOrganization: (result, args, cache) => {
            cache.invalidate("Query", "organizations", { archived: true })
            cache.invalidate("Query", "organizations", { archived: false })
            return { ...args, ...result.archiveOrganization }
          },
          bulkClockIn: (result, args, cache) => {
            const successes = args.candidates.filter(
              (candidate) => !result.bulkClockIn.errors?.map(({ userId }) => userId).includes(candidate.userId)
            )

            successes.map((candidate) => {
              cache.writeFragment(
                gql`
                  fragment _ on QueryUsersConnectionEdge {
                    node {
                      id
                      isClockedIn
                    }
                  }
                `,
                { node: { id: candidate.userId, isClockedIn: true }, __typename: "QueryUsersConnectionEdge" }
              )

              cache.writeFragment(
                gql`
                  fragment _ on User {
                    id
                    isClockedIn
                  }
                `,
                {
                  id: candidate.userId,
                  isClockedIn: true,
                  __typename: "User",
                }
              )
            })

            updateOrganizationClockedInCount(cache, successes.length)
          },
          bulkClockOut: (result, args, cache) => {
            const successes = args.candidates.filter(
              (candidate) => !result.bulkClockOut.errors?.map(({ userId }) => userId).includes(candidate.userId)
            )

            successes.map((candidate) => {
              cache.writeFragment(
                gql`
                  fragment _ on QueryUsersConnectionEdge {
                    node {
                      id
                      isClockedIn
                    }
                  }
                `,
                { node: { id: candidate.userId, isClockedIn: false }, __typename: "QueryUsersConnectionEdge" }
              )

              cache.writeFragment(
                gql`
                  fragment _ on User {
                    id
                    isClockedIn
                  }
                `,
                {
                  id: candidate.userId,
                  isClockedIn: false,
                  __typename: "User",
                }
              )
            })

            updateOrganizationClockedInCount(cache, -successes.length)
          },
          bulkUpdateUserAssignments: (_r, args, cache, _i) => {
            args.assignmentsToDelete?.forEach((assignmentId) =>
              cache.invalidate({ __typename: "UserAssignment", id: assignmentId })
            )

            args.assignmentsToCreate?.forEach((assignment) => {
              const userKey = cache.keyOfEntity({ __typename: "User", id: assignment.userId })
              const projectKey = cache.keyOfEntity({ __typename: "Project", id: assignment.projectId })

              const newUserAssignment = {
                ...assignment,
                __typename: "UserAssignment",
                id: `${new Date().toISOString()}:u${assignment.userId}:p${assignment.projectId}`,
              } as UserAssignment

              linkUserAssignment(cache, newUserAssignment, userKey, projectKey)
            })
          },
          bulkUpdateTaskSortOrder: (_r, args, cache, _i) => {
            const { updates = [] } = args

            updates.forEach((update) => {
              const key = cache.keyOfEntity({ __typename: update.type, id: update.id })

              if (cache.resolve(key, "sortOrder")) {
                cache.writeFragment(
                  gql`
                    fragment _ on TaskListItem {
                      id
                      sortOrder
                    }
                  `,
                  {
                    id: update.id,
                    sortOrder: update.sortOrder,
                    __typename: "TaskListItem",
                  }
                )
              }
            })
          },
          clockIn: (result, _args, cache) => {
            if (result.clockIn) {
              const userData = result.clockIn.user
              cache.writeFragment(
                gql`
                  fragment _ on User {
                    id
                    isClockedIn
                    secondsClockedSinceOrgMidnight
                    latestTimeEntry {
                      id
                      endAt
                      evidence
                      startAt
                      taskId
                      userId
                    }
                  }
                `,
                {
                  id: result.clockIn.userId,
                  isClockedIn: userData?.isClockedIn || true,
                  latestTimeEntry: result.clockIn,
                  secondsClockedSinceOrgMidnight: userData?.secondsClockedSinceOrgMidnight,
                  __typename: "User",
                }
              )

              updateOrganizationClockedInCount(cache, 1)
            }
          },
          clockOutUser: (result, _args, cache) => {
            if (result.clockOutUser) {
              updateOrganizationClockedInCount(cache, -1)
            }
          },
          createAssetBillingClassification: (result, args, cache) => {
            const newModel = {
              ...args,
              ...result.createAssetBillingClassification,
              __typename: "AssetBillingClassification",
            }

            addToQuery(
              cache,
              {
                arguments: {},
                fieldName: "assetBillingClassifications",
                fieldKey: "assetBillingClassifications",
              },
              newModel
            )
          },
          createCustomer: (result, args, cache) => {
            const newModel = { ...args, ...result.createCustomer, __typename: "Customer" }

            addToQuery(
              cache,
              {
                arguments: {},
                fieldName: "customers",
                fieldKey: "customers",
              },
              newModel
            )
          },
          createDeliverableUnit: (result, args, cache) => {
            const newUnit = { ...args, ...result.createDeliverableUnit, __typename: "DeliverableUnit" }

            const query = cache
              .inspectFields("Query")
              .find((cachedQuery) => cachedQuery.fieldName === "deliverableUnits")

            if (query) {
              addToQuery(cache, query, newUnit)
            }
          },
          createOneContract: (result, args, cache) => {
            const newModel = { ...args, ...result.createOneContract, __typename: "Contract" }

            const queries = cache.inspectFields("Query")

            queries.forEach((query) => {
              if (query.fieldName !== "contracts") return
              if (query.arguments?.customerId !== args.customerId) return

              addToQuery(cache, query, newModel)
            })
          },
          createSchedule: (result, args, cache) => {
            const newSchedule = { ...args, ...result.createSchedule, __typename: "Schedule" }

            if (args.isDefault) {
              const fieldNames = ["schedules", "myOrganization", "project", "task"]

              const queries = cache.inspectFields("Query").filter((field) => fieldNames.includes(field.fieldName))

              queries.forEach((query) => {
                const data = cache.resolve("Query", query.fieldKey)

                if (data && Array.isArray(data)) {
                  data.push(newSchedule)
                  cache.link("Query", query.fieldKey, data as Link<Entity>)
                } else if (query.fieldName === "myOrganization") {
                  const myOrganizationData = cache.resolve("Query", query.fieldKey)

                  if (myOrganizationData) {
                    cache.link("Query", query.fieldKey, [newSchedule] as Link<Entity>)
                  }
                }
              })
            }

            if (!args.scheduledBreaks?.length) return

            args.scheduledBreaks.forEach((sb) => {
              const newBreak = { ...sb, __typename: "ScheduledBreak" }
              const data = cache.resolve("Query", "scheduledBreaks")
              const fieldNames = ["scheduledBreaks", "myOrganization", "project", "task"]

              const queries = cache.inspectFields("Query").filter((field) => fieldNames.includes(field.fieldName))

              queries.forEach((query) => {
                if (data && Array.isArray(data)) {
                  data.push(newBreak)
                  cache.link("Query", "scheduledBreaks", data as Link<Entity>)
                } else {
                  const breakData = cache.resolve("Query", query.fieldKey)

                  if (breakData) {
                    cache.link("Query", query.fieldKey, [newBreak] as Link<Entity>)
                  }
                }
              })
            })
          },
          createUser: (result, args, cache) => {
            const newUser = { ...args, ...result.createUser, __typename: "User" }
            const newEdge = {
              __typename: "QueryUsersConnectionEdge",
              node: newUser,
              cursor: btoa(`GPC:S:${newUser.id}`),
            }

            // Get all fields for "Query"
            const allFields = cache.inspectFields("Query")

            // Filter fields that match the "users" query
            const userQueries = allFields.filter((field) => field.fieldName === "users")

            userQueries.forEach((field) => {
              // Get the current cache data for the specific query
              const edges = cache.resolve(field.fieldKey, "edges")

              // If edges exist and are an array, update them
              if (edges && Array.isArray(edges)) {
                const newEdges = [...edges, newEdge]

                // Write the new edges array back to the cache
                cache.link(field.fieldKey, "edges", newEdges)
              }
            })
          },
          createUserAssignment: (result, args, cache) => {
            const userKey = cache.keyOfEntity({ __typename: "User", id: args.userId })
            const projectKey = cache.keyOfEntity({ __typename: "Project", id: args.projectId })

            const newUA = { ...args, ...result?.createUserAssignment, __typename: "UserAssignment" } as UserAssignment

            linkUserAssignment(cache, newUA, userKey, projectKey)

            return newUA
          },
          createUserNotification: (result, args, cache) => {
            // refetch the notifications count
            cache.invalidate("Query", "myNotifications", { take: 100 })

            // might as well update the user's notifications while we're at it
            const newNotification = { ...args, ...result.createUserNotification, __typename: "UserNotification" }
            const data = cache.resolve("Query", "myNotifications")

            if (data && Array.isArray(data)) {
              data.push(newNotification)
              cache.link("Query", "myNotifications", data as Link<Entity>)
            }
          },
          createUserBillingClassification: (result, args, cache) => {
            const newModel = {
              ...args,
              ...result.createUserBillingClassification,
              __typename: "UserBillingClassification",
            }

            addToQuery(
              cache,
              {
                arguments: {},
                fieldName: "userBillingClassifications",
                fieldKey: "userBillingClassifications",
              },
              newModel
            )
          },
          markAllNotificationsRead: (_result, _args, cache) => {
            // refetch the notifications count
            cache.invalidate("Query", "myNotifications", { take: 100 })

            // might as well update the user's notifications while we're at it
            const data = cache.resolve("Query", "myNotifications")

            if (data && Array.isArray(data)) {
              data.forEach((notification) => {
                notification.readAt = new Date().toISOString()
              })
              cache.link("Query", "myNotifications", data as Link<Entity>)
            }
          },
          markNotificationsReadById: (_result, _args, cache) => {
            // refetch the notifications count
            cache.invalidate("Query", "myNotifications", { take: 100 })
          },
          createUnitGoal: (result, args, cache) => {
            const newUnitGoal = { ...args, ...result.createUnitGoal, __typename: "UnitGoal" }
            if (!newUnitGoal.id) return
            const key = cache.keyOfEntity({ __typename: "Task", id: args.taskId })

            const data = cache.resolve(key, "unitGoals")
            if (data && Array.isArray(data)) {
              data.push(newUnitGoal)
              cache.link(key, "unitGoals", data as Link<Entity>)
            }
          },
          createUnitOfMeasure: (result, args, cache) => {
            const newUnitOfMeasure = { ...args, ...result.createUnitOfMeasure, __typename: "UnitOfMeasure" }
            // UnitsOfMeasure are queried through organization.unitsOfMeasure
            // 1. Get the organization cache key
            const key = cache.keyOfEntity({
              __typename: "Organization",
              id: result.createUnitOfMeasure.organizationId || "",
            })

            // 2. Get the unitsOfMeasure field from the Organization cache
            const data = cache.resolve(key, "unitsOfMeasure")

            // 3. Append the new item to the list and link the cache
            if (data && Array.isArray(data)) {
              data.push(newUnitOfMeasure)
              cache.link("Query", "unitsOfMeasure", data as Link<Entity>)
            }
          },
          createVendor: (result, args, cache) => {
            const newModel = { ...args, ...result.createVendor, __typename: "Vendor" }

            addToQuery(
              cache,
              {
                arguments: {},
                fieldName: "vendors",
                fieldKey: "vendors",
              },
              newModel
            )
          },
          createVendorContact: (result, args, cache) => {
            cache.invalidate("Query", "vendorContacts", { vendorId: args.vendorId })
            return { ...args, ...result, __typename: "VendorContact" }
          },
          createWorkersCompCode: (result, args, cache) => {
            const newCode = { ...args, ...result.createWorkersCompCode, __typename: "WorkersCompCode" }
            const data = cache.resolve("Query", "workersCompCodes")
            if (data && Array.isArray(data)) {
              data.push(newCode)
              cache.link("Query", "workersCompCodes", data as Link<Entity>)
            } else {
              cache.link("Query", "workersCompCodes", [newCode])
            }
          },
          deleteAssetBillingClassification: (_r, args, cache, _i) =>
            cache.invalidate({ __typename: "AssetBillingClassification", id: args.id }),
          deleteOneTask: (_result, _args, cache, _info) => {
            // From a Gantt perspective, we need to invalidate the project cache because this action can change the task's start/end dates.
            // Since task dates are calculated from a project level, we need to recalculate all dates for every task in the project
            invalidateProjectCache(cache)
          },
          deleteOneTimeEntry: (_result, args, cache) => cache.invalidate({ __typename: "TimeEntry", id: args.id }),
          deleteUnitGoal: (_result, args, cache) => cache.invalidate({ __typename: "UnitGoal", id: args.id }),
          deleteUnitOfMeasure: (result, _args, cache) => {
            // UnitsOfMeasure are queried through organization.unitsOfMeasure
            return cache.invalidate({ __typename: "Organization", id: result.deleteUnitOfMeasure.organizationId || "" })
          },
          deleteVendorContact: (_result, args, cache) => cache.invalidate({ __typename: "VendorContact", id: args.id }),
          // Remove the user from the list if it is an "active" userList status
          // Remove the user from the list if it is a "users" query with no archived filter
          deleteOneUser: (result, args, cache) => {
            cache.inspectFields("Query").forEach((query) => {
              let willAddToQuery: boolean | null = null
              if (query.fieldName === "usersList") {
                willAddToQuery = query.arguments?.status !== "active"
              } else if (query.fieldName === "users") {
                willAddToQuery = Boolean(((query.arguments?.filter || {}) as UserSearchFilter).archived)
              } else if (query.fieldName === "user" && query.arguments?.id === args.id) {
                willAddToQuery = true
              }

              if (notNil(willAddToQuery)) {
                if (willAddToQuery) {
                  const newUser = { ...args, ...result.deleteOneUser, __typename: "User" }
                  addToQuery(cache, query, newUser)
                } else {
                  const userKey = cache.keyOfEntity({ __typename: "User", id: args.id })
                  removeFromQuery(cache, query, userKey)
                }
              }
            })
          },
          deleteProjectScheduleAndBreaks: (_result, _args, cache) => {
            invalidateProjectCache(cache)
            invalidateTaskCache(cache)
          },
          deleteReportTemplate: (_result, args, cache) => {
            const key = cache.keyOfEntity({ id: args.id, __typename: "AssetReportTemplate" })
            const queries = cache
              .inspectFields("Query")
              .filter((field) => field.fieldName === "reusableAssetReportTemplates")

            queries.forEach((query) => removeFromQuery(cache, query, key))
          },
          deleteScheduledBreak: (_result, args, cache) =>
            cache.invalidate({ __typename: "ScheduledBreak", id: args.id }),
          deleteUserAssignment: (_r, args, cache, _i) =>
            cache.invalidate({ __typename: "UserAssignment", id: args.id }),
          deleteUserBillingClassification: (_r, args, cache, _i) =>
            cache.invalidate({ __typename: "UserBillingClassification", id: args.id }),
          deleteUserDeviceSession: (_r, args, cache, _i) =>
            cache.invalidate({ __typename: "UserDeviceSession", id: args.deviceSessionId }),
          duplicateReportTemplate: (result, _args, cache) => {
            const newTemplate = { ...result.duplicateReportTemplate, __typename: "AssetReportTemplate" }
            const data = cache.resolve("Query", "reusableAssetReportTemplates")

            if (data && Array.isArray(data)) {
              data.push(newTemplate)
              cache.link("Query", "reusableAssetReportTemplates", data as Link<Entity>)
            }
          },
          editUnitGoal: (result, args, _cache) => {
            return { ...args, ...result.editUnitGoal }
          },
          editUnitOfMeasure: (result, _args, cache) => {
            // UnitsOfMeasure are queried through organization.unitsOfMeasure
            cache.invalidate({
              __typename: result.editUnitOfMeasure.__typename,
              id: result.editUnitOfMeasure.organizationId || "",
            })
          },
          insertOneAsset: (result, args, cache, _info) => {
            const newAsset = { ...args, ...result.insertOneAsset, __typename: "Asset" }

            if (newAsset.assignableId && newAsset.assignableType === "Asset") {
              const assetKey = cache.keyOfEntity({ __typename: "Asset", id: newAsset.assignableId })
              const asset = cache.resolve(assetKey, "childAssetGroups")
              if (Array.isArray(asset)) {
                asset.push(newAsset)
                cache.link(assetKey, "childAssetGroups", asset as Link<Entity>)
              }
            } else {
              invalidateAssetCache(cache)
            }

            return newAsset
          },
          insertOneProject: (result, args, cache, _info) => {
            const newProject = { ...args, ...result.insertOneProject, __typename: "Project" }
            const projectsByStatusKey = cache.keyOfField("projectsByStatus", { status: ProjectStatus.Active })
            const projectsByStatusActive = cache.resolve("Query", projectsByStatusKey!)
            const activeProjects = cache.resolve("Query", "activeProjects")
            if (Array.isArray(projectsByStatusActive)) {
              projectsByStatusActive.push(newProject)
              cache.link("Query", projectsByStatusKey!, projectsByStatusActive as Link<Entity>)
            }
            if (Array.isArray(activeProjects)) {
              activeProjects.push(newProject)
              cache.link("Query", "activeProjects", activeProjects as Link<Entity>)
            }
            return newProject
          },
          insertOneTask: (result, args, cache) => {
            // From a Gantt perspective, we need to invalidate the project cache because this action can change the task's start/end dates.
            // Since task dates are calculated from a project level, we need to recalculate all dates for every task in the project
            invalidateProjectCache(cache)
            return { ...args, ...result, __typename: "Task" }
          },
          insertManyTasks: (result, args, cache) => {
            // From a Gantt perspective, we need to invalidate the project cache because this action can change the task's start/end dates.
            // Since task dates are calculated from a project level, we need to recalculate all dates for every task in the project
            invalidateProjectCache(cache)
            return { ...args, ...result, __typename: "Task" }
          },
          insertReportTemplate: (result, args, cache) => {
            const newTemplate = { ...args, ...result.insertReportTemplate, __typename: "AssetReportTemplate" }

            const queries = cache
              .inspectFields("Query")
              .filter((field) => field.fieldName === "reusableAssetReportTemplates")

            queries.forEach((query) => {
              const data = cache.resolve("Query", query.fieldKey)
              if (data && Array.isArray(data)) {
                data.push(newTemplate)
                cache.link("Query", query.fieldKey, data as Link<Entity>)
              }
            })
          },
          markTaskCompletion: (result, args, cache, _info) => {
            const { markTaskCompletion: completedTask } = result
            cache.invalidate({ ...args, __typename: "Task" })

            const fragment = gql`
              fragment _ on TaskListItem {
                taskId
                taskGroupId
                isComplete
                completedTaskCount
              }
            `
            const isTaskComplete = Boolean(completedTask?.isComplete)

            // Update the task
            cache.writeFragment(fragment, {
              taskId: args.id,
              taskGroupId: null,
              isComplete: isTaskComplete,
              completedTaskCount: isTaskComplete ? 1 : 0,
              __typename: "TaskListItem",
            })

            // If there is a group, update it too
            if (completedTask?.groupId) {
              const existingTaskItem = cache.readFragment(
                fragment,
                `TaskListItem:task:null;taskGroup:${completedTask.groupId}`
              )

              const completedTaskCount = isTaskComplete
                ? existingTaskItem?.completedTaskCount + 1
                : existingTaskItem?.completedTaskCount - 1

              cache.writeFragment(fragment, {
                taskId: null,
                taskGroupId: completedTask.groupId,
                isComplete: isTaskComplete,
                completedTaskCount,
                __typename: "TaskListItem",
              })
            }
          },

          reportTaskProgress: (_result, _args, cache) => {
            invalidateProjectCache(cache)
          },
          restoreOneAsset: (result, args, cache, _info) => {
            const restoredAsset = { ...args, ...result.restoreOneAsset, __typename: "Asset" }
            const key = cache.keyOfEntity(restoredAsset)

            const assetQueries = cache.inspectFields("Query").filter((field) => field.fieldName === "assets")

            assetQueries.forEach((query) => {
              const { deleted } = (query?.arguments as QueryAssetsArgs) || {}
              let data = cache.resolve("Query", query.fieldKey)

              if (data && Array.isArray(data)) {
                if (!deleted) data.push(restoredAsset)
                if (deleted) data = data?.filter((k) => k !== key)
                cache.link("Query", query.fieldKey, data as Link<Entity>)
              }
            })

            return { ...args, ...result, __typename: "Asset" }
          },

          restoreOneUser: (result, args, cache) => {
            const userListQueryNames = ["users", "usersList"]
            const userKey = cache.keyOfEntity({ __typename: "User", id: args.id })
            const newUser = { ...args, ...result.restoreOneUser }

            const userQueries = cache
              .inspectFields("Query")
              .filter((field) => userListQueryNames.includes(field.fieldName))

            userQueries.forEach((query) => {
              // Remove the user from the list if it is an "active" userList status OR if it is a "users" query with no archived filter
              query.fieldName === "usersList" && query.arguments?.status === "active"
                ? addToQuery(cache, query, newUser)
                : removeFromQuery(cache, query, userKey)
              query.fieldName === "users" && ((query.arguments?.filter || {}) as UserSearchFilter).archived
                ? removeFromQuery(cache, query, userKey)
                : addToQuery(cache, query, newUser)
            })
          },
          returnQuantityToInventory: (_result, args, cache) => {
            const key = cache.keyOfEntity({ ...args, __typename: "AssetGroup" })

            const assetGroupFragment = gql`
              fragment _ on AssetGroup {
                assetGroupId
                assignableId
                assignableType
                status
                count
              }
            `
            // get the assetGroup we're returning
            const data = cache.readFragment(assetGroupFragment, args)
            const newCount = (data?.count || 0) - args.quantityToReturn

            // if there is at least one asset left in this group, update the count
            if (newCount >= 1) {
              cache.writeFragment(assetGroupFragment, {
                ...args,
                count: newCount,
                __typename: "AssetGroup",
              })
            }

            // otherwise, remove the grouped asset from the list
            if (newCount < 1) {
              cache
                .inspectFields("Query")
                .filter((query) => query.fieldName === "assetGroups")
                .forEach((query) => {
                  const queryData = cache.resolve("Query", query.fieldKey)

                  if (queryData && Array.isArray(queryData)) {
                    const filteredData = queryData.filter((cacheKey) => cacheKey !== key)
                    cache.link("Query", query.fieldKey, filteredData)
                  }
                })
            }

            // inventory row is where assetGroupId === assignableId && assignableType === 'Asset'
            const inventoryGroupArgs = {
              ...args,
              assignableId: args.assetGroupId,
              assignableType: AssetAssignableType.Asset,
              status: Available,
            }

            // update the count on the inventory row
            const inventoryData = cache.readFragment(assetGroupFragment, inventoryGroupArgs)
            const newInventoryCount = (inventoryData?.count || 0) + args.quantityToReturn

            cache.writeFragment(assetGroupFragment, {
              ...inventoryGroupArgs,
              count: newInventoryCount,
            })
          },

          unarchiveQuantities: (_result, args, cache) => {
            const key = cache.keyOfEntity({ ...args, __typename: "AssetGroup" })

            const fragment = gql`
              fragment _ on AssetGroup {
                assetGroupId
                assignableId
                assignableType
                status
                count
              }
            `
            const data = cache.readFragment(fragment, args)
            const newCount = (data?.count || 0) - args.quantityToUnarchive

            // if there are still some quantity in archived status, update the count
            if (newCount >= 1) {
              cache.writeFragment(fragment, {
                ...args,
                count: newCount,
                __typename: "AssetGroup",
              })
            }

            // otherwise, remove the grouped asset from the archived list
            if (newCount < 1) {
              cache
                .inspectFields("Query")
                .filter((query) => query.fieldName === "archivedAssetGroups")
                .forEach((query) => {
                  const queryData = cache.resolve("Query", query.fieldKey)

                  if (queryData && Array.isArray(queryData)) {
                    const filteredData = queryData.filter((cacheKey) => cacheKey !== key)
                    cache.link("Query", query.fieldKey, filteredData)
                  }
                })
            }
          },
          updateOneAsset: async (result, args, _cache, _info) => {
            if (args.photoId && result.updateOneAsset?.imageUrl) {
              updateImageCache(result.updateOneAsset.imageUrl)
            }

            return {
              ...args,
              ...result.updateOneAsset,
              __typename: "Asset",
            }
          },
          updateOneProject: (result, args, cache, _info) => {
            if (args.image && result.updateOneProject.imageUrl) {
              updateImageCache(result.updateOneProject.imageUrl)
            }

            if (args.schedule) {
              const schedule = {
                ...args.schedule,
                __typename: "Schedule",
              }

              cache.writeFragment(
                gql`
                  fragment _ on Project {
                    id
                    schedule {
                      id
                      isDefault
                      nonWorkDays {
                        id
                        active
                        dateRange
                        name
                      }
                      workDays {
                        active
                        label
                      }
                      workHours {
                        endTime
                        hours
                        startTime
                      }
                      __typename
                    }
                  }
                `,
                {
                  id: args.id,
                  schedule: schedule,
                  __typename: "Project",
                }
              )
            }

            if (args.scheduledBreaks) {
              const breaks = { ...args.scheduledBreaks, __typename: "ScheduledBreak" }

              cache.writeFragment(
                gql`
                  fragment _ on Project {
                    id
                    scheduledBreaks {
                      id
                      breakTask {
                        id
                        name
                        isUnpaid
                        projectId
                      }
                      durationInMinutes
                      isActive
                      localizedStartTime
                      name
                      projectId
                      __typename
                    }
                  }
                `,
                {
                  id: args.id,
                  scheduledBreaks: breaks,
                  __typename: "Project",
                }
              )
            }

            invalidateTaskCache(cache)

            return {
              ...args,
              ...result.updateOneProject,
              __typename: "Project",
            }
          },
          updateOneTask: (_result, _args, cache) => {
            // From a Gantt perspective, we need to invalidate the project cache because this action can change the task's start/end dates.
            // Since task dates are calculated from a project level, we need to recalculate all dates for every task in the project
            invalidateProjectCache(cache)
          },
          updateOneTaskGroup: (result, args, cache) => {
            const fragment = gql`
              fragment _ on TaskListItem {
                taskId
                taskGroupId
                name
              }
            `
            cache.writeFragment(fragment, {
              taskId: null,
              taskGroupId: args.id,
              name: args.name,
              __typename: "TaskListItem",
            })
            return { ...args, ...result.updateOneTaskGroup, __typename: "Task" }
          },
          updateOneTimeEntry: (result, args, _cache) => ({
            ...args,
            ...result.updateOneTimeEntry,
            __typename: "TimeEntry",
          }),
          updateOneUser: (result, args, _cache) => {
            if (args.user.image && result.updateOneUser.imageUrl) {
              updateImageCache(result.updateOneUser.imageUrl)
            }
            return { ...args, ...result.updateOneUser, __typename: "User" }
          },
          updateReportTemplate: (result, args, cache) => {
            const template = gql`
              query AssetReportTemplate($id: String!) {
                assetReportTemplate(id: $id) {
                  id
                  assetsCount
                  createdAt
                  deletedAt
                  fields {
                    id
                    label
                    photoRequired
                    photoLabel
                    rule
                    failedStatus
                    required
                    type
                  }
                  name
                  reusable
                  universal
                }
              }
            `

            cache.updateQuery({ query: template, variables: { id: args.id } }, (_oldData) => {
              return { data: { assetReportTemplate: { ...result.updateReportTemplate } } }
            })
          },
          updateSchedule: (result, args, cache) => {
            const updatedSchedule = { ...args, ...result.updateSchedule, __typename: "Schedule" }
            const fieldNames = ["schedules", "myOrganization", "project", "task"]

            const queries = cache.inspectFields("Query").filter((field) => fieldNames.includes(field.fieldName))

            queries.forEach((query) => {
              const data = cache.resolve("Query", query.fieldKey)

              if (data && Array.isArray(data)) {
                data.push(updatedSchedule)
                cache.link("Query", query.fieldKey, data as Link<Entity>)
              } else if (query.fieldName === "myOrganization") {
                const myOrganizationData = cache.resolve("Query", query.fieldKey)

                if (myOrganizationData) {
                  cache.link("Query", query.fieldKey, [updatedSchedule] as Link<Entity>)
                }
              }
            })

            if (!args.scheduledBreaks?.length) return

            args.scheduledBreaks.forEach((sb) => {
              const newBreak = { ...sb, __typename: "ScheduledBreak" }
              const data = cache.resolve("Query", "scheduledBreaks")
              const sbFieldNames = ["scheduledBreaks", "myOrganization", "project", "task"]

              const sbQueries = cache.inspectFields("Query").filter((field) => sbFieldNames.includes(field.fieldName))

              sbQueries.forEach((query) => {
                if (data && Array.isArray(data)) {
                  data.push(newBreak)
                  cache.link("Query", "scheduledBreaks", data as Link<Entity>)
                } else {
                  const breakData = cache.resolve("Query", query.fieldKey)

                  if (breakData) {
                    cache.link("Query", query.fieldKey, [newBreak] as Link<Entity>)
                  }
                }
              })
            })
          },
        },
      },
    }),
    mapExchange({
      onError(error, _operation) {
        const isInvalidSession = error.graphQLErrors.some((e) => e.extensions?.code === INVALID_SESSION)
        if (isInvalidSession) session.logout()
      },
    }),
    authExchange(async (utils) => {
      return {
        addAuthToOperation: (operation) => {
          const accessToken = localStorage.getItem(sessionKeys.accessToken)
          if (!accessToken) return operation
          return utils.appendHeaders(operation, {
            Authorization: accessToken,
          })
        },
        didAuthError: (error, _operation) => {
          return error.graphQLErrors.some((e) => e.extensions?.code === EXPIRED_TOKEN)
        },
        refreshAuth: async () => {
          const refreshToken = localStorage.getItem(sessionKeys.refreshToken)
          await refreshSession(refreshToken)
        },
        willAuthError(_operation) {
          const exp = getSessionExp()
          if (!exp) return true
          return shouldRefresh(exp, 4)
        },
      }
    }),
    fetchExchange,
  ]
  if (process.env.NODE_ENV !== "production") {
    exchanges.unshift(devtoolsExchange, refocusExchange())
  }

  return createClient({
    url: "/api/graphql",
    requestPolicy: "cache-and-network",
    exchanges: exchanges as Exchange[],
  })
}
