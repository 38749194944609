// THIS FILE IS GENERATED by `npm run generate:graphql`, DO NOT EDIT MANUALLY!
import { cacheExchange } from '@urql/exchange-graphcache';
import { Resolver as GraphCacheResolver, UpdateResolver as GraphCacheUpdateResolver, OptimisticMutationResolver as GraphCacheOptimisticMutationResolver } from '@urql/exchange-graphcache';

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: Date | string; output: Date | string; }
  HTML: { input: any; output: any; }
  JSON: { input: any; output: any; }
};

export enum ActionTypeEnum {
  ClockIn = 'CLOCK_IN',
  ClockOut = 'CLOCK_OUT',
  ManualCreate = 'MANUAL_CREATE',
  ManualDelete = 'MANUAL_DELETE',
  ManualEdit = 'MANUAL_EDIT',
  Unknown = 'UNKNOWN'
}

export type Address = {
  __typename?: 'Address';
  addressComponents: Array<AddressComponent>;
  formattedAddress?: Maybe<Scalars['String']['output']>;
};

export type AddressComponent = {
  __typename?: 'AddressComponent';
  longName: Scalars['String']['output'];
  shortName: Scalars['String']['output'];
  types: Array<Scalars['String']['output']>;
};

export type AddressComponentInput = {
  longName?: InputMaybe<Scalars['String']['input']>;
  shortName?: InputMaybe<Scalars['String']['input']>;
  types?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AddressInput = {
  addressComponents?: InputMaybe<Array<AddressComponentInput>>;
  formattedAddress?: InputMaybe<Scalars['String']['input']>;
};

export type Asset = {
  __typename?: 'Asset';
  active: Scalars['Boolean']['output'];
  activeChangedAt: Scalars['DateTime']['output'];
  assetChildCount: Scalars['Int']['output'];
  assetGroupId?: Maybe<Scalars['String']['output']>;
  assetGroupMemberCount: Scalars['Int']['output'];
  assignableId: Scalars['String']['output'];
  assignableType: AssetAssignableType;
  assignedAsset?: Maybe<Asset>;
  assignedAssetId?: Maybe<Scalars['String']['output']>;
  assignedTask?: Maybe<Task>;
  assignedTaskId?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars['String']['output']>;
  assignmentsCount: Scalars['Int']['output'];
  billingClassification?: Maybe<AssetBillingClassification>;
  billingClassificationId?: Maybe<Scalars['String']['output']>;
  category: AssetCategory;
  childAssetGroups?: Maybe<Array<AssetGroup>>;
  childAssets?: Maybe<Array<Asset>>;
  companyAssetNumber?: Maybe<Scalars['String']['output']>;
  compositeKey: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  division: Division;
  divisionId?: Maybe<Scalars['String']['output']>;
  files: Array<File>;
  groupQuantity?: Maybe<Scalars['Int']['output']>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  inferredOwner?: Maybe<User>;
  inferredProjectId?: Maybe<Scalars['String']['output']>;
  inspectionTemplatesAssignments: Array<AssetInspectionTemplateAssignments>;
  inventoryRequirements?: Maybe<AssetInventoryRequirements>;
  isArchived?: Maybe<Scalars['Boolean']['output']>;
  isAssetGroup: Scalars['Boolean']['output'];
  lastInventoriedAt?: Maybe<Scalars['DateTime']['output']>;
  manufacturer?: Maybe<AssetManufacturer>;
  name: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['String']['output'];
  ownershipType: Scalars['String']['output'];
  photo: File;
  photoId?: Maybe<Scalars['String']['output']>;
  purchaseDetails?: Maybe<AssetPurchaseDetails>;
  rentalAgreement?: Maybe<AssetRentalAgreement>;
  repairRequestCount: Scalars['Int']['output'];
  reportCount: Scalars['Int']['output'];
  /** @deprecated Use inspectionTemplatesAssignments instead, since the table is no longer directly associated */
  reportTemplates: Array<AssetReportTemplate>;
  reports: Array<AssetReport>;
  status: AssetStatus;
  updatedAt: Scalars['DateTime']['output'];
  vendorContact?: Maybe<AssetVendorContact>;
};

export enum AssetAssignableType {
  Asset = 'Asset',
  Task = 'Task',
  User = 'User'
}

export type AssetBillingClassification = {
  __typename?: 'AssetBillingClassification';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export enum AssetCategory {
  Electronics = 'Electronics',
  HeavyEquipment = 'HeavyEquipment',
  Other = 'Other',
  SmallEquipment = 'SmallEquipment',
  Tools = 'Tools',
  Trailers = 'Trailers',
  Vehicles = 'Vehicles'
}

export type AssetFilter = {
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  assetId?: InputMaybe<Scalars['String']['input']>;
  assignableId?: InputMaybe<Scalars['String']['input']>;
  assignableType?: InputMaybe<AssetAssignableType>;
  billingClassificationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  categories?: InputMaybe<Array<AssetCategory>>;
  includeGroupedAssets?: InputMaybe<Scalars['Boolean']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<AssetStatus>>;
  taskGroupId?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type AssetGroup = {
  __typename?: 'AssetGroup';
  assetGroupId: Scalars['String']['output'];
  assignableId: Scalars['String']['output'];
  assignableType: AssetAssignableType;
  assignedAsset?: Maybe<Asset>;
  assignedAssetId?: Maybe<Scalars['String']['output']>;
  assignedTask?: Maybe<Task>;
  assignedTaskId?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars['String']['output']>;
  compositeKey: Scalars['String']['output'];
  count: Scalars['Int']['output'];
  groupParent: Asset;
  status: AssetStatus;
};

export type AssetGroupFilterSpecification = {
  assetGroupId: Scalars['String']['input'];
  assignableId: Scalars['String']['input'];
  assignableType: Scalars['String']['input'];
  count: Scalars['Int']['input'];
  status: AssetStatus;
};

export type AssetGroupReassignmentSpecification = {
  filter: AssetGroupFilterSpecification;
  ids: Array<Scalars['String']['input']>;
};

/** AssetInspectionRequirements */
export type AssetInspectionRequirements = {
  __typename?: 'AssetInspectionRequirements';
  intervalInSeconds?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

export type AssetInspectionRequirementsInput = {
  assetReportTemplateId?: InputMaybe<Scalars['String']['input']>;
  intervalInSeconds?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AssetInspectionTemplateAssignments = {
  __typename?: 'AssetInspectionTemplateAssignments';
  asset: Asset;
  assetId: Scalars['String']['output'];
  assetReportTemplateId: Scalars['String']['output'];
  /** helper method to make it simple on the client side */
  id: Scalars['ID']['output'];
  inspectionTemplate: AssetReportTemplate;
  intervalInSeconds?: Maybe<Scalars['Int']['output']>;
  intervalRequired: Scalars['Boolean']['output'];
  lastReportedAt?: Maybe<Scalars['DateTime']['output']>;
  organizationId: Scalars['String']['output'];
  startDate?: Maybe<Scalars['DateTime']['output']>;
};

/** AssetInventoryRequirements */
export type AssetInventoryRequirements = {
  __typename?: 'AssetInventoryRequirements';
  intervalInSeconds?: Maybe<Scalars['Int']['output']>;
  photoRequired?: Maybe<Scalars['Boolean']['output']>;
};

export type AssetInventoryRequirementsInput = {
  intervalInSeconds?: InputMaybe<Scalars['Int']['input']>;
  photoRequired?: InputMaybe<Scalars['Boolean']['input']>;
};

/** AssetManufacturer */
export type AssetManufacturer = {
  __typename?: 'AssetManufacturer';
  id?: Maybe<Scalars['String']['output']>;
  make?: Maybe<Scalars['String']['output']>;
  model?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  year?: Maybe<Scalars['Int']['output']>;
};

export type AssetManufacturerInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  make?: InputMaybe<Scalars['String']['input']>;
  model?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  year?: InputMaybe<Scalars['Int']['input']>;
};

/** AssetPurchaseDetails */
export type AssetPurchaseDetails = {
  __typename?: 'AssetPurchaseDetails';
  date?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Float']['output']>;
};

export type AssetPurchaseDetailsInput = {
  date?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Float']['input']>;
};

/** AssetRentalAgreement */
export type AssetRentalAgreement = {
  __typename?: 'AssetRentalAgreement';
  endOn?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<AssetRentalAgreementRate>;
  startOn?: Maybe<Scalars['String']['output']>;
};

export type AssetRentalAgreementInput = {
  endOn?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<AssetRentalAgreementRateInput>;
  startOn?: InputMaybe<Scalars['String']['input']>;
};

/** AssetRentalAgreementRate */
export type AssetRentalAgreementRate = {
  __typename?: 'AssetRentalAgreementRate';
  daily: Scalars['Float']['output'];
  monthly: Scalars['Float']['output'];
  weekly: Scalars['Float']['output'];
};

export type AssetRentalAgreementRateInput = {
  daily?: InputMaybe<Scalars['Float']['input']>;
  monthly?: InputMaybe<Scalars['Float']['input']>;
  weekly?: InputMaybe<Scalars['Float']['input']>;
};

export type AssetRepairRequest = {
  __typename?: 'AssetRepairRequest';
  asset: Asset;
  assetId: Scalars['String']['output'];
  assetReport: AssetReport;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  inspectionFieldId?: Maybe<Scalars['String']['output']>;
  label: Scalars['String']['output'];
  organization: Organization;
  organizationId: Scalars['String']['output'];
  reporter: User;
  reporterId: Scalars['String']['output'];
};

export type AssetReport = {
  __typename?: 'AssetReport';
  asset: Asset;
  assetId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  inspectionReport?: Maybe<Array<AssetReportInspectionSubmission>>;
  inspectionReportAggregations?: Maybe<InspectionReportAggregations>;
  inventoryReport?: Maybe<AssetReportInventoryReport>;
  notes?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organizationId: Scalars['String']['output'];
  quantityReported?: Maybe<Scalars['Int']['output']>;
  reporter: User;
  reporterId: Scalars['String']['output'];
  statusChange?: Maybe<AssetStatusChange>;
  transferReport?: Maybe<AssetReportTransferReport>;
  type: AssetReportType;
  updatedAt: Scalars['DateTime']['output'];
};

export type AssetReportInput = {
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  assetId: Scalars['String']['input'];
  assetReportTemplateId?: InputMaybe<Scalars['String']['input']>;
  inspectionReport?: InputMaybe<Array<AssetReportInspectionSubmissionInput>>;
  inventoryReport?: InputMaybe<AssetReportInventoryReportType>;
  notes?: InputMaybe<Scalars['String']['input']>;
  quantityReported?: InputMaybe<Scalars['Int']['input']>;
  statusChange?: InputMaybe<AssetStatusChangeInput>;
  type: AssetReportType;
};

/** AssetReportInspectionSubmission */
export type AssetReportInspectionSubmission = {
  __typename?: 'AssetReportInspectionSubmission';
  failedStatus?: Maybe<Scalars['String']['output']>;
  fileIds?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  input?: Maybe<Scalars['String']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  photoLabel?: Maybe<Scalars['String']['output']>;
  photoRequired?: Maybe<Scalars['Boolean']['output']>;
  photoUrls?: Maybe<Array<Scalars['String']['output']>>;
  required?: Maybe<Scalars['Boolean']['output']>;
  rule?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** AssetReportInspectionSubmission */
export type AssetReportInspectionSubmissionInput = {
  failedStatus?: InputMaybe<Scalars['String']['input']>;
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  id?: InputMaybe<Scalars['String']['input']>;
  input?: InputMaybe<Scalars['String']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  photoLabel?: InputMaybe<Scalars['String']['input']>;
  photoRequired?: InputMaybe<Scalars['Boolean']['input']>;
  photoUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  photos?: InputMaybe<Array<Scalars['String']['input']>>;
  required?: InputMaybe<Scalars['Boolean']['input']>;
  rule?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** AssetReportInventoryReport */
export type AssetReportInventoryReport = {
  __typename?: 'AssetReportInventoryReport';
  fileIds?: Maybe<Array<Scalars['String']['output']>>;
  note?: Maybe<Scalars['String']['output']>;
  photoUrls?: Maybe<Array<Scalars['String']['output']>>;
};

export type AssetReportInventoryReportType = {
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  note?: InputMaybe<Scalars['String']['input']>;
  photoUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  photos?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type AssetReportTemplate = {
  __typename?: 'AssetReportTemplate';
  active: Scalars['Boolean']['output'];
  /** @deprecated Use assetAssignments in the future */
  assets: Array<Asset>;
  assetsCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  fields: Array<AssetReportTemplateField>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
  organizationId?: Maybe<Scalars['String']['output']>;
  reusable: Scalars['Boolean']['output'];
  universal: Scalars['Boolean']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

/** AssetReportInput */
export type AssetReportTemplateField = {
  __typename?: 'AssetReportTemplateField';
  failedStatus?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  label: Scalars['String']['output'];
  photoLabel?: Maybe<Scalars['String']['output']>;
  photoRequired: Scalars['Boolean']['output'];
  required: Scalars['Boolean']['output'];
  rule?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
};

/** AssetReportTemplateFieldInput */
export type AssetReportTemplateFieldInput = {
  failedStatus?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  label: Scalars['String']['input'];
  photoLabel?: InputMaybe<Scalars['String']['input']>;
  photoRequired: Scalars['Boolean']['input'];
  required: Scalars['Boolean']['input'];
  rule?: InputMaybe<Scalars['String']['input']>;
  type: Scalars['String']['input'];
};

/** AssetReportTransferAssignmentInput */
export type AssetReportTransferAssignment = {
  __typename?: 'AssetReportTransferAssignment';
  asset?: Maybe<Asset>;
  assignableId: Scalars['String']['output'];
  assignableType: AssetAssignableType;
  task?: Maybe<Task>;
  user?: Maybe<User>;
};

/** AssetReportTransferAssignmentInput */
export type AssetReportTransferAssignmentInput = {
  assignableId?: InputMaybe<Scalars['String']['input']>;
  assignableType?: InputMaybe<AssetAssignableType>;
};

/** AssetReportTransferReport */
export type AssetReportTransferReport = {
  __typename?: 'AssetReportTransferReport';
  from: AssetReportTransferAssignment;
  to: AssetReportTransferAssignment;
};

/** AssetReportTransferReport */
export type AssetReportTransferReportInput = {
  from?: InputMaybe<AssetReportTransferAssignmentInput>;
  to?: InputMaybe<AssetReportTransferAssignmentInput>;
};

export enum AssetReportType {
  DismissRepair = 'DismissRepair',
  Inspection = 'Inspection',
  Inventory = 'Inventory',
  Repair = 'Repair',
  StatusChange = 'StatusChange',
  Transfer = 'Transfer',
  TransferDivision = 'TransferDivision'
}

export enum AssetRetainmentOption {
  /** Offload assets nested in an asset so the assets will be removed from the asset and stay in the existing division. */
  Offload = 'OFFLOAD',
  /** Retain assets nested in an asset so the assets will be in the newly assigned division. */
  Retain = 'RETAIN'
}

export type AssetSort = {
  by?: AssetSortByInput;
  direction?: AssetSortDirectionInput;
};

export enum AssetSortByInput {
  Name = 'name'
}

export enum AssetSortDirectionInput {
  Asc = 'asc',
  Desc = 'desc'
}

export enum AssetStatus {
  Available = 'Available',
  BeingRepaired = 'BeingRepaired',
  InUse = 'InUse',
  Missing = 'Missing',
  NeedsRepair = 'NeedsRepair',
  OutOfService = 'OutOfService',
  Sold = 'Sold',
  Transferred = 'Transferred'
}

/** AssetStatusChange */
export type AssetStatusChange = {
  __typename?: 'AssetStatusChange';
  active: Scalars['Boolean']['output'];
  missingCount?: Maybe<Scalars['Int']['output']>;
  status: AssetStatus;
};

/** AssetStatusChange */
export type AssetStatusChangeInput = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  missingCount?: InputMaybe<Scalars['Int']['input']>;
  status?: InputMaybe<AssetStatus>;
};

/** AssetVendorContact */
export type AssetVendorContact = {
  __typename?: 'AssetVendorContact';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
};

export type AssetVendorContactInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
};

export type AssignedAsset = {
  __typename?: 'AssignedAsset';
  active: Scalars['Boolean']['output'];
  assetChildCount: Scalars['Int']['output'];
  assetGroupId?: Maybe<Scalars['String']['output']>;
  assignableId: Scalars['String']['output'];
  assignableType: AssetAssignableType;
  assignedAsset?: Maybe<Asset>;
  assignedAssetId?: Maybe<Scalars['String']['output']>;
  assignedTask?: Maybe<Task>;
  assignedTaskId?: Maybe<Scalars['String']['output']>;
  assignedUser?: Maybe<User>;
  assignedUserId?: Maybe<Scalars['String']['output']>;
  assignmentsCount: Scalars['Int']['output'];
  category: AssetCategory;
  companyAssetNumber?: Maybe<Scalars['String']['output']>;
  compositeKey: Scalars['String']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  depth?: Maybe<Scalars['Int']['output']>;
  groupParentAsset?: Maybe<Asset>;
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  inferredOwner?: Maybe<User>;
  inventoryRequirements?: Maybe<AssetInventoryRequirements>;
  isAssetGroup: Scalars['Boolean']['output'];
  lastInventoriedAt?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  ownershipType: Scalars['String']['output'];
  quantizedAssetIds?: Maybe<Array<Scalars['String']['output']>>;
  status: AssetStatus;
};

export type AssignedAssetFilter = {
  assignableId?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AssetStatus>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export enum BillingStatus {
  Active = 'ACTIVE',
  DisabledDueToNonPayment = 'DISABLED_DUE_TO_NON_PAYMENT',
  PastDue = 'PAST_DUE'
}

export type BulkClockInCandidate = {
  projectId: Scalars['String']['input'];
  startEvidence?: InputMaybe<StartEvidenceInput>;
  taskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type BulkClockOutCandidate = {
  endEvidence?: InputMaybe<EndEvidenceInput>;
  userId: Scalars['String']['input'];
};

/** Result of bulk task creation operation */
export type BulkTaskCreationResult = {
  __typename?: 'BulkTaskCreationResult';
  createdSubTasksCount: Scalars['Int']['output'];
  createdTaskGroupsCount: Scalars['Int']['output'];
  createdTasksCount: Scalars['Int']['output'];
  createdUnitGoalsCount: Scalars['Int']['output'];
};

/** BulkTimeEntryResponse */
export type BulkTimeEntryResponse = {
  __typename?: 'BulkTimeEntryResponse';
  errors: Array<TimeEntryError>;
  success: Scalars['Int']['output'];
};

export type BulkUpdateTaskSortOrderInput = {
  id: Scalars['String']['input'];
  sortOrder: Scalars['Int']['input'];
  taskGroupId?: InputMaybe<Scalars['String']['input']>;
  /** Either a task or taskGroup */
  type: Scalars['String']['input'];
};

export type ClockInData = {
  __typename?: 'ClockInData';
  fileUploadResponses?: Maybe<Array<FileUploadResponse>>;
  imagePath?: Maybe<Scalars['String']['output']>;
  projectId: Scalars['String']['output'];
  task: Task;
  taskId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type ClockInDataInput = {
  filesToUpload?: InputMaybe<Array<FileUploadRequest>>;
  imagePath?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type ClockOutData = {
  __typename?: 'ClockOutData';
  fileUploadResponses?: Maybe<Array<FileUploadResponse>>;
  imagePath?: Maybe<Scalars['String']['output']>;
  injured?: Maybe<Scalars['Boolean']['output']>;
  injuryNotes?: Maybe<Scalars['String']['output']>;
  injuryReportId?: Maybe<Scalars['String']['output']>;
  projectId: Scalars['String']['output'];
  signaturePath?: Maybe<Scalars['String']['output']>;
  task: Task;
  taskId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type ClockOutDataInput = {
  filesToUpload?: InputMaybe<Array<FileUploadRequest>>;
  imagePath?: InputMaybe<Scalars['String']['input']>;
  injured?: InputMaybe<Scalars['Boolean']['input']>;
  injuryNotes?: InputMaybe<Scalars['String']['input']>;
  injuryReportId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  signaturePath?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type Contract = {
  __typename?: 'Contract';
  assetBillingClassifications: Array<ContractAssetBillingClassification>;
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  deliverableUnits: Array<ContractDeliverableUnit>;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  number?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userBillingClassifications: Array<ContractUserBillingClassification>;
};

export type ContractAssetBillingClassification = {
  __typename?: 'ContractAssetBillingClassification';
  assetBillingClassification: AssetBillingClassification;
  assetBillingClassificationId: Scalars['String']['output'];
  contract: Contract;
  contractId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ContractAssetBillingClassificationInput = {
  assetBillingClassificationId: Scalars['String']['input'];
  customerDescription?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
};

export type ContractDeliverableUnit = {
  __typename?: 'ContractDeliverableUnit';
  contract: Contract;
  contractId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerDescription?: Maybe<Scalars['String']['output']>;
  deliverableUnit: DeliverableUnit;
  deliverableUnitId: Scalars['String']['output'];
  id: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type ContractDeliverableUnitInput = {
  customerDescription?: InputMaybe<Scalars['String']['input']>;
  deliverableUnitId: Scalars['String']['input'];
  rate?: InputMaybe<Scalars['Float']['input']>;
};

export type ContractUserBillingClassification = {
  __typename?: 'ContractUserBillingClassification';
  contract: Contract;
  contractId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customerDescription?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  rate?: Maybe<Scalars['Float']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  userBillingClassification: UserBillingClassification;
  userBillingClassificationId: Scalars['String']['output'];
};

export type ContractUserBillingClassificationInput = {
  customerDescription?: InputMaybe<Scalars['String']['input']>;
  rate?: InputMaybe<Scalars['Float']['input']>;
  userBillingClassificationId: Scalars['String']['input'];
};

export type CreateManyOfflineEventsResponsePayload = {
  __typename?: 'CreateManyOfflineEventsResponsePayload';
  createdEvents: Array<OfflineEvent>;
  failureCount: Scalars['Int']['output'];
  successCount: Scalars['Int']['output'];
};

export type Customer = {
  __typename?: 'Customer';
  activeProjectCount: Scalars['Int']['output'];
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contracts: Array<Contract>;
  createdAt: Scalars['DateTime']['output'];
  customerId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** @deprecated Use imageUrl instead */
  image?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberExt?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  zip?: Maybe<Scalars['String']['output']>;
};

export type DeliverableUnit = {
  __typename?: 'DeliverableUnit';
  archivedAt?: Maybe<Scalars['DateTime']['output']>;
  color: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  description: Scalars['String']['output'];
  divisionDeliverableUnit?: Maybe<DivisionDeliverableUnit>;
  id: Scalars['ID']['output'];
  referenceNumber?: Maybe<Scalars['String']['output']>;
  unitOfMeasure: Scalars['String']['output'];
};

export type DigestDataDetails = {
  __typename?: 'DigestDataDetails';
  code?: Maybe<Scalars['String']['output']>;
  dailyProgress: Scalars['Int']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  isArchived?: Maybe<Scalars['DateTime']['output']>;
  isComplete?: Maybe<Scalars['DateTime']['output']>;
  name: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['String']['output'];
  tasksCompletedSum: Scalars['Int']['output'];
  timeEntriesSum: Scalars['Float']['output'];
};

export type Division = {
  __typename?: 'Division';
  activeProjectsCount: Scalars['Int']['output'];
  activeUsersForDateRangeCount: Scalars['Int']['output'];
  address?: Maybe<OrganizationAddress>;
  adminCount: Scalars['Int']['output'];
  admins: Array<User>;
  allowTeamMembersClockInOut: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  assetCount: Scalars['Int']['output'];
  billingPastDueAt?: Maybe<Scalars['DateTime']['output']>;
  billingStatus: BillingStatus;
  clockedInUserCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  dailyDigestData: Array<DigestDataDetails>;
  /** @deprecated Use `createdAt` instead */
  dateCreated: Scalars['DateTime']['output'];
  divisionCount: Scalars['Int']['output'];
  id?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isCurrentAssignment: Scalars['Boolean']['output'];
  isPrimary: Scalars['Boolean']['output'];
  lastStripeInvoice?: Maybe<StripeInvoice>;
  name?: Maybe<Scalars['String']['output']>;
  notificationSettings: Scalars['JSON']['output'];
  organizationId: Scalars['String']['output'];
  overtimeType: OvertimeTypeEnum;
  projectsCount: Scalars['Int']['output'];
  requireInjuryReportOnClockout: Scalars['Boolean']['output'];
  requireTimeEntryEvidence: Scalars['Boolean']['output'];
  roles: Array<Role>;
  schedule?: Maybe<Schedule>;
  scheduledBreaks: Array<ScheduledBreak>;
  secondsClockedForCurrentWeek: Scalars['Int']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeInvoices: Array<StripeInvoice>;
  timezone: Scalars['String']['output'];
  unassignedProjectId: Scalars['String']['output'];
  unitsOfMeasure: Array<UnitOfMeasure>;
  userCount: Scalars['Int']['output'];
};


export type DivisionActiveUsersForDateRangeCountArgs = {
  rangeEnd: Scalars['DateTime']['input'];
  rangeStart: Scalars['DateTime']['input'];
};


export type DivisionDailyDigestDataArgs = {
  rangeEnd: Scalars['DateTime']['input'];
  rangeStart: Scalars['DateTime']['input'];
};

export type DivisionDeliverableUnit = {
  __typename?: 'DivisionDeliverableUnit';
  active: Scalars['Boolean']['output'];
  assignedToTask?: Maybe<Scalars['Boolean']['output']>;
  id: Scalars['ID']['output'];
};

export type EditHistoryEvent = {
  __typename?: 'EditHistoryEvent';
  createdAt: Scalars['DateTime']['output'];
  data: Scalars['JSON']['output'];
  id: Scalars['ID']['output'];
  modelId: Scalars['String']['output'];
  modelType: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  project: Project;
  projectId?: Maybe<Scalars['String']['output']>;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['String']['output']>;
  type: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export enum EmptyFormat {
  EmptyString = 'EMPTY_STRING',
  Number = 'NUMBER'
}

export type EndEvidence = {
  __typename?: 'EndEvidence';
  imageUrl?: Maybe<Scalars['String']['output']>;
  location?: Maybe<GeolocationPosition>;
  locationError?: Maybe<Scalars['String']['output']>;
  signatureUrl?: Maybe<Scalars['String']['output']>;
};

export type EndEvidenceInput = {
  imagePath?: InputMaybe<Scalars['String']['input']>;
  injured?: InputMaybe<Scalars['Boolean']['input']>;
  injuryNotes?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<GeolocationPositionInput>;
  locationError?: InputMaybe<Scalars['String']['input']>;
  signaturePath?: InputMaybe<Scalars['String']['input']>;
};

export enum FeatureToggleSegments {
  DeveloperBeta = 'DEVELOPER_BETA',
  PublicBeta = 'PUBLIC_BETA'
}

export type File = {
  __typename?: 'File';
  assetId?: Maybe<Scalars['String']['output']>;
  contentType: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  documentUrl?: Maybe<Scalars['String']['output']>;
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  fileName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  key: Scalars['String']['output'];
  objectVersion: Scalars['Int']['output'];
  organizationId: Scalars['String']['output'];
  projectId?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
  vendorId?: Maybe<Scalars['String']['output']>;
};

/** Remove this once mobile clients are updated with the better named 'FileUploadResponse' */
export type FileUpload = {
  __typename?: 'FileUpload';
  fileId: Scalars['String']['output'];
  objectKey?: Maybe<Scalars['String']['output']>;
  uploadUrl?: Maybe<Scalars['String']['output']>;
  urlExpiresAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FileUploadRequest = {
  contentType: Scalars['String']['input'];
  id: Scalars['String']['input'];
  key?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['String']['input']>;
};

export type FileUploadResponse = {
  __typename?: 'FileUploadResponse';
  fileId: Scalars['String']['output'];
  objectKey?: Maybe<Scalars['String']['output']>;
  uploadUrl?: Maybe<Scalars['String']['output']>;
  urlExpiresAt?: Maybe<Scalars['DateTime']['output']>;
};

export type FraudEvidenceData = {
  __typename?: 'FraudEvidenceData';
  currentUtcDateTime: Scalars['DateTime']['output'];
  systemWallTime: Scalars['Int']['output'];
};

export type FraudEvidenceDataInput = {
  currentUtcDateTime: Scalars['DateTime']['input'];
  systemWallTime: Scalars['Int']['input'];
};

export type GanttProjectDetailsRef = {
  __typename?: 'GanttProjectDetailsRef';
  endDate: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  startDate: Scalars['DateTime']['output'];
  taskDependencies: Array<GanttTaskDependencyRef>;
  taskGroups: Array<GanttTaskGroupDetailsRef>;
  tasks: Array<GanttTaskDetailsRef>;
};

export type GanttTaskDependencyRef = {
  __typename?: 'GanttTaskDependencyRef';
  dependentTaskEndDate?: Maybe<Scalars['DateTime']['output']>;
  dependentTaskGroupId?: Maybe<Scalars['String']['output']>;
  dependentTaskId: Scalars['String']['output'];
  dependentTaskSortOrder?: Maybe<Scalars['Int']['output']>;
  dependentTaskStartDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  metadata?: Maybe<TaskDependencyData>;
  predecessorTaskEndDate?: Maybe<Scalars['DateTime']['output']>;
  predecessorTaskGroupId?: Maybe<Scalars['String']['output']>;
  predecessorTaskId: Scalars['String']['output'];
  predecessorTaskSortOrder?: Maybe<Scalars['Int']['output']>;
  predecessorTaskStartDate?: Maybe<Scalars['DateTime']['output']>;
  type: TaskDependencyType;
};

export type GanttTaskDetailsRef = {
  __typename?: 'GanttTaskDetailsRef';
  actualEndDate?: Maybe<Scalars['DateTime']['output']>;
  actualStartDate?: Maybe<Scalars['DateTime']['output']>;
  constrainedEndDate?: Maybe<Scalars['DateTime']['output']>;
  constrainedStartDate?: Maybe<Scalars['DateTime']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  plannedEndDate?: Maybe<Scalars['DateTime']['output']>;
  plannedStartDate?: Maybe<Scalars['DateTime']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  unitGoalsSumProgress?: Maybe<Scalars['Float']['output']>;
  unitGoalsSumTargetQuantity?: Maybe<Scalars['Float']['output']>;
};

export type GanttTaskGroupDetailsRef = {
  __typename?: 'GanttTaskGroupDetailsRef';
  actualEndDate?: Maybe<Scalars['DateTime']['output']>;
  actualStartDate?: Maybe<Scalars['DateTime']['output']>;
  constrainedEndDate?: Maybe<Scalars['DateTime']['output']>;
  constrainedStartDate?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  isComplete?: Maybe<Scalars['Boolean']['output']>;
  name: Scalars['String']['output'];
  plannedEndDate?: Maybe<Scalars['DateTime']['output']>;
  plannedStartDate?: Maybe<Scalars['DateTime']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  tasks: Array<GanttTaskDetailsRef>;
  unitProgress?: Maybe<Scalars['Float']['output']>;
  unitTargetGoal?: Maybe<Scalars['Float']['output']>;
};

export type GeolocationCoordinates = {
  __typename?: 'GeolocationCoordinates';
  accuracy: Scalars['Float']['output'];
  altitude?: Maybe<Scalars['Float']['output']>;
  altitudeAccuracy?: Maybe<Scalars['Float']['output']>;
  heading?: Maybe<Scalars['Float']['output']>;
  latitude: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  speed?: Maybe<Scalars['Float']['output']>;
};

export type GeolocationCoordinatesInput = {
  accuracy: Scalars['Float']['input'];
  altitude?: InputMaybe<Scalars['Float']['input']>;
  altitudeAccuracy?: InputMaybe<Scalars['Float']['input']>;
  heading?: InputMaybe<Scalars['Float']['input']>;
  latitude: Scalars['Float']['input'];
  longitude: Scalars['Float']['input'];
  speed?: InputMaybe<Scalars['Float']['input']>;
};

export type GeolocationPosition = {
  __typename?: 'GeolocationPosition';
  coords: GeolocationCoordinates;
  isMocked?: Maybe<Scalars['Boolean']['output']>;
  timestamp: Scalars['DateTime']['output'];
};

export type GeolocationPositionInput = {
  coords: GeolocationCoordinatesInput;
  isMocked?: InputMaybe<Scalars['Boolean']['input']>;
  timestamp: Scalars['Float']['input'];
};

export type GroupInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  tasks?: InputMaybe<Array<TaskInput>>;
};

export type InjuryReport = {
  __typename?: 'InjuryReport';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  project?: Maybe<Project>;
  projectId?: Maybe<Scalars['String']['output']>;
  reporter: User;
  reporterId: Scalars['String']['output'];
  signatureUrl?: Maybe<Scalars['String']['output']>;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type InjuryReportData = {
  __typename?: 'InjuryReportData';
  fileUploadResponses?: Maybe<Array<FileUploadResponse>>;
  injuryReportId: Scalars['String']['output'];
  notes: Scalars['String']['output'];
  signaturePath: Scalars['String']['output'];
  task: Task;
  taskId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type InjuryReportDataInput = {
  filesToUpload?: InputMaybe<Array<FileUploadRequest>>;
  injuryReportId: Scalars['String']['input'];
  notes: Scalars['String']['input'];
  signaturePath: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type InspectionReportAggregations = {
  __typename?: 'InspectionReportAggregations';
  falseCount: Scalars['Int']['output'];
  trueCount: Scalars['Int']['output'];
};

export type LastExport = {
  __typename?: 'LastExport';
  exportedAt: Scalars['String']['output'];
  userId: Scalars['String']['output'];
  userName: Scalars['String']['output'];
};

export type LastExportInput = {
  exportedAt: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  userName: Scalars['String']['input'];
};

export enum ListVisibilityFilter {
  /** Return all records */
  All = 'all',
  /** Return records directly pertinent to the user making the request */
  My = 'my'
}

export type LocationWithRadius = {
  __typename?: 'LocationWithRadius';
  /** @deprecated Use latitude instead */
  lat: Scalars['Float']['output'];
  latitude: Scalars['Float']['output'];
  /** @deprecated Use longitude instead */
  lng: Scalars['Float']['output'];
  longitude: Scalars['Float']['output'];
  /** @deprecated Use radiusInMeters instead */
  radius?: Maybe<Scalars['Float']['output']>;
  radiusInMeters?: Maybe<Scalars['Float']['output']>;
};

export type MetadataNote = {
  __typename?: 'MetadataNote';
  content: Scalars['String']['output'];
  highlight?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
};

export type MetadataNoteInput = {
  content: Scalars['String']['input'];
  highlight?: InputMaybe<Scalars['Boolean']['input']>;
  label: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  activateOrganization: Organization;
  activateProject: Project;
  addOrUpdateNonWorkDay: Schedule;
  addQuantitiesToGroup?: Maybe<Asset>;
  archiveOrganization: Organization;
  archiveProject: Project;
  archiveQuantities: Array<Asset>;
  archiveWorkersCompCode?: Maybe<WorkersCompCode>;
  /** Clock-in users in bulk */
  bulkClockIn: BulkTimeEntryResponse;
  /** Clock-out users in bulk */
  bulkClockOut: BulkTimeEntryResponse;
  bulkDeleteAssets?: Maybe<Array<Asset>>;
  bulkUpdateTaskSortOrder: Array<UpdateSortOrderPayload>;
  bulkUpdateUserAssignments?: Maybe<UserAssignmentBatchResponse>;
  /** Clock-in a user and create a new time entry */
  clockIn: TimeEntry;
  /** Clock-out a user to close their time entry */
  clockOutUser: TimeEntry;
  completeProject: Project;
  createAssetBillingClassification: AssetBillingClassification;
  createCustomer?: Maybe<Customer>;
  createDeliverableUnit: DeliverableUnit;
  createDivision: Division;
  /** Creates a new injury report */
  createInjuryReport: InjuryReport;
  createManyTaskReports?: Maybe<Array<Task>>;
  createOfflineEvent: OfflineEvent;
  createOfflineEvents: CreateManyOfflineEventsResponsePayload;
  createOneContract: Contract;
  createSchedule: Schedule;
  createTaskDependency: TaskDependency;
  createUnitGoal?: Maybe<UnitGoal>;
  createUnitOfMeasure: UnitOfMeasure;
  createUser?: Maybe<User>;
  createUserAssignment?: Maybe<UserAssignment>;
  createUserBillingClassification: UserBillingClassification;
  /** Allow users to create notifications for themselves */
  createUserNotification?: Maybe<UserNotification>;
  createVendor?: Maybe<Vendor>;
  createVendorContact: VendorContact;
  createWorkersCompCode?: Maybe<WorkersCompCode>;
  deleteAssetBillingClassification: AssetBillingClassification;
  deleteCustomer: Customer;
  deleteDeliverableUnit?: Maybe<DeliverableUnit>;
  deleteNonWorkDay: Schedule;
  deleteOneAsset?: Maybe<Asset>;
  deleteOneContract: Contract;
  /** 'Soft delete' file metadata */
  deleteOneFile: File;
  deleteOneTask?: Maybe<Task>;
  /** Deletes a Task Group */
  deleteOneTaskGroup?: Maybe<TaskGroup>;
  /** Delete a time entry */
  deleteOneTimeEntry: TimeEntry;
  deleteOneUser: User;
  deleteProjectScheduleAndBreaks: Scalars['String']['output'];
  deleteReportTemplate: AssetReportTemplate;
  deleteScheduledBreak: ScheduledBreak;
  deleteTaskDependency: TaskDependency;
  deleteTaskScheduleAndBreaks: Scalars['String']['output'];
  deleteUnitGoal?: Maybe<UnitGoal>;
  deleteUnitOfMeasure: UnitOfMeasure;
  deleteUserAssignment: UserAssignment;
  deleteUserBillingClassification: UserBillingClassification;
  /** Delete a user device session */
  deleteUserDeviceSession: Scalars['Boolean']['output'];
  deleteVendor: Vendor;
  deleteVendorContact: VendorContact;
  dismissAssetRepair: AssetReport;
  duplicateReportTemplate: AssetReportTemplate;
  duplicateSchedule: Schedule;
  editDeliverableUnit: DeliverableUnit;
  editDivision: Division;
  editDivisionSettings: Settings;
  editOrganization: Organization;
  editScheduledBreak: ScheduledBreak;
  editUnitGoal?: Maybe<UnitGoal>;
  editUnitOfMeasure: UnitOfMeasure;
  /** Deletes all masquerade sessions for a user */
  endAllMasqueradeSessionsForUser: Scalars['Boolean']['output'];
  grantDivisionAccessToUser: User;
  insertManyAssetReports: Array<AssetReport>;
  /** creates the tasks inputted in the task builder */
  insertManyTasks: BulkTaskCreationResult;
  insertOneAsset?: Maybe<Asset>;
  /** Create a new project */
  insertOneProject: Project;
  insertOneTask: Task;
  /** Creates a new Task Group */
  insertOneTaskGroup: TaskGroup;
  /** Create a new time entry */
  insertOneTimeEntry: TimeEntry;
  insertReportTemplate: AssetReportTemplate;
  markAllNotificationsRead?: Maybe<Array<UserNotification>>;
  markNotificationRead?: Maybe<UserNotification>;
  markNotificationsReadById?: Maybe<Array<UserNotification>>;
  markOrganizationNotPastDue: Organization;
  markOrganizationPastDue: Organization;
  markTaskCompletion?: Maybe<Task>;
  /** Masquerade as a user */
  masqueradeUser: Credentials;
  reassignUser: User;
  reassignUsers: Array<User>;
  reportAssetRepair: AssetReport;
  reportTaskProgress?: Maybe<Task>;
  resendUserInvites?: Maybe<Scalars['Boolean']['output']>;
  /** Mark a single offline event as 'resolved'.  This is only supported for the reporting user right now. */
  resolveOfflineEvent: OfflineEvent;
  restoreOneAsset?: Maybe<Asset>;
  restoreOneUser: User;
  returnQuantityToInventory: Array<Asset>;
  revokeDivisionAccessFromUser: User;
  /** Log user in to a different division */
  switchDivision: Credentials;
  switchDivisionAssignmentForUser: User;
  switchDivisionForAsset: Asset;
  toggleAssetReportTemplateState: AssetReportTemplate;
  transferAssets: Array<Asset>;
  unarchiveQuantities: Array<Asset>;
  updateAssetBillingClassification: AssetBillingClassification;
  updateCustomer?: Maybe<Customer>;
  updateLastExport?: Maybe<LastExport>;
  updateOneAsset?: Maybe<Asset>;
  updateOneContract: Contract;
  /** Updates file metadata */
  updateOneFile: File;
  /** Edit a project */
  updateOneProject: Project;
  updateOneTask: Task;
  /** Updates a Task Group */
  updateOneTaskGroup: TaskGroup;
  /** Update a time entry */
  updateOneTimeEntry: TimeEntry;
  updateOneUser: User;
  /** Updates a user's password */
  updatePassword: Scalars['Boolean']['output'];
  updateReportTemplate: AssetReportTemplate;
  updateSchedule: Schedule;
  updateTaskDependency: TaskDependency;
  updateTaskSortOrder?: Maybe<UpdateSortOrderPayload>;
  updateUserAssignment?: Maybe<UserAssignment>;
  updateUserBillingClassification: UserBillingClassification;
  updateVendor?: Maybe<Vendor>;
  updateVendorContact: VendorContact;
  upsertDivisionDeliverableUnit: DivisionDeliverableUnit;
};


export type MutationActivateOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type MutationActivateProjectArgs = {
  id: Scalars['String']['input'];
};


export type MutationAddOrUpdateNonWorkDayArgs = {
  nonWorkDay: ScheduleNonWorkDaysInput;
};


export type MutationAddQuantitiesToGroupArgs = {
  assetGroupId: Scalars['String']['input'];
  assignableId: Scalars['String']['input'];
  assignableType: AssetAssignableType;
  quantityToAdd: Scalars['Int']['input'];
};


export type MutationArchiveOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveProjectArgs = {
  id: Scalars['String']['input'];
};


export type MutationArchiveQuantitiesArgs = {
  assetGroupId: Scalars['String']['input'];
  assignableId: Scalars['String']['input'];
  assignableType: AssetAssignableType;
  quantityToArchive: Scalars['Int']['input'];
  status: AssetStatus;
};


export type MutationArchiveWorkersCompCodeArgs = {
  id: Scalars['String']['input'];
};


export type MutationBulkClockInArgs = {
  candidates: Array<BulkClockInCandidate>;
  location?: InputMaybe<GeolocationPositionInput>;
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationBulkClockOutArgs = {
  candidates: Array<BulkClockOutCandidate>;
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
};


export type MutationBulkDeleteAssetsArgs = {
  ids: Array<Scalars['String']['input']>;
  status: AssetStatus;
};


export type MutationBulkUpdateTaskSortOrderArgs = {
  updates: Array<BulkUpdateTaskSortOrderInput>;
};


export type MutationBulkUpdateUserAssignmentsArgs = {
  assignmentsToCreate?: InputMaybe<Array<UserAssignmentCreateInput>>;
  assignmentsToDelete?: InputMaybe<Array<Scalars['String']['input']>>;
  assignmentsToUpdate?: InputMaybe<Array<UserAssignmentUpdateInput>>;
};


export type MutationClockInArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  startEvidence?: InputMaybe<StartEvidenceInput>;
  taskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationClockOutUserArgs = {
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  endEvidence?: InputMaybe<EndEvidenceInput>;
  injuryReportId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type MutationCompleteProjectArgs = {
  id: Scalars['String']['input'];
};


export type MutationCreateAssetBillingClassificationArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateCustomerArgs = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberExt?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateDeliverableUnitArgs = {
  color: Scalars['String']['input'];
  description: Scalars['String']['input'];
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  unitOfMeasure: Scalars['String']['input'];
};


export type MutationCreateDivisionArgs = {
  address?: InputMaybe<OrganizationAddressInput>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};


export type MutationCreateInjuryReportArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  signaturePath: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationCreateManyTaskReportsArgs = {
  projectId: Scalars['String']['input'];
  taskReports: Array<TaskReportInput>;
};


export type MutationCreateOfflineEventArgs = {
  clientCreatedAt: Scalars['DateTime']['input'];
  clockInData?: InputMaybe<ClockInDataInput>;
  clockOutData?: InputMaybe<ClockOutDataInput>;
  fraudDetected?: InputMaybe<Scalars['Boolean']['input']>;
  fraudEvidence?: InputMaybe<FraudEvidenceDataInput>;
  injuryReportData?: InputMaybe<InjuryReportDataInput>;
  location: GeolocationCoordinatesInput;
  reassignData?: InputMaybe<ReassignmentDataInput>;
  reportTaskProgressData?: InputMaybe<TaskProgressEventDataInput>;
  type: OfflineEventType;
};


export type MutationCreateOfflineEventsArgs = {
  events: Array<OfflineEventInput>;
};


export type MutationCreateOneContractArgs = {
  assetBillingClassifications?: InputMaybe<Array<ContractAssetBillingClassificationInput>>;
  customerId: Scalars['String']['input'];
  deliverableUnits: Array<ContractDeliverableUnitInput>;
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  number?: InputMaybe<Scalars['String']['input']>;
  userBillingClassifications?: InputMaybe<Array<ContractUserBillingClassificationInput>>;
};


export type MutationCreateScheduleArgs = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  nonWorkDays?: InputMaybe<Array<ScheduleNonWorkDaysInput>>;
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>;
  workDays?: InputMaybe<Array<ScheduleWorkDaysInput>>;
  workHours?: InputMaybe<ScheduleWorkHoursInput>;
};


export type MutationCreateTaskDependencyArgs = {
  dependentTaskId: Scalars['String']['input'];
  metadata?: InputMaybe<TaskDependencyDataInput>;
  predecessorTaskId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  type: TaskDependencyType;
};


export type MutationCreateUnitGoalArgs = {
  goal: UnitGoalInput;
  taskId: Scalars['String']['input'];
};


export type MutationCreateUnitOfMeasureArgs = {
  name: Scalars['String']['input'];
  symbol: Scalars['String']['input'];
};


export type MutationCreateUserArgs = {
  sendInvite?: Scalars['Boolean']['input'];
  user: NewUser;
};


export type MutationCreateUserAssignmentArgs = {
  projectId: Scalars['String']['input'];
  taskId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type MutationCreateUserBillingClassificationArgs = {
  name: Scalars['String']['input'];
};


export type MutationCreateUserNotificationArgs = {
  assetId?: InputMaybe<Scalars['String']['input']>;
  modelType: UserNotificationModelType;
  type: UserNotificationType;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateVendorArgs = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberExt?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateVendorContactArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberExt?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
  vendorId: Scalars['String']['input'];
};


export type MutationCreateWorkersCompCodeArgs = {
  name: Scalars['String']['input'];
  ncciCode: Scalars['String']['input'];
  ncciDescription: Scalars['String']['input'];
  override: Scalars['Boolean']['input'];
  rate: Scalars['Float']['input'];
};


export type MutationDeleteAssetBillingClassificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCustomerArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteDeliverableUnitArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteNonWorkDayArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOneAssetArgs = {
  id: Scalars['String']['input'];
  status: AssetStatus;
};


export type MutationDeleteOneContractArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOneFileArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOneTaskArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOneTaskGroupArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOneTimeEntryArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteOneUserArgs = {
  id: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeleteProjectScheduleAndBreaksArgs = {
  projectId: Scalars['String']['input'];
  scheduleId: Scalars['String']['input'];
};


export type MutationDeleteReportTemplateArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteScheduledBreakArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTaskDependencyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteTaskScheduleAndBreaksArgs = {
  scheduleId: Scalars['String']['input'];
};


export type MutationDeleteUnitGoalArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUnitOfMeasureArgs = {
  id: Scalars['Int']['input'];
};


export type MutationDeleteUserAssignmentArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserBillingClassificationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteUserDeviceSessionArgs = {
  deviceSessionId: Scalars['String']['input'];
};


export type MutationDeleteVendorArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteVendorContactArgs = {
  id: Scalars['String']['input'];
};


export type MutationDismissAssetRepairArgs = {
  report: ReportAssetRepairInput;
};


export type MutationDuplicateReportTemplateArgs = {
  id: Scalars['String']['input'];
};


export type MutationDuplicateScheduleArgs = {
  id: Scalars['String']['input'];
};


export type MutationEditDeliverableUnitArgs = {
  color: Scalars['String']['input'];
  description: Scalars['String']['input'];
  id: Scalars['String']['input'];
  referenceNumber?: InputMaybe<Scalars['String']['input']>;
  unitOfMeasure: Scalars['String']['input'];
};


export type MutationEditDivisionArgs = {
  address?: InputMaybe<OrganizationAddressInput>;
  featureToggleSegments?: InputMaybe<Array<FeatureToggleSegments>>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notificationSettings?: InputMaybe<Scalars['JSON']['input']>;
  organizationId: Scalars['String']['input'];
  overtimeType?: InputMaybe<OvertimeTypeEnum>;
  requireInjuryReportOnClockout?: InputMaybe<Scalars['Boolean']['input']>;
  requireTimeEntryEvidence?: InputMaybe<Scalars['Boolean']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  timezone: Scalars['String']['input'];
};


export type MutationEditDivisionSettingsArgs = {
  allowTeamMembersClockInOut?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  notificationSettings?: InputMaybe<Scalars['JSON']['input']>;
  overtimeType?: InputMaybe<OvertimeTypeEnum>;
  requireInjuryReportOnClockout?: InputMaybe<Scalars['Boolean']['input']>;
  requireTimeEntryEvidence?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationEditOrganizationArgs = {
  address?: InputMaybe<OrganizationAddressInput>;
  allowTeamMembersClockInOut?: InputMaybe<Scalars['Boolean']['input']>;
  featureToggleSegments?: InputMaybe<Array<FeatureToggleSegments>>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notificationSettings?: InputMaybe<Scalars['JSON']['input']>;
  overtimeType?: InputMaybe<OvertimeTypeEnum>;
  requireInjuryReportOnClockout?: InputMaybe<Scalars['Boolean']['input']>;
  requireTimeEntryEvidence?: InputMaybe<Scalars['Boolean']['input']>;
  stripeCustomerId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditScheduledBreakArgs = {
  breakTaskId?: InputMaybe<Scalars['String']['input']>;
  durationInMinutes?: InputMaybe<Scalars['Int']['input']>;
  id: Scalars['String']['input'];
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isUnpaid?: InputMaybe<Scalars['Boolean']['input']>;
  localizedStartTime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationEditUnitGoalArgs = {
  goal: UnitGoalInput;
  id: Scalars['String']['input'];
};


export type MutationEditUnitOfMeasureArgs = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
  symbol: Scalars['String']['input'];
};


export type MutationEndAllMasqueradeSessionsForUserArgs = {
  subjectUserId: Scalars['String']['input'];
};


export type MutationGrantDivisionAccessToUserArgs = {
  divisionIds?: InputMaybe<Array<Scalars['String']['input']>>;
  grantPrimary?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationInsertManyAssetReportsArgs = {
  reports: Array<AssetReportInput>;
};


export type MutationInsertManyTasksArgs = {
  projectId: Scalars['String']['input'];
  taskGroups?: InputMaybe<Array<GroupInput>>;
  tasks?: InputMaybe<Array<TaskInput>>;
};


export type MutationInsertOneAssetArgs = {
  assignableId: Scalars['String']['input'];
  assignableType: AssetAssignableType;
  billingClassificationId?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<AssetCategory>;
  companyAssetNumber?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  groupQuantity?: InputMaybe<Scalars['Int']['input']>;
  inspectionRequirements?: InputMaybe<Array<AssetInspectionRequirementsInput>>;
  inventoryRequirements?: InputMaybe<AssetInventoryRequirementsInput>;
  isAssetGroup?: InputMaybe<Scalars['Boolean']['input']>;
  manufacturer?: InputMaybe<AssetManufacturerInput>;
  name: Scalars['String']['input'];
  ownershipType: Scalars['String']['input'];
  photoId?: InputMaybe<Scalars['String']['input']>;
  purchaseDetails?: InputMaybe<AssetPurchaseDetailsInput>;
  rentalAgreement?: InputMaybe<AssetRentalAgreementInput>;
  reportTemplateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  vendorContact?: InputMaybe<AssetVendorContactInput>;
};


export type MutationInsertOneProjectArgs = {
  address?: InputMaybe<AddressInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  contractId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  location?: InputMaybe<Scalars['JSON']['input']>;
  locationNotes?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Array<MetadataNoteInput>>;
  name: Scalars['String']['input'];
  schedule?: InputMaybe<ScheduleInput>;
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  taskGroups?: InputMaybe<Array<GroupInput>>;
  tasks?: InputMaybe<Array<TaskInput>>;
  vendors?: InputMaybe<Array<Scalars['String']['input']>>;
  workersCompCodeId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInsertOneTaskArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedHours?: InputMaybe<Scalars['Float']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Array<MetadataNoteInput>>;
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  schedule?: InputMaybe<ScheduleInput>;
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  unitGoals?: InputMaybe<Array<UnitGoalInput>>;
  workersCompCodeId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInsertOneTaskGroupArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Array<MetadataNoteInput>>;
  projectId: Scalars['String']['input'];
};


export type MutationInsertOneTimeEntryArgs = {
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  startAt: Scalars['DateTime']['input'];
  startEvidence?: InputMaybe<StartEvidenceInput>;
  taskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationInsertReportTemplateArgs = {
  fields: Array<AssetReportTemplateFieldInput>;
  name: Scalars['String']['input'];
  reusable: Scalars['Boolean']['input'];
  universal?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationMarkAllNotificationsReadArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMarkNotificationReadArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationMarkNotificationsReadByIdArgs = {
  ids?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationMarkOrganizationNotPastDueArgs = {
  id: Scalars['String']['input'];
};


export type MutationMarkOrganizationPastDueArgs = {
  id: Scalars['String']['input'];
};


export type MutationMarkTaskCompletionArgs = {
  completed: Scalars['Boolean']['input'];
  id: Scalars['String']['input'];
};


export type MutationMasqueradeUserArgs = {
  divisionId?: InputMaybe<Scalars['String']['input']>;
  subjectUserId: Scalars['String']['input'];
};


export type MutationReassignUserArgs = {
  projectId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationReassignUsersArgs = {
  assignments: Array<UserAssignmentAssignment>;
};


export type MutationReportAssetRepairArgs = {
  report: ReportAssetRepairInput;
};


export type MutationReportTaskProgressArgs = {
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  note?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  unitGoalProgress?: InputMaybe<Array<UnitGoalProgressInput>>;
};


export type MutationResendUserInvitesArgs = {
  userIds: Array<Scalars['String']['input']>;
};


export type MutationResolveOfflineEventArgs = {
  id: Scalars['String']['input'];
};


export type MutationRestoreOneAssetArgs = {
  id: Scalars['String']['input'];
};


export type MutationRestoreOneUserArgs = {
  id: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationReturnQuantityToInventoryArgs = {
  assetGroupId: Scalars['String']['input'];
  assignableId: Scalars['String']['input'];
  assignableType: AssetAssignableType;
  quantityToReturn: Scalars['Int']['input'];
  status: AssetStatus;
};


export type MutationRevokeDivisionAccessFromUserArgs = {
  divisionId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  revokePrimary?: InputMaybe<Scalars['Boolean']['input']>;
  userId: Scalars['String']['input'];
};


export type MutationSwitchDivisionArgs = {
  currentDivisionId?: InputMaybe<Scalars['String']['input']>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSwitchDivisionAssignmentForUserArgs = {
  assetRetainment: UserAssetRetainmentOption;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  maintainDivisionAccess: Scalars['Boolean']['input'];
  organizationId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type MutationSwitchDivisionForAssetArgs = {
  assetId: Scalars['String']['input'];
  assetRetainment: AssetRetainmentOption;
  assignableId: Scalars['String']['input'];
  assignableType: AssetAssignableType;
  divisionId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  projectIdIfTask?: InputMaybe<Scalars['String']['input']>;
};


export type MutationToggleAssetReportTemplateStateArgs = {
  id: Scalars['String']['input'];
};


export type MutationTransferAssetsArgs = {
  assetGroupReassignments?: InputMaybe<Array<AssetGroupReassignmentSpecification>>;
  assetIds: Array<Scalars['String']['input']>;
  assignableId: Scalars['String']['input'];
  assignableType: AssetAssignableType;
  projectIdIfTask?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUnarchiveQuantitiesArgs = {
  assetGroupId: Scalars['String']['input'];
  assignableId: Scalars['String']['input'];
  assignableType: AssetAssignableType;
  quantityToUnarchive: Scalars['Int']['input'];
  status: AssetStatus;
};


export type MutationUpdateAssetBillingClassificationArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateCustomerArgs = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberExt?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateLastExportArgs = {
  entityId?: InputMaybe<Scalars['String']['input']>;
  entityType?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOneAssetArgs = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  assigneeId?: InputMaybe<Scalars['String']['input']>;
  billingClassificationId?: InputMaybe<Scalars['String']['input']>;
  category?: InputMaybe<AssetCategory>;
  companyAssetNumber?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  inspectionRequirements?: InputMaybe<Array<AssetInspectionRequirementsInput>>;
  inventoryRequirements?: InputMaybe<AssetInventoryRequirementsInput>;
  manufacturer?: InputMaybe<AssetManufacturerInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  ownershipType?: InputMaybe<Scalars['String']['input']>;
  photoId?: InputMaybe<Scalars['String']['input']>;
  purchaseDetails?: InputMaybe<AssetPurchaseDetailsInput>;
  rentalAgreement?: InputMaybe<AssetRentalAgreementInput>;
  reportTemplateIds?: InputMaybe<Array<Scalars['String']['input']>>;
  status?: InputMaybe<AssetStatus>;
  vendorContact?: InputMaybe<AssetVendorContactInput>;
};


export type MutationUpdateOneContractArgs = {
  additionalAssetBillingClassifications?: InputMaybe<Array<ContractAssetBillingClassificationInput>>;
  additionalDeliverableUnits?: InputMaybe<Array<ContractDeliverableUnitInput>>;
  additionalUserBillingClassifications?: InputMaybe<Array<ContractUserBillingClassificationInput>>;
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  number?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOneFileArgs = {
  expiresAt?: InputMaybe<Scalars['DateTime']['input']>;
  fileName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  status?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOneProjectArgs = {
  address?: InputMaybe<AddressInput>;
  code?: InputMaybe<Scalars['String']['input']>;
  contractId?: InputMaybe<Scalars['String']['input']>;
  customerId?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  googlePlace?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  isArchived?: InputMaybe<Scalars['DateTime']['input']>;
  isComplete?: InputMaybe<Scalars['DateTime']['input']>;
  location?: InputMaybe<Scalars['JSON']['input']>;
  locationNotes?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Array<MetadataNoteInput>>;
  metadataToAdd?: InputMaybe<Array<MetadataNoteInput>>;
  metadataToRemove?: InputMaybe<Array<MetadataNoteInput>>;
  name?: InputMaybe<Scalars['String']['input']>;
  schedule?: InputMaybe<ScheduleInput>;
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  unassignedTaskId?: InputMaybe<Scalars['String']['input']>;
  vendors?: InputMaybe<Array<Scalars['String']['input']>>;
  workersCompCodeId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOneTaskArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedHours?: InputMaybe<Scalars['Float']['input']>;
  groupId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  isComplete?: InputMaybe<Scalars['DateTime']['input']>;
  metadata?: InputMaybe<Array<MetadataNoteInput>>;
  metadataToAdd?: InputMaybe<Array<MetadataNoteInput>>;
  metadataToRemove?: InputMaybe<Array<MetadataNoteInput>>;
  name: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  schedule?: InputMaybe<ScheduleInput>;
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  unitGoals?: InputMaybe<Array<UnitGoalInput>>;
  workersCompCodeId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOneTaskGroupArgs = {
  description?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  notesToAdd?: InputMaybe<Array<MetadataNoteInput>>;
  notesToRemove?: InputMaybe<Array<MetadataNoteInput>>;
  projectId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOneTimeEntryArgs = {
  endAt?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['String']['input'];
  startAt?: InputMaybe<Scalars['DateTime']['input']>;
  taskId: Scalars['String']['input'];
};


export type MutationUpdateOneUserArgs = {
  sendInvite?: InputMaybe<Scalars['Boolean']['input']>;
  user: UserUpdateModel;
};


export type MutationUpdatePasswordArgs = {
  currentPassword: Scalars['String']['input'];
  newPassword: Scalars['String']['input'];
};


export type MutationUpdateReportTemplateArgs = {
  fields?: InputMaybe<Array<AssetReportTemplateFieldInput>>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
  reusable?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationUpdateScheduleArgs = {
  id: Scalars['String']['input'];
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  nonWorkDays?: InputMaybe<Array<ScheduleNonWorkDaysInput>>;
  scheduledBreaks?: InputMaybe<Array<ScheduledBreakInput>>;
  workDays?: InputMaybe<Array<ScheduleWorkDaysInput>>;
  workHours?: InputMaybe<ScheduleWorkHoursInput>;
};


export type MutationUpdateTaskDependencyArgs = {
  dependentTaskId: Scalars['String']['input'];
  id: Scalars['String']['input'];
  metadata: TaskDependencyDataInput;
  predecessorTaskId: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  type: TaskDependencyType;
};


export type MutationUpdateTaskSortOrderArgs = {
  sortOrder: Scalars['Int']['input'];
  taskId: Scalars['String']['input'];
  type: Scalars['String']['input'];
};


export type MutationUpdateUserAssignmentArgs = {
  id: Scalars['String']['input'];
  projectId: Scalars['String']['input'];
  taskId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateUserBillingClassificationArgs = {
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
};


export type MutationUpdateVendorArgs = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberExt?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  vendorId?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateVendorContactArgs = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberExt?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpsertDivisionDeliverableUnitArgs = {
  active: Scalars['Boolean']['input'];
  deliverableUnitId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
};

export type NewUser = {
  billingClassificationId?: InputMaybe<Scalars['String']['input']>;
  companyUserId?: InputMaybe<Scalars['String']['input']>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  jobTitle: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  organizationId?: InputMaybe<Scalars['String']['input']>;
  payRate?: InputMaybe<Scalars['String']['input']>;
  payType?: InputMaybe<PayType>;
  phoneNumber: Scalars['String']['input'];
  phoneNumberExt?: InputMaybe<Scalars['String']['input']>;
  roles: Array<Scalars['String']['input']>;
  workersCompCodeId?: InputMaybe<Scalars['String']['input']>;
};

export type OfflineEvent = {
  __typename?: 'OfflineEvent';
  /** @deprecated Please don't use outside of debugging purposes */
  actionPayload: Scalars['String']['output'];
  assetReportData?: Maybe<Scalars['JSON']['output']>;
  clientCanceledAt?: Maybe<Scalars['DateTime']['output']>;
  clientCreatedAt: Scalars['DateTime']['output'];
  clientUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  clockInData?: Maybe<ClockInData>;
  clockOutData?: Maybe<ClockOutData>;
  createdAt: Scalars['DateTime']['output'];
  deviceInformation?: Maybe<Scalars['JSON']['output']>;
  errors?: Maybe<Scalars['String']['output']>;
  fraudDetected: Scalars['Boolean']['output'];
  fraudEvidence?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  injuryReportData?: Maybe<InjuryReportData>;
  issues?: Maybe<Scalars['String']['output']>;
  location?: Maybe<GeolocationPosition>;
  markedAsResolvedAt?: Maybe<Scalars['DateTime']['output']>;
  markedAsResolvedBy?: Maybe<User>;
  markedAsResolvedById?: Maybe<Scalars['String']['output']>;
  mobileApplicationIsVerified: Scalars['Boolean']['output'];
  organizationId: Scalars['ID']['output'];
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  reassignData?: Maybe<ReassignmentData>;
  receivedAt: Scalars['DateTime']['output'];
  reconciledAt?: Maybe<Scalars['DateTime']['output']>;
  reporterId: Scalars['ID']['output'];
  requestInformation?: Maybe<Scalars['JSON']['output']>;
  taskProgressData?: Maybe<TaskProgressEventData>;
  type: OfflineEventType;
  updatedAt: Scalars['DateTime']['output'];
};

export type OfflineEventInput = {
  clientCanceledAt?: InputMaybe<Scalars['DateTime']['input']>;
  clientCreatedAt: Scalars['DateTime']['input'];
  clockInData?: InputMaybe<ClockInDataInput>;
  clockOutData?: InputMaybe<ClockOutDataInput>;
  fraudDetected?: InputMaybe<Scalars['Boolean']['input']>;
  fraudEvidence?: InputMaybe<FraudEvidenceDataInput>;
  id: Scalars['String']['input'];
  injuryReportData?: InputMaybe<InjuryReportDataInput>;
  location: GeolocationPositionInput;
  reassignData?: InputMaybe<ReassignmentDataInput>;
  reportTaskProgressData?: InputMaybe<TaskProgressEventDataInput>;
  type: OfflineEventType;
};

export enum OfflineEventType {
  AssetReportEvent = 'AssetReportEvent',
  ClockInEvent = 'ClockInEvent',
  ClockOutEvent = 'ClockOutEvent',
  InjuryReportEvent = 'InjuryReportEvent',
  ReassignEvent = 'ReassignEvent',
  TaskProgressEvent = 'TaskProgressEvent'
}

export enum OperationEnum {
  Delete = 'DELETE',
  Insert = 'INSERT',
  Unknown = 'UNKNOWN',
  Update = 'UPDATE'
}

export type Organization = {
  __typename?: 'Organization';
  activeProjectsCount: Scalars['Int']['output'];
  activeUsersForDateRangeCount: Scalars['Int']['output'];
  address?: Maybe<OrganizationAddress>;
  adminCount: Scalars['Int']['output'];
  admins: Array<User>;
  allowTeamMembersClockInOut: Scalars['Boolean']['output'];
  archived: Scalars['Boolean']['output'];
  assetCount: Scalars['Int']['output'];
  billingPastDueAt?: Maybe<Scalars['DateTime']['output']>;
  billingStatus: BillingStatus;
  clockedInUserCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  dailyDigestData: Array<DigestDataDetails>;
  /** @deprecated Use `createdAt` instead */
  dateCreated: Scalars['DateTime']['output'];
  divisionCount: Scalars['Int']['output'];
  divisions: Array<Division>;
  featureToggleSegments: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** @deprecated Use imageUrl instead */
  image?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  isCurrentAssignment: Scalars['Boolean']['output'];
  isPrimary: Scalars['Boolean']['output'];
  lastStripeInvoice?: Maybe<StripeInvoice>;
  name?: Maybe<Scalars['String']['output']>;
  notificationSettings: Scalars['JSON']['output'];
  overtimeType: OvertimeTypeEnum;
  projectsCount: Scalars['Int']['output'];
  requireInjuryReportOnClockout: Scalars['Boolean']['output'];
  requireTimeEntryEvidence: Scalars['Boolean']['output'];
  roles: Array<Role>;
  schedule?: Maybe<Schedule>;
  scheduledBreaks: Array<ScheduledBreak>;
  secondsClockedForCurrentWeek: Scalars['Int']['output'];
  stripeCustomerId?: Maybe<Scalars['String']['output']>;
  stripeInvoices: Array<StripeInvoice>;
  timezone: Scalars['String']['output'];
  unassignedProjectId: Scalars['String']['output'];
  unitsOfMeasure: Array<UnitOfMeasure>;
  userCount: Scalars['Int']['output'];
};


export type OrganizationActiveUsersForDateRangeCountArgs = {
  rangeEnd: Scalars['DateTime']['input'];
  rangeStart: Scalars['DateTime']['input'];
};


export type OrganizationDailyDigestDataArgs = {
  rangeEnd: Scalars['DateTime']['input'];
  rangeStart: Scalars['DateTime']['input'];
};

export type OrganizationAddress = {
  __typename?: 'OrganizationAddress';
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  state?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type OrganizationAddressInput = {
  addressLine1?: InputMaybe<Scalars['String']['input']>;
  addressLine2?: InputMaybe<Scalars['String']['input']>;
  city?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  zip?: InputMaybe<Scalars['String']['input']>;
};

export enum OvertimeTypeEnum {
  DailyOvertime = 'DAILY_OVERTIME',
  StandardOvertime = 'STANDARD_OVERTIME'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']['output']>;
  hasNextPage: Scalars['Boolean']['output'];
  hasPreviousPage: Scalars['Boolean']['output'];
  startCursor?: Maybe<Scalars['String']['output']>;
};

export enum PayType {
  Hourly = 'HOURLY',
  Salary = 'SALARY'
}

export type PayrollExportRow = {
  __typename?: 'PayrollExportRow';
  code: Scalars['String']['output'];
  companyUserId: Scalars['String']['output'];
  dailyTotals: Array<Scalars['String']['output']>;
  daysWorked: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  jobTitle: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  overtime: Scalars['String']['output'];
  projectId: Scalars['String']['output'];
  regularTime: Scalars['String']['output'];
  totalPaidTime: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type ProductionRateData = {
  __typename?: 'ProductionRateData';
  actual: Scalars['Float']['output'];
  expected: Scalars['Float']['output'];
  rate: Scalars['Float']['output'];
};

export type Project = {
  __typename?: 'Project';
  activeTasksCount: Scalars['Int']['output'];
  address?: Maybe<Address>;
  assetCount: Scalars['Int']['output'];
  assets: Array<Asset>;
  assetsCount: Scalars['Int']['output'];
  assignees: Array<UserAssignment>;
  code?: Maybe<Scalars['String']['output']>;
  contract?: Maybe<Contract>;
  contractId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  customer?: Maybe<Customer>;
  customerId?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `createdAt` instead */
  dateCreated: Scalars['DateTime']['output'];
  /** @deprecated Please use unassignedTask instead as they are redundant */
  defaultTask: Task;
  deliveredPrimaryGoalUnits: Scalars['Float']['output'];
  description?: Maybe<Scalars['HTML']['output']>;
  divisionId?: Maybe<Scalars['String']['output']>;
  documents: Array<File>;
  editHistoryEvents: Array<EditHistoryEvent>;
  editHistoryEventsCount: Scalars['Float']['output'];
  endDate?: Maybe<Scalars['DateTime']['output']>;
  estimatedHours: Scalars['Float']['output'];
  ganttDetails: GanttProjectDetailsRef;
  /** @deprecated Not pertinent or used anymore */
  googlePlace?: Maybe<Scalars['JSON']['output']>;
  hasReportableUnit: Scalars['Boolean']['output'];
  hoursDataByDateRangeType: Scalars['JSON']['output'];
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  injuryReports: Array<InjuryReport>;
  isArchived?: Maybe<Scalars['DateTime']['output']>;
  isComplete?: Maybe<Scalars['DateTime']['output']>;
  isDefault: Scalars['Boolean']['output'];
  lastExport?: Maybe<Scalars['JSON']['output']>;
  location?: Maybe<LocationWithRadius>;
  locationNotes?: Maybe<Scalars['HTML']['output']>;
  /** @deprecated This field is untyped and replaced by location */
  locationUntyped?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated Please use `notes` instead for improved clarity */
  metadata?: Maybe<Array<MetadataNote>>;
  /** @deprecated Please use `notes` instead */
  metadataNotes?: Maybe<Array<MetadataNote>>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<MetadataNote>>;
  orgScheduleId?: Maybe<Scalars['String']['output']>;
  organization: Organization;
  organizationId: Scalars['String']['output'];
  primaryGoalTargetQuantity: Scalars['Float']['output'];
  productionRate: Scalars['Float']['output'];
  progressSummary: ProjectProgressSummary;
  schedule?: Maybe<Schedule>;
  scheduledBreaks?: Maybe<Array<ScheduledBreak>>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  taskDependencies: Array<TaskDependency>;
  taskGroups: Array<TaskGroup>;
  taskProgressEventsWithUnitReports: Array<TaskProgressEvent>;
  tasks: Array<Task>;
  tasksCount: Scalars['Int']['output'];
  timeEntries: Array<TimeEntry>;
  timeEntriesSumDurationInSeconds: Scalars['Float']['output'];
  unassignedTask: Task;
  unassignedTaskId?: Maybe<Scalars['String']['output']>;
  unitGoals: Array<UnitGoal>;
  userCount: Scalars['Int']['output'];
  users: Array<User>;
  vendors: Array<Vendor>;
  workersCompCode?: Maybe<WorkersCompCode>;
  workersCompCodeId?: Maybe<Scalars['String']['output']>;
};


export type ProjectAssetsArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ProjectAssetsCountArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ProjectDeliveredPrimaryGoalUnitsArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ProjectGanttDetailsArgs = {
  id: Scalars['String']['input'];
};


export type ProjectHoursDataByDateRangeTypeArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ProjectProductionRateArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ProjectTaskProgressEventsWithUnitReportsArgs = {
  rangeEnd?: InputMaybe<Scalars['String']['input']>;
  rangeStart?: InputMaybe<Scalars['String']['input']>;
};


export type ProjectTasksArgs = {
  includeArchived?: InputMaybe<Scalars['Boolean']['input']>;
  includeBreakTasks?: InputMaybe<Scalars['Boolean']['input']>;
  includeCompleted?: InputMaybe<Scalars['Boolean']['input']>;
  includeGroupTasks?: InputMaybe<Scalars['Boolean']['input']>;
  includeSubtasks?: InputMaybe<Scalars['Boolean']['input']>;
  visibilityFilter?: InputMaybe<ListVisibilityFilter>;
};


export type ProjectTimeEntriesArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ProjectTimeEntriesSumDurationInSecondsArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type ProjectUsersArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type ProjectFilter = {
  customerId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  projectIds?: InputMaybe<Array<Scalars['String']['input']>>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  status?: ProjectStatus;
  vendorId?: InputMaybe<Scalars['String']['input']>;
};

export type ProjectProgressSummary = {
  __typename?: 'ProjectProgressSummary';
  completedHours: Scalars['Float']['output'];
  completedUnits: Scalars['Float']['output'];
  completionPercentage: Scalars['Float']['output'];
  noHours?: Maybe<Scalars['Boolean']['output']>;
  targetHours: Scalars['Float']['output'];
  targetUnits: Scalars['Float']['output'];
};

export type ProjectSort = {
  by?: ProjectSortByInput;
  direction?: SortDirectionInput;
};

export enum ProjectSortByInput {
  EndDate = 'endDate',
  Name = 'name',
  StartDate = 'startDate'
}

export enum ProjectStatus {
  Active = 'active',
  Archived = 'archived',
  Completed = 'completed'
}

export type ProjectVendor = {
  __typename?: 'ProjectVendor';
  createdAt: Scalars['DateTime']['output'];
  project: Project;
  vendor: Vendor;
};

export type ProjectVendorInput = {
  projectId: Scalars['String']['input'];
  vendorId: Scalars['String']['input'];
};

export type ProjectVendorInputData = {
  __typename?: 'ProjectVendorInputData';
  projectId: Scalars['String']['output'];
  vendorId: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  activeProjectCount: Scalars['Int']['output'];
  activeProjects: Array<Project>;
  activeProjectsPaginated: QueryActiveProjectsPaginatedConnection;
  archivedAssetGroups: Array<AssetGroup>;
  archivedAssets: QueryArchivedAssetsConnection;
  asset: Asset;
  assetBillingClassification?: Maybe<AssetBillingClassification>;
  assetBillingClassifications: Array<AssetBillingClassification>;
  assetGroups: Array<AssetGroup>;
  assetRepairRequest: AssetRepairRequest;
  assetRepairRequests: Array<AssetRepairRequest>;
  assetReport: AssetReport;
  assetReportTemplate: AssetReportTemplate;
  assetReports: Array<AssetReport>;
  assets: Array<Asset>;
  assets_2: QueryAssets_2Connection;
  assignedAssets: Array<AssignedAsset>;
  contract: Contract;
  contracts: Array<Contract>;
  currentUser: User;
  customer: Customer;
  customers: Array<Customer>;
  deliverableUnit: DeliverableUnit;
  deliverableUnits: Array<DeliverableUnit>;
  dependentTaskDependencies: Array<TaskDependency>;
  dependentTasks: Array<Task>;
  division: Division;
  divisions: Array<Division>;
  editHistoryEvent: EditHistoryEvent;
  editHistoryEvents: Array<EditHistoryEvent>;
  getJobTitles: Array<Scalars['String']['output']>;
  myDivision: Division;
  myDivisions?: Maybe<Array<Division>>;
  myNotifications: Array<UserNotification>;
  myOrganization: Organization;
  nestedAssets: Array<Asset>;
  nestedAssetsForInventory: Array<AssignedAsset>;
  offlineEvent: OfflineEvent;
  offlineEvents: Array<OfflineEvent>;
  organization: Organization;
  organizations: Array<Organization>;
  payrollExportRows: Array<PayrollExportRow>;
  predecessorTaskDependencies: Array<TaskDependency>;
  predecessorTasks: Array<Task>;
  project?: Maybe<Project>;
  projects: Array<Project>;
  projectsByStatus: Array<Project>;
  projectsPaginated: QueryProjectsPaginatedConnection;
  reusableAssetReportTemplates: Array<AssetReportTemplate>;
  roles: Array<Role>;
  scheduledBreaks: Array<ScheduledBreak>;
  schedules: Array<Schedule>;
  settings: Settings;
  task: Task;
  taskDependency?: Maybe<TaskDependency>;
  taskGroup?: Maybe<TaskGroup>;
  taskGroups: Array<TaskGroup>;
  taskList: Array<TaskListItem>;
  taskProgressEvent: TaskProgressEvent;
  taskProgressEvents: Array<TaskProgressEvent>;
  tasks: Array<Task>;
  tasksForGroupId: Array<Task>;
  tasksPaginated: QueryTasksPaginatedConnection;
  timeCardWeek: TimeCardWeek;
  timeEntryDay: TimeEntryDay;
  timeEntryDays: TimeEntryDayRange;
  unitGoal?: Maybe<UnitGoal>;
  unitGoals: Array<UnitGoal>;
  user: User;
  userAssignments: Array<UserAssignment>;
  userBillingClassification?: Maybe<UserBillingClassification>;
  userBillingClassifications: Array<UserBillingClassification>;
  users: QueryUsersConnection;
  usersForSummarySection: Array<User>;
  usersList: Array<User>;
  vendor: Vendor;
  vendorContact: VendorContact;
  vendorContacts: Array<VendorContact>;
  vendors: Array<Vendor>;
  workersCompCodes: Array<WorkersCompCode>;
};


export type QueryActiveProjectsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryArchivedAssetGroupsArgs = {
  assetId: Scalars['String']['input'];
};


export type QueryArchivedAssetsArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  sort?: AssetSort;
  visibilityFilter?: InputMaybe<ListVisibilityFilter>;
};


export type QueryAssetArgs = {
  id: Scalars['String']['input'];
};


export type QueryAssetBillingClassificationArgs = {
  id: Scalars['String']['input'];
};


export type QueryAssetGroupsArgs = {
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  assetId?: InputMaybe<Scalars['String']['input']>;
  assetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryAssetRepairRequestArgs = {
  id: Scalars['String']['input'];
};


export type QueryAssetRepairRequestsArgs = {
  assetId: Scalars['String']['input'];
};


export type QueryAssetReportArgs = {
  reportId: Scalars['String']['input'];
};


export type QueryAssetReportTemplateArgs = {
  id: Scalars['String']['input'];
};


export type QueryAssetReportsArgs = {
  assetId: Scalars['String']['input'];
};


export type QueryAssetsArgs = {
  assetGroupId?: InputMaybe<Scalars['String']['input']>;
  assetGroupIds?: InputMaybe<Array<Scalars['String']['input']>>;
  assetId?: InputMaybe<Scalars['String']['input']>;
  assetIds?: InputMaybe<Array<Scalars['String']['input']>>;
  assignableId?: InputMaybe<Scalars['String']['input']>;
  assignableType?: InputMaybe<AssetAssignableType>;
  category?: InputMaybe<AssetCategory>;
  deleted?: InputMaybe<Scalars['Boolean']['input']>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  includeGroupedAssets?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<AssetStatus>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
  visibilityFilter?: InputMaybe<ListVisibilityFilter>;
};


export type QueryAssets_2Args = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: AssetFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: AssetSort;
  visibilityFilter?: InputMaybe<ListVisibilityFilter>;
};


export type QueryAssignedAssetsArgs = {
  filter?: AssignedAssetFilter;
  sort?: AssetSort;
};


export type QueryContractArgs = {
  id: Scalars['String']['input'];
};


export type QueryContractsArgs = {
  customerId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryCustomerArgs = {
  id: Scalars['String']['input'];
};


export type QueryDeliverableUnitArgs = {
  id: Scalars['String']['input'];
};


export type QueryDeliverableUnitsArgs = {
  contractId?: InputMaybe<Scalars['String']['input']>;
  filterByDivision?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryDependentTaskDependenciesArgs = {
  id: Scalars['String']['input'];
};


export type QueryDependentTasksArgs = {
  id: Scalars['String']['input'];
};


export type QueryDivisionArgs = {
  id?: InputMaybe<Scalars['String']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryDivisionsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  organizationId: Scalars['String']['input'];
};


export type QueryEditHistoryEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryEditHistoryEventsArgs = {
  taskId: Scalars['String']['input'];
};


export type QueryMyNotificationsArgs = {
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryNestedAssetsArgs = {
  assignableId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  includeInspectionTemplates?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryNestedAssetsForInventoryArgs = {
  id: Scalars['String']['input'];
};


export type QueryOfflineEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryOfflineEventsArgs = {
  processed?: InputMaybe<Scalars['Boolean']['input']>;
  reporterId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryOrganizationArgs = {
  id: Scalars['String']['input'];
};


export type QueryOrganizationsArgs = {
  archived?: InputMaybe<Scalars['Boolean']['input']>;
};


export type QueryPayrollExportRowsArgs = {
  afterCursor?: InputMaybe<Scalars['String']['input']>;
  approximatePageSize?: InputMaybe<Scalars['Int']['input']>;
  decimalPrecision?: InputMaybe<Scalars['Int']['input']>;
  emptyFormat?: InputMaybe<EmptyFormat>;
  rangeEndOn: Scalars['String']['input'];
  rangeStartOn: Scalars['String']['input'];
  round?: InputMaybe<Scalars['Boolean']['input']>;
  roundDirection?: InputMaybe<RoundDirection>;
  timeFormat?: InputMaybe<TimeFormat>;
};


export type QueryPredecessorTaskDependenciesArgs = {
  id: Scalars['String']['input'];
};


export type QueryPredecessorTasksArgs = {
  id: Scalars['String']['input'];
};


export type QueryProjectArgs = {
  divisionId?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};


export type QueryProjectsArgs = {
  filter?: ProjectFilter;
  sort?: ProjectSort;
  visibilityFilter?: InputMaybe<ListVisibilityFilter>;
};


export type QueryProjectsByStatusArgs = {
  divisionId?: InputMaybe<Scalars['String']['input']>;
  excludedProjectId?: InputMaybe<Scalars['String']['input']>;
  includeDefault?: InputMaybe<Scalars['Boolean']['input']>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  status: ProjectStatus;
  visibilityFilter?: InputMaybe<ListVisibilityFilter>;
};


export type QueryProjectsPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryReusableAssetReportTemplatesArgs = {
  divisionId?: InputMaybe<Scalars['String']['input']>;
  filterByActiveTemplates: Scalars['Boolean']['input'];
};


export type QueryScheduledBreaksArgs = {
  includeOrgBreaks?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
};


export type QuerySchedulesArgs = {
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTaskArgs = {
  id: Scalars['String']['input'];
};


export type QueryTaskDependencyArgs = {
  dependentTaskId?: InputMaybe<Scalars['String']['input']>;
  predecessorTaskId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
};


export type QueryTaskGroupArgs = {
  id: Scalars['String']['input'];
};


export type QueryTaskGroupsArgs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTaskListArgs = {
  archived: Scalars['Boolean']['input'];
  projectId: Scalars['String']['input'];
  searchText?: InputMaybe<Scalars['String']['input']>;
  visibilityFilter?: InputMaybe<ListVisibilityFilter>;
};


export type QueryTaskProgressEventArgs = {
  id: Scalars['String']['input'];
};


export type QueryTaskProgressEventsArgs = {
  filter?: InputMaybe<TaskProgressEventFilter>;
  projectId: Scalars['String']['input'];
  rangeEnd?: InputMaybe<Scalars['String']['input']>;
  rangeStart?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTasksArgs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<TaskStatus>;
  visibilityLevel?: InputMaybe<TaskVisibilityLevel>;
};


export type QueryTasksForGroupIdArgs = {
  groupId: Scalars['String']['input'];
};


export type QueryTasksPaginatedArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  onlyActive?: InputMaybe<Scalars['Boolean']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryTimeCardWeekArgs = {
  rangeEndOn: Scalars['String']['input'];
  rangeStartOn: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};


export type QueryTimeEntryDayArgs = {
  day: Scalars['String']['input'];
  projectId?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryTimeEntryDaysArgs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  rangeEndOn: Scalars['String']['input'];
  rangeStartOn: Scalars['String']['input'];
  taskId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};


export type QueryUnitGoalArgs = {
  id: Scalars['String']['input'];
};


export type QueryUnitGoalsArgs = {
  projectId: Scalars['String']['input'];
  taskId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserArgs = {
  id: Scalars['String']['input'];
};


export type QueryUserAssignmentsArgs = {
  userId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUserBillingClassificationArgs = {
  id: Scalars['String']['input'];
};


export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['String']['input']>;
  before?: InputMaybe<Scalars['String']['input']>;
  filter?: UserFilter;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  sort?: UserSort;
  visibilityFilter?: InputMaybe<ListVisibilityFilter>;
};


export type QueryUsersForSummarySectionArgs = {
  projectId: Scalars['String']['input'];
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryUsersListArgs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryVendorArgs = {
  id: Scalars['String']['input'];
};


export type QueryVendorContactArgs = {
  id: Scalars['String']['input'];
};


export type QueryVendorContactsArgs = {
  vendorId: Scalars['String']['input'];
};

export type QueryActiveProjectsPaginatedConnection = {
  __typename?: 'QueryActiveProjectsPaginatedConnection';
  edges: Array<Maybe<QueryActiveProjectsPaginatedConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryActiveProjectsPaginatedConnectionEdge = {
  __typename?: 'QueryActiveProjectsPaginatedConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Project;
};

export type QueryArchivedAssetsConnection = {
  __typename?: 'QueryArchivedAssetsConnection';
  edges: Array<Maybe<QueryArchivedAssetsConnectionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QueryArchivedAssetsConnectionEdge = {
  __typename?: 'QueryArchivedAssetsConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Asset;
};

export type QueryAssets_2Connection = {
  __typename?: 'QueryAssets_2Connection';
  edges: Array<Maybe<QueryAssets_2ConnectionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QueryAssets_2ConnectionEdge = {
  __typename?: 'QueryAssets_2ConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Asset;
};

export type QueryProjectsPaginatedConnection = {
  __typename?: 'QueryProjectsPaginatedConnection';
  edges: Array<Maybe<QueryProjectsPaginatedConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryProjectsPaginatedConnectionEdge = {
  __typename?: 'QueryProjectsPaginatedConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Project;
};

export type QueryTasksPaginatedConnection = {
  __typename?: 'QueryTasksPaginatedConnection';
  edges: Array<Maybe<QueryTasksPaginatedConnectionEdge>>;
  pageInfo: PageInfo;
};

export type QueryTasksPaginatedConnectionEdge = {
  __typename?: 'QueryTasksPaginatedConnectionEdge';
  cursor: Scalars['String']['output'];
  node: Task;
};

export type QueryUsersConnection = {
  __typename?: 'QueryUsersConnection';
  edges: Array<Maybe<QueryUsersConnectionEdge>>;
  pageInfo: PageInfo;
  totalCount: Scalars['Int']['output'];
};

export type QueryUsersConnectionEdge = {
  __typename?: 'QueryUsersConnectionEdge';
  cursor: Scalars['String']['output'];
  node: User;
};

export type ReassignmentData = {
  __typename?: 'ReassignmentData';
  newProject: Project;
  newProjectId: Scalars['String']['output'];
  newTask: Task;
  newTaskId: Scalars['String']['output'];
  oldProject: Project;
  oldProjectId: Scalars['String']['output'];
  oldTask: Task;
  oldTaskId: Scalars['String']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type ReassignmentDataInput = {
  newProjectId: Scalars['String']['input'];
  newTaskId: Scalars['String']['input'];
  oldProjectId: Scalars['String']['input'];
  oldTaskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type ReportAssetRepairInput = {
  assetId: Scalars['String']['input'];
  assetRepairId: Scalars['String']['input'];
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  notes?: InputMaybe<Scalars['String']['input']>;
  photoUrls?: InputMaybe<Array<Scalars['String']['input']>>;
  statusChange?: InputMaybe<AssetStatusChangeInput>;
};

export type RequestInfo = {
  __typename?: 'RequestInfo';
  browser?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  isBot?: Maybe<Scalars['Boolean']['output']>;
  isCurl?: Maybe<Scalars['Boolean']['output']>;
  isDesktop?: Maybe<Scalars['Boolean']['output']>;
  isMobile?: Maybe<Scalars['Boolean']['output']>;
  isTablet?: Maybe<Scalars['Boolean']['output']>;
  os?: Maybe<Scalars['String']['output']>;
  platform?: Maybe<Scalars['String']['output']>;
  region?: Maybe<Scalars['String']['output']>;
};

export type Role = {
  __typename?: 'Role';
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Use `createdAt` instead */
  dateCreated: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  order?: Maybe<Scalars['Int']['output']>;
  organization: Organization;
  organizationId: Scalars['String']['output'];
  permissions: Array<Scalars['String']['output']>;
  widgets: Scalars['JSON']['output'];
};

export enum RoundDirection {
  Down = 'DOWN',
  Nearest = 'NEAREST',
  Up = 'UP'
}

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['String']['output'];
  isDefault?: Maybe<Scalars['Boolean']['output']>;
  nonWorkDays?: Maybe<Array<ScheduleNonWorkDays>>;
  workDays?: Maybe<Array<ScheduleWorkDay>>;
  workHours?: Maybe<ScheduleWorkHours>;
};

export type ScheduleInput = {
  id?: InputMaybe<Scalars['String']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  nonWorkDays?: InputMaybe<Array<ScheduleNonWorkDaysInput>>;
  workDays?: InputMaybe<Array<ScheduleWorkDaysInput>>;
  workHours?: InputMaybe<ScheduleWorkHoursInput>;
};

/** ScheduleNonWorkDays */
export type ScheduleNonWorkDays = {
  __typename?: 'ScheduleNonWorkDays';
  active: Scalars['Boolean']['output'];
  dateRange: Array<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

/** ScheduleNonWorkDaysInput */
export type ScheduleNonWorkDaysInput = {
  active: Scalars['Boolean']['input'];
  dateRange: Array<Scalars['DateTime']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
};

/** ScheduleWorkDay */
export type ScheduleWorkDay = {
  __typename?: 'ScheduleWorkDay';
  active: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
};

/** ScheduleWorkDaysInput */
export type ScheduleWorkDaysInput = {
  active: Scalars['Boolean']['input'];
  label: Scalars['String']['input'];
};

/** ScheduleWorkHours */
export type ScheduleWorkHours = {
  __typename?: 'ScheduleWorkHours';
  endTime?: Maybe<Scalars['String']['output']>;
  hours?: Maybe<Scalars['Int']['output']>;
  startTime?: Maybe<Scalars['String']['output']>;
};

/** ScheduleWorkHoursInput */
export type ScheduleWorkHoursInput = {
  endTime?: InputMaybe<Scalars['String']['input']>;
  hours?: InputMaybe<Scalars['Int']['input']>;
  startTime?: InputMaybe<Scalars['String']['input']>;
};

export type ScheduledBreak = {
  __typename?: 'ScheduledBreak';
  breakTask: Task;
  breakTaskId: Scalars['String']['output'];
  durationInMinutes: Scalars['Int']['output'];
  id: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  localizedStartTime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  projectId?: Maybe<Scalars['String']['output']>;
};

export type ScheduledBreakInput = {
  breakTaskId: Scalars['String']['input'];
  durationInMinutes: Scalars['Int']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isActive: Scalars['Boolean']['input'];
  isUnpaid: Scalars['Boolean']['input'];
  localizedStartTime: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type SecurityActivity = {
  __typename?: 'SecurityActivity';
  createdAt: Scalars['DateTime']['output'];
  event: Scalars['String']['output'];
  ip?: Maybe<Scalars['String']['output']>;
  userAgent?: Maybe<Scalars['String']['output']>;
};

export type Settings = {
  __typename?: 'Settings';
  allowTeamMembersClockInOut: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  notificationSettings?: Maybe<Scalars['JSON']['output']>;
  overtimeType: OvertimeTypeEnum;
  requireInjuryReportOnClockout: Scalars['Boolean']['output'];
  requireTimeEntryEvidence: Scalars['Boolean']['output'];
};

export enum SortByInput {
  FirstName = 'firstName',
  ProjectName = 'projectName'
}

export enum SortDirectionInput {
  Asc = 'asc',
  Desc = 'desc'
}

export type StartEvidence = {
  __typename?: 'StartEvidence';
  imageUrl?: Maybe<Scalars['String']['output']>;
  location?: Maybe<GeolocationPosition>;
  locationError?: Maybe<Scalars['String']['output']>;
};

export type StartEvidenceInput = {
  imagePath?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<GeolocationPositionInput>;
  locationError?: InputMaybe<Scalars['String']['input']>;
};

/** Invoices created with Stripe */
export type StripeInvoice = {
  __typename?: 'StripeInvoice';
  accountName?: Maybe<Scalars['String']['output']>;
  amountDue: Scalars['Int']['output'];
  amountPaid: Scalars['Int']['output'];
  amountRemaining: Scalars['Int']['output'];
  attemptCount: Scalars['Int']['output'];
  attempted: Scalars['Boolean']['output'];
  autoAdvance?: Maybe<Scalars['Boolean']['output']>;
  billingReason?: Maybe<Scalars['String']['output']>;
  collectionMethod: Scalars['String']['output'];
  created?: Maybe<Scalars['DateTime']['output']>;
  currency: Scalars['String']['output'];
  customerEmail?: Maybe<Scalars['String']['output']>;
  customerName?: Maybe<Scalars['String']['output']>;
  customerPhone?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  hostedInvoiceUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoicePdf?: Maybe<Scalars['String']['output']>;
  livemode: Scalars['Boolean']['output'];
  object: Scalars['String']['output'];
  paid: Scalars['Boolean']['output'];
  status?: Maybe<Scalars['String']['output']>;
  subtotal: Scalars['Int']['output'];
  subtotalExcludingTax?: Maybe<Scalars['Int']['output']>;
  tax?: Maybe<Scalars['Int']['output']>;
  total: Scalars['Int']['output'];
};

export type Task = {
  __typename?: 'Task';
  archived: Scalars['Boolean']['output'];
  assetCount: Scalars['Int']['output'];
  assignees: Array<UserAssignment>;
  clockedInUserCount: Scalars['Int']['output'];
  clockedOutUserCount: Scalars['Int']['output'];
  completedHours: Scalars['Float']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Use `createdAt` instead */
  dateCreated: Scalars['DateTime']['output'];
  dependentTaskDependencies: Array<TaskDependency>;
  dependentTasks?: Maybe<Array<Task>>;
  description?: Maybe<Scalars['HTML']['output']>;
  divisionId?: Maybe<Scalars['String']['output']>;
  driverTaskDependency?: Maybe<TaskDependency>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  /** Hours, as used for Man-Hours */
  estimatedHours?: Maybe<Scalars['Float']['output']>;
  /** Days, as use for Work days */
  estimatedWorkDays?: Maybe<Scalars['Float']['output']>;
  /** Hours, as use for Work days */
  estimatedWorkHours?: Maybe<Scalars['Int']['output']>;
  group?: Maybe<TaskGroup>;
  groupId?: Maybe<Scalars['String']['output']>;
  hasReportableUnit: Scalars['Boolean']['output'];
  hoursDataByDateRangeType: Scalars['JSON']['output'];
  id: Scalars['String']['output'];
  injuryReports: Array<InjuryReport>;
  isComplete?: Maybe<Scalars['DateTime']['output']>;
  isDefault: Scalars['Boolean']['output'];
  isUnpaid: Scalars['Boolean']['output'];
  lastExport?: Maybe<Scalars['JSON']['output']>;
  /** @deprecated Please use `notes` instead */
  metadata?: Maybe<Array<MetadataNote>>;
  /** @deprecated Please use `notes` instead */
  metadataNotes?: Maybe<Array<MetadataNote>>;
  name: Scalars['String']['output'];
  notes?: Maybe<Array<MetadataNote>>;
  orgScheduleId?: Maybe<Scalars['String']['output']>;
  organizationId: Scalars['String']['output'];
  predecessorTaskDependencies: Array<TaskDependency>;
  predecessorTasks?: Maybe<Array<Task>>;
  primaryUnitGoals: Array<UnitGoal>;
  productionRate: ProductionRateData;
  project: Project;
  projectId: Scalars['String']['output'];
  projectScheduleId?: Maybe<Scalars['String']['output']>;
  schedule?: Maybe<Schedule>;
  scheduledBreaks?: Maybe<Array<ScheduledBreak>>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  sumOfDeliveredUnits: Scalars['Float']['output'];
  taskProgressEvents: Array<TaskProgressEvent>;
  taskProgressEventsWithUnitReports: Array<TaskProgressEvent>;
  timeEntries: Array<TimeEntry>;
  timeEntriesSumDurationInSeconds: Scalars['Float']['output'];
  unitGoals: Array<UnitGoal>;
  unitGoalsSumProgress: Scalars['Float']['output'];
  unitGoalsSumTargetQuantity: Scalars['Float']['output'];
  userCount: Scalars['Int']['output'];
  users: Array<User>;
  usersWhoClockedOrReportedCount: Scalars['Int']['output'];
  visibilityLevel: Scalars['String']['output'];
  workersCompCode?: Maybe<WorkersCompCode>;
  workersCompCodeId?: Maybe<Scalars['String']['output']>;
};


export type TaskAssetCountArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskCompletedHoursArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskHoursDataByDateRangeTypeArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskProductionRateArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskSumOfDeliveredUnitsArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskTaskProgressEventsArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskTaskProgressEventsWithUnitReportsArgs = {
  rangeEnd?: InputMaybe<Scalars['String']['input']>;
  rangeStart?: InputMaybe<Scalars['String']['input']>;
};


export type TaskTimeEntriesArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskTimeEntriesSumDurationInSecondsArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskUsersArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskUsersWhoClockedOrReportedCountArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskDependency = {
  __typename?: 'TaskDependency';
  dependentTaskId: Scalars['ID']['output'];
  id: Scalars['String']['output'];
  metadata?: Maybe<TaskDependencyData>;
  predecessorTaskId: Scalars['ID']['output'];
  projectId: Scalars['ID']['output'];
  type: TaskDependencyType;
};

export type TaskDependencyData = {
  __typename?: 'TaskDependencyData';
  offsetDays?: Maybe<Scalars['Int']['output']>;
};

export type TaskDependencyDataInput = {
  offsetDays?: InputMaybe<Scalars['Int']['input']>;
};

export enum TaskDependencyType {
  FinishToFinish = 'FINISH_TO_FINISH',
  FinishToStart = 'FINISH_TO_START',
  StartToStart = 'START_TO_START'
}

export type TaskGroup = {
  __typename?: 'TaskGroup';
  assetCount: Scalars['Int']['output'];
  completedHours: Scalars['Float']['output'];
  completedTaskCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['HTML']['output']>;
  endDate?: Maybe<Scalars['DateTime']['output']>;
  estimatedHours: Scalars['Float']['output'];
  id: Scalars['ID']['output'];
  isComplete: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Array<MetadataNote>>;
  organizationId: Scalars['ID']['output'];
  productionRate: Scalars['Float']['output'];
  projectId: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  startDate?: Maybe<Scalars['DateTime']['output']>;
  taskCount: Scalars['Int']['output'];
  tasks: Array<Task>;
  unitProgress: Scalars['Float']['output'];
  unitTargetGoal: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userCount: Scalars['Int']['output'];
};


export type TaskGroupAssetCountArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskGroupCompletedHoursArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskGroupProductionRateArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};


export type TaskGroupTasksArgs = {
  visibilityFilter?: InputMaybe<ListVisibilityFilter>;
};


export type TaskGroupUserCountArgs = {
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
};

export type TaskInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  estimatedHours?: InputMaybe<Scalars['Int']['input']>;
  isDefault?: InputMaybe<Scalars['Boolean']['input']>;
  name: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
  unitGoals?: InputMaybe<Array<UnitGoalInput>>;
  workDays?: InputMaybe<Scalars['Int']['input']>;
};

export type TaskListItem = {
  __typename?: 'TaskListItem';
  assetCount: Scalars['Int']['output'];
  completedHours?: Maybe<Scalars['Float']['output']>;
  completedTaskCount: Scalars['Int']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** endDate */
  endDate?: Maybe<Scalars['DateTime']['output']>;
  estimatedHours?: Maybe<Scalars['Float']['output']>;
  groupId?: Maybe<Scalars['String']['output']>;
  isComplete: Scalars['Boolean']['output'];
  isDefault: Scalars['Boolean']['output'];
  metadata?: Maybe<Array<MetadataNote>>;
  name: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  /** startDate */
  startDate?: Maybe<Scalars['DateTime']['output']>;
  taskCount: Scalars['Int']['output'];
  taskGroupId?: Maybe<Scalars['String']['output']>;
  taskId?: Maybe<Scalars['String']['output']>;
  unitProgress: Scalars['Int']['output'];
  unitTargetGoal: Scalars['Int']['output'];
  userCount: Scalars['Int']['output'];
};

export type TaskProgressEvent = {
  __typename?: 'TaskProgressEvent';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  imageUrls: Array<Maybe<Scalars['String']['output']>>;
  note?: Maybe<Scalars['String']['output']>;
  reporter: User;
  reporterId: Scalars['String']['output'];
  task: Task;
  taskId: Scalars['String']['output'];
  unitGoalProgressCount: Scalars['Int']['output'];
  unitGoalProgressReports: Array<UnitGoalProgress>;
  updatedAt: Scalars['DateTime']['output'];
};

export type TaskProgressEventData = {
  __typename?: 'TaskProgressEventData';
  fileUploadResponses?: Maybe<Array<FileUploadResponse>>;
  /** @deprecated This is poorly named, please use `fileUploadResponses`; We can remove it after mobile <0.0.4 is deprecated */
  fileUploads?: Maybe<Array<FileUpload>>;
  note?: Maybe<Scalars['String']['output']>;
  task: Task;
  taskId: Scalars['String']['output'];
  taskProgressEvent: TaskProgressEvent;
  taskProgressEventId: Scalars['String']['output'];
  unitGoalProgressInputs?: Maybe<Array<UnitGoalProgressInputData>>;
};

export type TaskProgressEventDataInput = {
  filesToUpload?: InputMaybe<Array<FileUploadRequest>>;
  note?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['String']['input'];
  taskProgressEventId: Scalars['String']['input'];
  unitGoalProgressInputs: Array<UnitGoalProgressInput>;
};

export enum TaskProgressEventFilter {
  Images = 'IMAGES',
  Notes = 'NOTES'
}

export type TaskReportInput = {
  fileIds?: InputMaybe<Array<Scalars['String']['input']>>;
  note?: InputMaybe<Scalars['String']['input']>;
  taskId: Scalars['String']['input'];
  unitGoalProgress?: InputMaybe<Array<UnitGoalProgressInput>>;
};

export enum TaskStatus {
  Active = 'active',
  Archived = 'archived',
  Completed = 'completed'
}

export enum TaskVisibilityLevel {
  /** Return hidden records */
  Hidden = 'HIDDEN',
  /** Return public tasks */
  Public = 'PUBLIC'
}

export type TimeCardWeek = {
  __typename?: 'TimeCardWeek';
  days: Array<TimeEntryDay>;
  overtime: Scalars['Int']['output'];
  regularTime: Scalars['Int']['output'];
  timezone: Scalars['String']['output'];
  totalEditedTimeInSeconds: Scalars['Int']['output'];
  totalPaidTimeInSeconds: Scalars['Int']['output'];
  totalTimeInSeconds: Scalars['Int']['output'];
};

export type TimeEntry = {
  __typename?: 'TimeEntry';
  createdAt: Scalars['DateTime']['output'];
  durationInSeconds: Scalars['Float']['output'];
  editData: TimeEntryWrapper;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  endAtEditor: User;
  endAtEditorId?: Maybe<Scalars['String']['output']>;
  evidence: Scalars['JSON']['output'];
  evidenceType: TimeEntryEvidence;
  id: Scalars['String']['output'];
  /** Tells us if this was a break or not */
  isBreak: Scalars['Boolean']['output'];
  isUnpaid: Scalars['Boolean']['output'];
  organization: Organization;
  organizationId: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['String']['output'];
  signInPhotoUrl?: Maybe<Scalars['String']['output']>;
  signOutPhotoUrl?: Maybe<Scalars['String']['output']>;
  startAt: Scalars['DateTime']['output'];
  startAtEditor: User;
  startAtEditorId: Scalars['String']['output'];
  task: Task;
  taskId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type TimeEntryChangeset = {
  __typename?: 'TimeEntryChangeset';
  action: ActionTypeEnum;
  actor?: Maybe<User>;
  actorId?: Maybe<Scalars['String']['output']>;
  changes: TimeEntryChangesetChanges;
  id: Scalars['Int']['output'];
  operation: OperationEnum;
  timestamp: Scalars['DateTime']['output'];
  totalEditedTimeInSeconds: Scalars['Int']['output'];
  value?: Maybe<TimeEntry>;
};

export type TimeEntryChangesetChanges = {
  __typename?: 'TimeEntryChangesetChanges';
  endAt?: Maybe<Scalars['DateTime']['output']>;
  startAt?: Maybe<Scalars['DateTime']['output']>;
};

export type TimeEntryDay = {
  __typename?: 'TimeEntryDay';
  id: Scalars['String']['output'];
  timeEntryWrappers: Array<TimeEntryWrapper>;
  totalEditedTimeInSeconds?: Maybe<Scalars['Int']['output']>;
  totalPaidTimeInSeconds?: Maybe<Scalars['Int']['output']>;
  totalTimeInSeconds?: Maybe<Scalars['Int']['output']>;
};

export type TimeEntryDayRange = {
  __typename?: 'TimeEntryDayRange';
  days: Array<TimeEntryDay>;
  rangeTotalEditedTimeInSeconds: Scalars['Int']['output'];
  rangeTotalPaidTimeInSeconds: Scalars['Int']['output'];
  rangeTotalTimeInSeconds: Scalars['Int']['output'];
  timezone: Scalars['String']['output'];
};

/** Time Entry error response */
export type TimeEntryError = {
  __typename?: 'TimeEntryError';
  reason: TimeEntryErrorReason;
  userId: Scalars['String']['output'];
};

export enum TimeEntryErrorReason {
  AlreadyClockedIn = 'ALREADY_CLOCKED_IN',
  AlreadyClockedOut = 'ALREADY_CLOCKED_OUT',
  AttemptedDoubleBooking = 'ATTEMPTED_DOUBLE_BOOKING',
  EndCannotBeBeforeStart = 'END_CANNOT_BE_BEFORE_START',
  Unknown = 'UNKNOWN',
  UserArchived = 'USER_ARCHIVED'
}

export type TimeEntryEvidence = {
  __typename?: 'TimeEntryEvidence';
  end?: Maybe<EndEvidence>;
  start?: Maybe<StartEvidence>;
};

export type TimeEntryWrapper = {
  __typename?: 'TimeEntryWrapper';
  actors: Array<User>;
  history: Array<TimeEntryChangeset>;
  id: Scalars['String']['output'];
  isBreak: Scalars['Boolean']['output'];
  splitEndAt?: Maybe<Scalars['DateTime']['output']>;
  splitStartAt?: Maybe<Scalars['DateTime']['output']>;
  timeEntry: TimeEntry;
  totalEditedTimeInSeconds: Scalars['Int']['output'];
};

export enum TimeFormat {
  Hours = 'HOURS',
  Minutes = 'MINUTES',
  Pretty = 'PRETTY',
  Seconds = 'SECONDS'
}

export type UnitGoal = {
  __typename?: 'UnitGoal';
  createdAt: Scalars['DateTime']['output'];
  deliverableUnit: DeliverableUnit;
  deliverableUnitId: Scalars['ID']['output'];
  id: Scalars['ID']['output'];
  isPrimary: Scalars['Boolean']['output'];
  organizationId: Scalars['ID']['output'];
  previousProgress: Scalars['Float']['output'];
  progressInDateRange: Scalars['Float']['output'];
  project: Project;
  projectId: Scalars['ID']['output'];
  targetQuantity?: Maybe<Scalars['Float']['output']>;
  task: Task;
  taskId: Scalars['ID']['output'];
  totalProgress: Scalars['Float']['output'];
  unitGoalProgress: Array<UnitGoalProgress>;
  updatedAt: Scalars['DateTime']['output'];
};


export type UnitGoalPreviousProgressArgs = {
  rangeStart: Scalars['DateTime']['input'];
};


export type UnitGoalProgressInDateRangeArgs = {
  rangeEnd: Scalars['DateTime']['input'];
  rangeStart: Scalars['DateTime']['input'];
};

export type UnitGoalInput = {
  deliverableUnitId: Scalars['String']['input'];
  id?: InputMaybe<Scalars['String']['input']>;
  isPrimary: Scalars['Boolean']['input'];
  targetQuantity?: InputMaybe<Scalars['Float']['input']>;
};

export type UnitGoalProgress = {
  __typename?: 'UnitGoalProgress';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  progress: Scalars['Float']['output'];
  unitGoal: UnitGoal;
  unitGoalId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UnitGoalProgressInput = {
  progress: Scalars['Float']['input'];
  unitGoalId: Scalars['String']['input'];
};

export type UnitGoalProgressInputData = {
  __typename?: 'UnitGoalProgressInputData';
  progress: Scalars['Float']['output'];
  unitGoalId: Scalars['String']['output'];
};

export type UnitOfMeasure = {
  __typename?: 'UnitOfMeasure';
  id?: Maybe<Scalars['Int']['output']>;
  name: Scalars['String']['output'];
  organizationId?: Maybe<Scalars['String']['output']>;
  symbol: Scalars['String']['output'];
};

/** Response from update sort order */
export type UpdateSortOrderPayload = {
  __typename?: 'UpdateSortOrderPayload';
  id: Scalars['String']['output'];
  sortOrder: Scalars['Int']['output'];
};

export type User = {
  __typename?: 'User';
  archived: Scalars['Boolean']['output'];
  assetCount: Scalars['Int']['output'];
  assignedDivision: Division;
  assignedProjectIds: Array<Scalars['String']['output']>;
  assignedTaskIds: Array<Scalars['String']['output']>;
  assignments: Array<UserAssignment>;
  billingClassification?: Maybe<UserBillingClassification>;
  billingClassificationId?: Maybe<Scalars['String']['output']>;
  companyUserId?: Maybe<Scalars['String']['output']>;
  currentAssignment: UserAssignment;
  currentProject: Project;
  currentProjectId: Scalars['String']['output'];
  currentTask: Task;
  currentTaskId: Scalars['String']['output'];
  defaultListVisibilityFilter: ListVisibilityFilter;
  deviceSessions: Array<UserDeviceSession>;
  divisionAccess: Array<Division>;
  divisionId?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  emailVerifiedAt?: Maybe<Scalars['DateTime']['output']>;
  firstName: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  imageUrl?: Maybe<Scalars['String']['output']>;
  injuryReports: Array<InjuryReport>;
  invitationPending: Scalars['Boolean']['output'];
  isBlackthornEmployee: Scalars['Boolean']['output'];
  isClockedIn: Scalars['Boolean']['output'];
  /** This field is a convenience helper to encapsulate logic which tells us if a user is on a scheduled break or not.  Someday this might look different but this is adequate today */
  isOnBreak: Scalars['Boolean']['output'];
  jobTitle: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  latestTimeEntry?: Maybe<TimeEntry>;
  myFiles: Array<File>;
  offlineEvents: Array<OfflineEvent>;
  organization: Organization;
  organizationId: Scalars['String']['output'];
  passwordUpdatedAt?: Maybe<Scalars['DateTime']['output']>;
  payRate?: Maybe<Scalars['String']['output']>;
  payType?: Maybe<PayType>;
  phoneNumber: Scalars['String']['output'];
  phoneNumberExt?: Maybe<Scalars['String']['output']>;
  /** @deprecated Use `currentProject` instead */
  project: Project;
  /** @deprecated Use currentProjectId */
  projectId: Scalars['String']['output'];
  reportedUnitsInDateRangeByProjectOrTask: Array<TaskProgressEvent>;
  roles: Array<Role>;
  secondsClockedInDateRangeOnProjectOrTask: Scalars['Int']['output'];
  secondsClockedSinceOrgMidnight: Scalars['Int']['output'];
  secondsClockedThisWeek: Scalars['Int']['output'];
  securityActivity: Array<SecurityActivity>;
  sortName: Scalars['String']['output'];
  /** @deprecated Use `currentTask` instead */
  task: Task;
  /** @deprecated Use currentTaskId */
  taskId?: Maybe<Scalars['String']['output']>;
  timeEntries?: Maybe<Array<TimeEntry>>;
  totalEditedTimeInRange: Scalars['Int']['output'];
  workersCompCode?: Maybe<WorkersCompCode>;
  workersCompCodeId?: Maybe<Scalars['String']['output']>;
};


export type UserOfflineEventsArgs = {
  allUnresolved?: InputMaybe<Scalars['Boolean']['input']>;
  limitToWeeks?: InputMaybe<Scalars['Int']['input']>;
};


export type UserReportedUnitsInDateRangeByProjectOrTaskArgs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
};


export type UserSecondsClockedInDateRangeOnProjectOrTaskArgs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
};


export type UserSecurityActivityArgs = {
  skip?: Scalars['Int']['input'];
  take?: Scalars['Int']['input'];
};


export type UserTimeEntriesArgs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  rangeEnd?: InputMaybe<Scalars['DateTime']['input']>;
  rangeStart?: InputMaybe<Scalars['DateTime']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
};


export type UserTotalEditedTimeInRangeArgs = {
  projectId?: InputMaybe<Scalars['String']['input']>;
  rangeEndOn: Scalars['String']['input'];
  rangeStartOn: Scalars['String']['input'];
  taskId?: InputMaybe<Scalars['String']['input']>;
};

export enum UserAssetRetainmentOption {
  /** Offload assets assigned to the user so the assets will be removed from the user and stay in the existing division. */
  Offload = 'OFFLOAD',
  /** Retain assets assigned to a user so the assets will be in the newly assigned division. */
  Retain = 'RETAIN'
}

export type UserAssignment = {
  __typename?: 'UserAssignment';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isCurrentAssignment?: Maybe<Scalars['Boolean']['output']>;
  organizationId: Scalars['String']['output'];
  project: Project;
  projectId: Scalars['String']['output'];
  sortOrder?: Maybe<Scalars['Int']['output']>;
  task?: Maybe<Task>;
  taskId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  user: User;
  userId: Scalars['String']['output'];
};

export type UserAssignmentAssignment = {
  previousProjectId?: InputMaybe<Scalars['String']['input']>;
  previousTaskId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  taskId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserAssignmentBatchResponse = {
  __typename?: 'UserAssignmentBatchResponse';
  count: Scalars['Int']['output'];
};

export type UserAssignmentCreateInput = {
  /** Unused in the query processing but helpful for cache updating logic */
  previousProjectId?: InputMaybe<Scalars['String']['input']>;
  /** Unused in the query processing but helpful for cache updating logic */
  previousTaskId?: InputMaybe<Scalars['String']['input']>;
  projectId: Scalars['String']['input'];
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserAssignmentUpdateInput = {
  id: Scalars['String']['input'];
  /** Unused in the query processing but helpful for cache updating logic */
  previousProjectId?: InputMaybe<Scalars['String']['input']>;
  /** Unused in the query processing but helpful for cache updating logic */
  previousTaskId?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  sortOrder: Scalars['Int']['input'];
  taskId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type UserBillingClassification = {
  __typename?: 'UserBillingClassification';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
  organizationId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type UserDeviceSession = {
  __typename?: 'UserDeviceSession';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  ip?: Maybe<Scalars['String']['output']>;
  isSingleUse?: Maybe<Scalars['Boolean']['output']>;
  requestInfo: RequestInfo;
  updatedAt: Scalars['DateTime']['output'];
  userAgent?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type UserFilter = {
  appRole?: InputMaybe<Array<Scalars['String']['input']>>;
  archived?: InputMaybe<Scalars['Boolean']['input']>;
  billingClassificationIds?: InputMaybe<Array<Scalars['String']['input']>>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  invitationPending?: InputMaybe<Scalars['Boolean']['input']>;
  isClockedIn?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  projectId?: InputMaybe<Scalars['String']['input']>;
  searchText?: InputMaybe<Scalars['String']['input']>;
  taskId?: InputMaybe<Scalars['String']['input']>;
  userIds?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type UserNotification = {
  __typename?: 'UserNotification';
  asset?: Maybe<Asset>;
  assignedUser: User;
  assignedUserId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  markedReadAt?: Maybe<Scalars['DateTime']['output']>;
  modelType: UserNotificationModelType;
  organizationId: Scalars['String']['output'];
  type: UserNotificationType;
  user?: Maybe<User>;
};

export enum UserNotificationModelType {
  Asset = 'Asset',
  User = 'User'
}

export enum UserNotificationType {
  AssetInspectionFailed = 'ASSET_INSPECTION_FAILED',
  InjuryReportCreated = 'INJURY_REPORT_CREATED',
  UserInvite = 'USER_INVITE'
}

export type UserSort = {
  by?: SortByInput;
  direction?: SortDirectionInput;
};

export type UserUpdateModel = {
  billingClassificationId?: InputMaybe<Scalars['String']['input']>;
  companyUserId?: InputMaybe<Scalars['String']['input']>;
  divisionId?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  image?: InputMaybe<Scalars['String']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  organizationId?: InputMaybe<Scalars['String']['input']>;
  payRate?: InputMaybe<Scalars['String']['input']>;
  payType?: InputMaybe<PayType>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  phoneNumberExt?: InputMaybe<Scalars['String']['input']>;
  roles?: InputMaybe<Array<Scalars['String']['input']>>;
  workersCompCodeId?: InputMaybe<Scalars['String']['input']>;
};

export type Vendor = {
  __typename?: 'Vendor';
  activeProjectCount: Scalars['Int']['output'];
  addressLine1?: Maybe<Scalars['String']['output']>;
  addressLine2?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  contacts: Array<VendorContact>;
  createdAt: Scalars['DateTime']['output'];
  email?: Maybe<Scalars['String']['output']>;
  files: Array<File>;
  id: Scalars['String']['output'];
  /** @deprecated Use imageUrl instead */
  image?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberExt?: Maybe<Scalars['String']['output']>;
  projects: Array<ProjectVendor>;
  state?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
  vendorId?: Maybe<Scalars['String']['output']>;
  zip?: Maybe<Scalars['String']['output']>;
};

export type VendorContact = {
  __typename?: 'VendorContact';
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** @deprecated Use imageUrl instead */
  image?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  phoneNumberExt?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
  vendor: Vendor;
  vendorId: Scalars['String']['output'];
};

export type WorkersCompCode = {
  __typename?: 'WorkersCompCode';
  active: Scalars['Boolean']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  ncciCode: Scalars['String']['output'];
  ncciDescription: Scalars['String']['output'];
  organizationId: Scalars['ID']['output'];
  override: Scalars['Boolean']['output'];
  rate: Scalars['Float']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type Credentials = {
  __typename?: 'credentials';
  accessToken: Scalars['String']['output'];
};

export type WithTypename<T extends { __typename?: any }> = Partial<T> & { __typename: NonNullable<T['__typename']> };

export type GraphCacheKeysConfig = {
  Address?: (data: WithTypename<Address>) => null | string,
  AddressComponent?: (data: WithTypename<AddressComponent>) => null | string,
  Asset?: (data: WithTypename<Asset>) => null | string,
  AssetBillingClassification?: (data: WithTypename<AssetBillingClassification>) => null | string,
  AssetGroup?: (data: WithTypename<AssetGroup>) => null | string,
  AssetInspectionRequirements?: (data: WithTypename<AssetInspectionRequirements>) => null | string,
  AssetInspectionTemplateAssignments?: (data: WithTypename<AssetInspectionTemplateAssignments>) => null | string,
  AssetInventoryRequirements?: (data: WithTypename<AssetInventoryRequirements>) => null | string,
  AssetManufacturer?: (data: WithTypename<AssetManufacturer>) => null | string,
  AssetPurchaseDetails?: (data: WithTypename<AssetPurchaseDetails>) => null | string,
  AssetRentalAgreement?: (data: WithTypename<AssetRentalAgreement>) => null | string,
  AssetRentalAgreementRate?: (data: WithTypename<AssetRentalAgreementRate>) => null | string,
  AssetRepairRequest?: (data: WithTypename<AssetRepairRequest>) => null | string,
  AssetReport?: (data: WithTypename<AssetReport>) => null | string,
  AssetReportInspectionSubmission?: (data: WithTypename<AssetReportInspectionSubmission>) => null | string,
  AssetReportInventoryReport?: (data: WithTypename<AssetReportInventoryReport>) => null | string,
  AssetReportTemplate?: (data: WithTypename<AssetReportTemplate>) => null | string,
  AssetReportTemplateField?: (data: WithTypename<AssetReportTemplateField>) => null | string,
  AssetReportTransferAssignment?: (data: WithTypename<AssetReportTransferAssignment>) => null | string,
  AssetReportTransferReport?: (data: WithTypename<AssetReportTransferReport>) => null | string,
  AssetStatusChange?: (data: WithTypename<AssetStatusChange>) => null | string,
  AssetVendorContact?: (data: WithTypename<AssetVendorContact>) => null | string,
  AssignedAsset?: (data: WithTypename<AssignedAsset>) => null | string,
  BulkTaskCreationResult?: (data: WithTypename<BulkTaskCreationResult>) => null | string,
  BulkTimeEntryResponse?: (data: WithTypename<BulkTimeEntryResponse>) => null | string,
  ClockInData?: (data: WithTypename<ClockInData>) => null | string,
  ClockOutData?: (data: WithTypename<ClockOutData>) => null | string,
  Contract?: (data: WithTypename<Contract>) => null | string,
  ContractAssetBillingClassification?: (data: WithTypename<ContractAssetBillingClassification>) => null | string,
  ContractDeliverableUnit?: (data: WithTypename<ContractDeliverableUnit>) => null | string,
  ContractUserBillingClassification?: (data: WithTypename<ContractUserBillingClassification>) => null | string,
  CreateManyOfflineEventsResponsePayload?: (data: WithTypename<CreateManyOfflineEventsResponsePayload>) => null | string,
  Customer?: (data: WithTypename<Customer>) => null | string,
  DeliverableUnit?: (data: WithTypename<DeliverableUnit>) => null | string,
  DigestDataDetails?: (data: WithTypename<DigestDataDetails>) => null | string,
  Division?: (data: WithTypename<Division>) => null | string,
  DivisionDeliverableUnit?: (data: WithTypename<DivisionDeliverableUnit>) => null | string,
  EditHistoryEvent?: (data: WithTypename<EditHistoryEvent>) => null | string,
  EndEvidence?: (data: WithTypename<EndEvidence>) => null | string,
  File?: (data: WithTypename<File>) => null | string,
  FileUpload?: (data: WithTypename<FileUpload>) => null | string,
  FileUploadResponse?: (data: WithTypename<FileUploadResponse>) => null | string,
  FraudEvidenceData?: (data: WithTypename<FraudEvidenceData>) => null | string,
  GanttProjectDetailsRef?: (data: WithTypename<GanttProjectDetailsRef>) => null | string,
  GanttTaskDependencyRef?: (data: WithTypename<GanttTaskDependencyRef>) => null | string,
  GanttTaskDetailsRef?: (data: WithTypename<GanttTaskDetailsRef>) => null | string,
  GanttTaskGroupDetailsRef?: (data: WithTypename<GanttTaskGroupDetailsRef>) => null | string,
  GeolocationCoordinates?: (data: WithTypename<GeolocationCoordinates>) => null | string,
  GeolocationPosition?: (data: WithTypename<GeolocationPosition>) => null | string,
  InjuryReport?: (data: WithTypename<InjuryReport>) => null | string,
  InjuryReportData?: (data: WithTypename<InjuryReportData>) => null | string,
  InspectionReportAggregations?: (data: WithTypename<InspectionReportAggregations>) => null | string,
  LastExport?: (data: WithTypename<LastExport>) => null | string,
  LocationWithRadius?: (data: WithTypename<LocationWithRadius>) => null | string,
  MetadataNote?: (data: WithTypename<MetadataNote>) => null | string,
  OfflineEvent?: (data: WithTypename<OfflineEvent>) => null | string,
  Organization?: (data: WithTypename<Organization>) => null | string,
  OrganizationAddress?: (data: WithTypename<OrganizationAddress>) => null | string,
  PageInfo?: (data: WithTypename<PageInfo>) => null | string,
  PayrollExportRow?: (data: WithTypename<PayrollExportRow>) => null | string,
  ProductionRateData?: (data: WithTypename<ProductionRateData>) => null | string,
  Project?: (data: WithTypename<Project>) => null | string,
  ProjectProgressSummary?: (data: WithTypename<ProjectProgressSummary>) => null | string,
  ProjectVendor?: (data: WithTypename<ProjectVendor>) => null | string,
  ProjectVendorInputData?: (data: WithTypename<ProjectVendorInputData>) => null | string,
  QueryActiveProjectsPaginatedConnection?: (data: WithTypename<QueryActiveProjectsPaginatedConnection>) => null | string,
  QueryActiveProjectsPaginatedConnectionEdge?: (data: WithTypename<QueryActiveProjectsPaginatedConnectionEdge>) => null | string,
  QueryArchivedAssetsConnection?: (data: WithTypename<QueryArchivedAssetsConnection>) => null | string,
  QueryArchivedAssetsConnectionEdge?: (data: WithTypename<QueryArchivedAssetsConnectionEdge>) => null | string,
  QueryAssets_2Connection?: (data: WithTypename<QueryAssets_2Connection>) => null | string,
  QueryAssets_2ConnectionEdge?: (data: WithTypename<QueryAssets_2ConnectionEdge>) => null | string,
  QueryProjectsPaginatedConnection?: (data: WithTypename<QueryProjectsPaginatedConnection>) => null | string,
  QueryProjectsPaginatedConnectionEdge?: (data: WithTypename<QueryProjectsPaginatedConnectionEdge>) => null | string,
  QueryTasksPaginatedConnection?: (data: WithTypename<QueryTasksPaginatedConnection>) => null | string,
  QueryTasksPaginatedConnectionEdge?: (data: WithTypename<QueryTasksPaginatedConnectionEdge>) => null | string,
  QueryUsersConnection?: (data: WithTypename<QueryUsersConnection>) => null | string,
  QueryUsersConnectionEdge?: (data: WithTypename<QueryUsersConnectionEdge>) => null | string,
  ReassignmentData?: (data: WithTypename<ReassignmentData>) => null | string,
  RequestInfo?: (data: WithTypename<RequestInfo>) => null | string,
  Role?: (data: WithTypename<Role>) => null | string,
  Schedule?: (data: WithTypename<Schedule>) => null | string,
  ScheduleNonWorkDays?: (data: WithTypename<ScheduleNonWorkDays>) => null | string,
  ScheduleWorkDay?: (data: WithTypename<ScheduleWorkDay>) => null | string,
  ScheduleWorkHours?: (data: WithTypename<ScheduleWorkHours>) => null | string,
  ScheduledBreak?: (data: WithTypename<ScheduledBreak>) => null | string,
  SecurityActivity?: (data: WithTypename<SecurityActivity>) => null | string,
  Settings?: (data: WithTypename<Settings>) => null | string,
  StartEvidence?: (data: WithTypename<StartEvidence>) => null | string,
  StripeInvoice?: (data: WithTypename<StripeInvoice>) => null | string,
  Task?: (data: WithTypename<Task>) => null | string,
  TaskDependency?: (data: WithTypename<TaskDependency>) => null | string,
  TaskDependencyData?: (data: WithTypename<TaskDependencyData>) => null | string,
  TaskGroup?: (data: WithTypename<TaskGroup>) => null | string,
  TaskListItem?: (data: WithTypename<TaskListItem>) => null | string,
  TaskProgressEvent?: (data: WithTypename<TaskProgressEvent>) => null | string,
  TaskProgressEventData?: (data: WithTypename<TaskProgressEventData>) => null | string,
  TimeCardWeek?: (data: WithTypename<TimeCardWeek>) => null | string,
  TimeEntry?: (data: WithTypename<TimeEntry>) => null | string,
  TimeEntryChangeset?: (data: WithTypename<TimeEntryChangeset>) => null | string,
  TimeEntryChangesetChanges?: (data: WithTypename<TimeEntryChangesetChanges>) => null | string,
  TimeEntryDay?: (data: WithTypename<TimeEntryDay>) => null | string,
  TimeEntryDayRange?: (data: WithTypename<TimeEntryDayRange>) => null | string,
  TimeEntryError?: (data: WithTypename<TimeEntryError>) => null | string,
  TimeEntryEvidence?: (data: WithTypename<TimeEntryEvidence>) => null | string,
  TimeEntryWrapper?: (data: WithTypename<TimeEntryWrapper>) => null | string,
  UnitGoal?: (data: WithTypename<UnitGoal>) => null | string,
  UnitGoalProgress?: (data: WithTypename<UnitGoalProgress>) => null | string,
  UnitGoalProgressInputData?: (data: WithTypename<UnitGoalProgressInputData>) => null | string,
  UnitOfMeasure?: (data: WithTypename<UnitOfMeasure>) => null | string,
  UpdateSortOrderPayload?: (data: WithTypename<UpdateSortOrderPayload>) => null | string,
  User?: (data: WithTypename<User>) => null | string,
  UserAssignment?: (data: WithTypename<UserAssignment>) => null | string,
  UserAssignmentBatchResponse?: (data: WithTypename<UserAssignmentBatchResponse>) => null | string,
  UserBillingClassification?: (data: WithTypename<UserBillingClassification>) => null | string,
  UserDeviceSession?: (data: WithTypename<UserDeviceSession>) => null | string,
  UserNotification?: (data: WithTypename<UserNotification>) => null | string,
  Vendor?: (data: WithTypename<Vendor>) => null | string,
  VendorContact?: (data: WithTypename<VendorContact>) => null | string,
  WorkersCompCode?: (data: WithTypename<WorkersCompCode>) => null | string,
  credentials?: (data: WithTypename<Credentials>) => null | string
}

export type GraphCacheResolvers = {
  Query?: {
    activeProjectCount?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Scalars['Int'] | string>,
    activeProjects?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Project> | string>>,
    activeProjectsPaginated?: GraphCacheResolver<WithTypename<Query>, QueryActiveProjectsPaginatedArgs, WithTypename<QueryActiveProjectsPaginatedConnection> | string>,
    archivedAssetGroups?: GraphCacheResolver<WithTypename<Query>, QueryArchivedAssetGroupsArgs, Array<WithTypename<AssetGroup> | string>>,
    archivedAssets?: GraphCacheResolver<WithTypename<Query>, QueryArchivedAssetsArgs, WithTypename<QueryArchivedAssetsConnection> | string>,
    asset?: GraphCacheResolver<WithTypename<Query>, QueryAssetArgs, WithTypename<Asset> | string>,
    assetBillingClassification?: GraphCacheResolver<WithTypename<Query>, QueryAssetBillingClassificationArgs, WithTypename<AssetBillingClassification> | string>,
    assetBillingClassifications?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<AssetBillingClassification> | string>>,
    assetGroups?: GraphCacheResolver<WithTypename<Query>, QueryAssetGroupsArgs, Array<WithTypename<AssetGroup> | string>>,
    assetRepairRequest?: GraphCacheResolver<WithTypename<Query>, QueryAssetRepairRequestArgs, WithTypename<AssetRepairRequest> | string>,
    assetRepairRequests?: GraphCacheResolver<WithTypename<Query>, QueryAssetRepairRequestsArgs, Array<WithTypename<AssetRepairRequest> | string>>,
    assetReport?: GraphCacheResolver<WithTypename<Query>, QueryAssetReportArgs, WithTypename<AssetReport> | string>,
    assetReportTemplate?: GraphCacheResolver<WithTypename<Query>, QueryAssetReportTemplateArgs, WithTypename<AssetReportTemplate> | string>,
    assetReports?: GraphCacheResolver<WithTypename<Query>, QueryAssetReportsArgs, Array<WithTypename<AssetReport> | string>>,
    assets?: GraphCacheResolver<WithTypename<Query>, QueryAssetsArgs, Array<WithTypename<Asset> | string>>,
    assets_2?: GraphCacheResolver<WithTypename<Query>, QueryAssets_2Args, WithTypename<QueryAssets_2Connection> | string>,
    assignedAssets?: GraphCacheResolver<WithTypename<Query>, QueryAssignedAssetsArgs, Array<WithTypename<AssignedAsset> | string>>,
    contract?: GraphCacheResolver<WithTypename<Query>, QueryContractArgs, WithTypename<Contract> | string>,
    contracts?: GraphCacheResolver<WithTypename<Query>, QueryContractsArgs, Array<WithTypename<Contract> | string>>,
    currentUser?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<User> | string>,
    customer?: GraphCacheResolver<WithTypename<Query>, QueryCustomerArgs, WithTypename<Customer> | string>,
    customers?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Customer> | string>>,
    deliverableUnit?: GraphCacheResolver<WithTypename<Query>, QueryDeliverableUnitArgs, WithTypename<DeliverableUnit> | string>,
    deliverableUnits?: GraphCacheResolver<WithTypename<Query>, QueryDeliverableUnitsArgs, Array<WithTypename<DeliverableUnit> | string>>,
    dependentTaskDependencies?: GraphCacheResolver<WithTypename<Query>, QueryDependentTaskDependenciesArgs, Array<WithTypename<TaskDependency> | string>>,
    dependentTasks?: GraphCacheResolver<WithTypename<Query>, QueryDependentTasksArgs, Array<WithTypename<Task> | string>>,
    division?: GraphCacheResolver<WithTypename<Query>, QueryDivisionArgs, WithTypename<Division> | string>,
    divisions?: GraphCacheResolver<WithTypename<Query>, QueryDivisionsArgs, Array<WithTypename<Division> | string>>,
    editHistoryEvent?: GraphCacheResolver<WithTypename<Query>, QueryEditHistoryEventArgs, WithTypename<EditHistoryEvent> | string>,
    editHistoryEvents?: GraphCacheResolver<WithTypename<Query>, QueryEditHistoryEventsArgs, Array<WithTypename<EditHistoryEvent> | string>>,
    getJobTitles?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<Scalars['String'] | string>>,
    myDivision?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<Division> | string>,
    myDivisions?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Division> | string>>,
    myNotifications?: GraphCacheResolver<WithTypename<Query>, QueryMyNotificationsArgs, Array<WithTypename<UserNotification> | string>>,
    myOrganization?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<Organization> | string>,
    nestedAssets?: GraphCacheResolver<WithTypename<Query>, QueryNestedAssetsArgs, Array<WithTypename<Asset> | string>>,
    nestedAssetsForInventory?: GraphCacheResolver<WithTypename<Query>, QueryNestedAssetsForInventoryArgs, Array<WithTypename<AssignedAsset> | string>>,
    offlineEvent?: GraphCacheResolver<WithTypename<Query>, QueryOfflineEventArgs, WithTypename<OfflineEvent> | string>,
    offlineEvents?: GraphCacheResolver<WithTypename<Query>, QueryOfflineEventsArgs, Array<WithTypename<OfflineEvent> | string>>,
    organization?: GraphCacheResolver<WithTypename<Query>, QueryOrganizationArgs, WithTypename<Organization> | string>,
    organizations?: GraphCacheResolver<WithTypename<Query>, QueryOrganizationsArgs, Array<WithTypename<Organization> | string>>,
    payrollExportRows?: GraphCacheResolver<WithTypename<Query>, QueryPayrollExportRowsArgs, Array<WithTypename<PayrollExportRow> | string>>,
    predecessorTaskDependencies?: GraphCacheResolver<WithTypename<Query>, QueryPredecessorTaskDependenciesArgs, Array<WithTypename<TaskDependency> | string>>,
    predecessorTasks?: GraphCacheResolver<WithTypename<Query>, QueryPredecessorTasksArgs, Array<WithTypename<Task> | string>>,
    project?: GraphCacheResolver<WithTypename<Query>, QueryProjectArgs, WithTypename<Project> | string>,
    projects?: GraphCacheResolver<WithTypename<Query>, QueryProjectsArgs, Array<WithTypename<Project> | string>>,
    projectsByStatus?: GraphCacheResolver<WithTypename<Query>, QueryProjectsByStatusArgs, Array<WithTypename<Project> | string>>,
    projectsPaginated?: GraphCacheResolver<WithTypename<Query>, QueryProjectsPaginatedArgs, WithTypename<QueryProjectsPaginatedConnection> | string>,
    reusableAssetReportTemplates?: GraphCacheResolver<WithTypename<Query>, QueryReusableAssetReportTemplatesArgs, Array<WithTypename<AssetReportTemplate> | string>>,
    roles?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Role> | string>>,
    scheduledBreaks?: GraphCacheResolver<WithTypename<Query>, QueryScheduledBreaksArgs, Array<WithTypename<ScheduledBreak> | string>>,
    schedules?: GraphCacheResolver<WithTypename<Query>, QuerySchedulesArgs, Array<WithTypename<Schedule> | string>>,
    settings?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, WithTypename<Settings> | string>,
    task?: GraphCacheResolver<WithTypename<Query>, QueryTaskArgs, WithTypename<Task> | string>,
    taskDependency?: GraphCacheResolver<WithTypename<Query>, QueryTaskDependencyArgs, WithTypename<TaskDependency> | string>,
    taskGroup?: GraphCacheResolver<WithTypename<Query>, QueryTaskGroupArgs, WithTypename<TaskGroup> | string>,
    taskGroups?: GraphCacheResolver<WithTypename<Query>, QueryTaskGroupsArgs, Array<WithTypename<TaskGroup> | string>>,
    taskList?: GraphCacheResolver<WithTypename<Query>, QueryTaskListArgs, Array<WithTypename<TaskListItem> | string>>,
    taskProgressEvent?: GraphCacheResolver<WithTypename<Query>, QueryTaskProgressEventArgs, WithTypename<TaskProgressEvent> | string>,
    taskProgressEvents?: GraphCacheResolver<WithTypename<Query>, QueryTaskProgressEventsArgs, Array<WithTypename<TaskProgressEvent> | string>>,
    tasks?: GraphCacheResolver<WithTypename<Query>, QueryTasksArgs, Array<WithTypename<Task> | string>>,
    tasksForGroupId?: GraphCacheResolver<WithTypename<Query>, QueryTasksForGroupIdArgs, Array<WithTypename<Task> | string>>,
    tasksPaginated?: GraphCacheResolver<WithTypename<Query>, QueryTasksPaginatedArgs, WithTypename<QueryTasksPaginatedConnection> | string>,
    timeCardWeek?: GraphCacheResolver<WithTypename<Query>, QueryTimeCardWeekArgs, WithTypename<TimeCardWeek> | string>,
    timeEntryDay?: GraphCacheResolver<WithTypename<Query>, QueryTimeEntryDayArgs, WithTypename<TimeEntryDay> | string>,
    timeEntryDays?: GraphCacheResolver<WithTypename<Query>, QueryTimeEntryDaysArgs, WithTypename<TimeEntryDayRange> | string>,
    unitGoal?: GraphCacheResolver<WithTypename<Query>, QueryUnitGoalArgs, WithTypename<UnitGoal> | string>,
    unitGoals?: GraphCacheResolver<WithTypename<Query>, QueryUnitGoalsArgs, Array<WithTypename<UnitGoal> | string>>,
    user?: GraphCacheResolver<WithTypename<Query>, QueryUserArgs, WithTypename<User> | string>,
    userAssignments?: GraphCacheResolver<WithTypename<Query>, QueryUserAssignmentsArgs, Array<WithTypename<UserAssignment> | string>>,
    userBillingClassification?: GraphCacheResolver<WithTypename<Query>, QueryUserBillingClassificationArgs, WithTypename<UserBillingClassification> | string>,
    userBillingClassifications?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<UserBillingClassification> | string>>,
    users?: GraphCacheResolver<WithTypename<Query>, QueryUsersArgs, WithTypename<QueryUsersConnection> | string>,
    usersForSummarySection?: GraphCacheResolver<WithTypename<Query>, QueryUsersForSummarySectionArgs, Array<WithTypename<User> | string>>,
    usersList?: GraphCacheResolver<WithTypename<Query>, QueryUsersListArgs, Array<WithTypename<User> | string>>,
    vendor?: GraphCacheResolver<WithTypename<Query>, QueryVendorArgs, WithTypename<Vendor> | string>,
    vendorContact?: GraphCacheResolver<WithTypename<Query>, QueryVendorContactArgs, WithTypename<VendorContact> | string>,
    vendorContacts?: GraphCacheResolver<WithTypename<Query>, QueryVendorContactsArgs, Array<WithTypename<VendorContact> | string>>,
    vendors?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<Vendor> | string>>,
    workersCompCodes?: GraphCacheResolver<WithTypename<Query>, Record<string, never>, Array<WithTypename<WorkersCompCode> | string>>
  },
  Address?: {
    addressComponents?: GraphCacheResolver<WithTypename<Address>, Record<string, never>, Array<WithTypename<AddressComponent> | string>>,
    formattedAddress?: GraphCacheResolver<WithTypename<Address>, Record<string, never>, Scalars['String'] | string>
  },
  AddressComponent?: {
    longName?: GraphCacheResolver<WithTypename<AddressComponent>, Record<string, never>, Scalars['String'] | string>,
    shortName?: GraphCacheResolver<WithTypename<AddressComponent>, Record<string, never>, Scalars['String'] | string>,
    types?: GraphCacheResolver<WithTypename<AddressComponent>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  Asset?: {
    active?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['Boolean'] | string>,
    activeChangedAt?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['DateTime'] | string>,
    assetChildCount?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['Int'] | string>,
    assetGroupId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    assetGroupMemberCount?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['Int'] | string>,
    assignableId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    assignableType?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, AssetAssignableType | string>,
    assignedAsset?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<Asset> | string>,
    assignedAssetId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    assignedTask?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<Task> | string>,
    assignedTaskId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    assignedUser?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<User> | string>,
    assignedUserId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    assignmentsCount?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['Int'] | string>,
    billingClassification?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<AssetBillingClassification> | string>,
    billingClassificationId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    category?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, AssetCategory | string>,
    childAssetGroups?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Array<WithTypename<AssetGroup> | string>>,
    childAssets?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Array<WithTypename<Asset> | string>>,
    companyAssetNumber?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    compositeKey?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['DateTime'] | string>,
    deletedAt?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['DateTime'] | string>,
    description?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    division?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<Division> | string>,
    divisionId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    files?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Array<WithTypename<File> | string>>,
    groupQuantity?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    inferredOwner?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<User> | string>,
    inferredProjectId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    inspectionTemplatesAssignments?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Array<WithTypename<AssetInspectionTemplateAssignments> | string>>,
    inventoryRequirements?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<AssetInventoryRequirements> | string>,
    isArchived?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['Boolean'] | string>,
    isAssetGroup?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['Boolean'] | string>,
    lastInventoriedAt?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['DateTime'] | string>,
    manufacturer?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<AssetManufacturer> | string>,
    name?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    organization?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<Organization> | string>,
    organizationId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    ownershipType?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    photo?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<File> | string>,
    photoId?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['String'] | string>,
    purchaseDetails?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<AssetPurchaseDetails> | string>,
    rentalAgreement?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<AssetRentalAgreement> | string>,
    repairRequestCount?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['Int'] | string>,
    reportCount?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['Int'] | string>,
    reportTemplates?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Array<WithTypename<AssetReportTemplate> | string>>,
    reports?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Array<WithTypename<AssetReport> | string>>,
    status?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, AssetStatus | string>,
    updatedAt?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, Scalars['DateTime'] | string>,
    vendorContact?: GraphCacheResolver<WithTypename<Asset>, Record<string, never>, WithTypename<AssetVendorContact> | string>
  },
  AssetBillingClassification?: {
    createdAt?: GraphCacheResolver<WithTypename<AssetBillingClassification>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<AssetBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<AssetBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<AssetBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<AssetBillingClassification>, Record<string, never>, Scalars['DateTime'] | string>
  },
  AssetGroup?: {
    assetGroupId?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars['String'] | string>,
    assignableId?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars['String'] | string>,
    assignableType?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, AssetAssignableType | string>,
    assignedAsset?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, WithTypename<Asset> | string>,
    assignedAssetId?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars['String'] | string>,
    assignedTask?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, WithTypename<Task> | string>,
    assignedTaskId?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars['String'] | string>,
    assignedUser?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, WithTypename<User> | string>,
    assignedUserId?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars['String'] | string>,
    compositeKey?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars['String'] | string>,
    count?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, Scalars['Int'] | string>,
    groupParent?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, WithTypename<Asset> | string>,
    status?: GraphCacheResolver<WithTypename<AssetGroup>, Record<string, never>, AssetStatus | string>
  },
  AssetInspectionRequirements?: {
    intervalInSeconds?: GraphCacheResolver<WithTypename<AssetInspectionRequirements>, Record<string, never>, Scalars['Int'] | string>,
    startDate?: GraphCacheResolver<WithTypename<AssetInspectionRequirements>, Record<string, never>, Scalars['DateTime'] | string>
  },
  AssetInspectionTemplateAssignments?: {
    asset?: GraphCacheResolver<WithTypename<AssetInspectionTemplateAssignments>, Record<string, never>, WithTypename<Asset> | string>,
    assetId?: GraphCacheResolver<WithTypename<AssetInspectionTemplateAssignments>, Record<string, never>, Scalars['String'] | string>,
    assetReportTemplateId?: GraphCacheResolver<WithTypename<AssetInspectionTemplateAssignments>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<AssetInspectionTemplateAssignments>, Record<string, never>, Scalars['ID'] | string>,
    inspectionTemplate?: GraphCacheResolver<WithTypename<AssetInspectionTemplateAssignments>, Record<string, never>, WithTypename<AssetReportTemplate> | string>,
    intervalInSeconds?: GraphCacheResolver<WithTypename<AssetInspectionTemplateAssignments>, Record<string, never>, Scalars['Int'] | string>,
    intervalRequired?: GraphCacheResolver<WithTypename<AssetInspectionTemplateAssignments>, Record<string, never>, Scalars['Boolean'] | string>,
    lastReportedAt?: GraphCacheResolver<WithTypename<AssetInspectionTemplateAssignments>, Record<string, never>, Scalars['DateTime'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<AssetInspectionTemplateAssignments>, Record<string, never>, Scalars['String'] | string>,
    startDate?: GraphCacheResolver<WithTypename<AssetInspectionTemplateAssignments>, Record<string, never>, Scalars['DateTime'] | string>
  },
  AssetInventoryRequirements?: {
    intervalInSeconds?: GraphCacheResolver<WithTypename<AssetInventoryRequirements>, Record<string, never>, Scalars['Int'] | string>,
    photoRequired?: GraphCacheResolver<WithTypename<AssetInventoryRequirements>, Record<string, never>, Scalars['Boolean'] | string>
  },
  AssetManufacturer?: {
    id?: GraphCacheResolver<WithTypename<AssetManufacturer>, Record<string, never>, Scalars['String'] | string>,
    make?: GraphCacheResolver<WithTypename<AssetManufacturer>, Record<string, never>, Scalars['String'] | string>,
    model?: GraphCacheResolver<WithTypename<AssetManufacturer>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<AssetManufacturer>, Record<string, never>, Scalars['String'] | string>,
    year?: GraphCacheResolver<WithTypename<AssetManufacturer>, Record<string, never>, Scalars['Int'] | string>
  },
  AssetPurchaseDetails?: {
    date?: GraphCacheResolver<WithTypename<AssetPurchaseDetails>, Record<string, never>, Scalars['String'] | string>,
    price?: GraphCacheResolver<WithTypename<AssetPurchaseDetails>, Record<string, never>, Scalars['Float'] | string>
  },
  AssetRentalAgreement?: {
    endOn?: GraphCacheResolver<WithTypename<AssetRentalAgreement>, Record<string, never>, Scalars['String'] | string>,
    rate?: GraphCacheResolver<WithTypename<AssetRentalAgreement>, Record<string, never>, WithTypename<AssetRentalAgreementRate> | string>,
    startOn?: GraphCacheResolver<WithTypename<AssetRentalAgreement>, Record<string, never>, Scalars['String'] | string>
  },
  AssetRentalAgreementRate?: {
    daily?: GraphCacheResolver<WithTypename<AssetRentalAgreementRate>, Record<string, never>, Scalars['Float'] | string>,
    monthly?: GraphCacheResolver<WithTypename<AssetRentalAgreementRate>, Record<string, never>, Scalars['Float'] | string>,
    weekly?: GraphCacheResolver<WithTypename<AssetRentalAgreementRate>, Record<string, never>, Scalars['Float'] | string>
  },
  AssetRepairRequest?: {
    asset?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, WithTypename<Asset> | string>,
    assetId?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, Scalars['String'] | string>,
    assetReport?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, WithTypename<AssetReport> | string>,
    createdAt?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, Scalars['String'] | string>,
    inspectionFieldId?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, Scalars['String'] | string>,
    organization?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, WithTypename<Organization> | string>,
    organizationId?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, Scalars['String'] | string>,
    reporter?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, WithTypename<User> | string>,
    reporterId?: GraphCacheResolver<WithTypename<AssetRepairRequest>, Record<string, never>, Scalars['String'] | string>
  },
  AssetReport?: {
    asset?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, WithTypename<Asset> | string>,
    assetId?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars['String'] | string>,
    inspectionReport?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Array<WithTypename<AssetReportInspectionSubmission> | string>>,
    inspectionReportAggregations?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, WithTypename<InspectionReportAggregations> | string>,
    inventoryReport?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, WithTypename<AssetReportInventoryReport> | string>,
    notes?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars['String'] | string>,
    organization?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, WithTypename<Organization> | string>,
    organizationId?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars['String'] | string>,
    quantityReported?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars['Int'] | string>,
    reporter?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, WithTypename<User> | string>,
    reporterId?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars['String'] | string>,
    statusChange?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, WithTypename<AssetStatusChange> | string>,
    transferReport?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, WithTypename<AssetReportTransferReport> | string>,
    type?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, AssetReportType | string>,
    updatedAt?: GraphCacheResolver<WithTypename<AssetReport>, Record<string, never>, Scalars['DateTime'] | string>
  },
  AssetReportInspectionSubmission?: {
    failedStatus?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Scalars['String'] | string>,
    fileIds?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Array<Scalars['String'] | string>>,
    id?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Scalars['String'] | string>,
    input?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Scalars['String'] | string>,
    photoLabel?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Scalars['String'] | string>,
    photoRequired?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Scalars['Boolean'] | string>,
    photoUrls?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Array<Scalars['String'] | string>>,
    required?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Scalars['Boolean'] | string>,
    rule?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<AssetReportInspectionSubmission>, Record<string, never>, Scalars['String'] | string>
  },
  AssetReportInventoryReport?: {
    fileIds?: GraphCacheResolver<WithTypename<AssetReportInventoryReport>, Record<string, never>, Array<Scalars['String'] | string>>,
    note?: GraphCacheResolver<WithTypename<AssetReportInventoryReport>, Record<string, never>, Scalars['String'] | string>,
    photoUrls?: GraphCacheResolver<WithTypename<AssetReportInventoryReport>, Record<string, never>, Array<Scalars['String'] | string>>
  },
  AssetReportTemplate?: {
    active?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars['Boolean'] | string>,
    assets?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Array<WithTypename<Asset> | string>>,
    assetsCount?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars['Int'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars['DateTime'] | string>,
    deletedAt?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars['DateTime'] | string>,
    fields?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Array<WithTypename<AssetReportTemplateField> | string>>,
    id?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars['String'] | string>,
    organization?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, WithTypename<Organization> | string>,
    organizationId?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars['String'] | string>,
    reusable?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars['Boolean'] | string>,
    universal?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars['Boolean'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<AssetReportTemplate>, Record<string, never>, Scalars['DateTime'] | string>
  },
  AssetReportTemplateField?: {
    failedStatus?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars['String'] | string>,
    label?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars['String'] | string>,
    photoLabel?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars['String'] | string>,
    photoRequired?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars['Boolean'] | string>,
    required?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars['Boolean'] | string>,
    rule?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<AssetReportTemplateField>, Record<string, never>, Scalars['String'] | string>
  },
  AssetReportTransferAssignment?: {
    asset?: GraphCacheResolver<WithTypename<AssetReportTransferAssignment>, Record<string, never>, WithTypename<Asset> | string>,
    assignableId?: GraphCacheResolver<WithTypename<AssetReportTransferAssignment>, Record<string, never>, Scalars['String'] | string>,
    assignableType?: GraphCacheResolver<WithTypename<AssetReportTransferAssignment>, Record<string, never>, AssetAssignableType | string>,
    task?: GraphCacheResolver<WithTypename<AssetReportTransferAssignment>, Record<string, never>, WithTypename<Task> | string>,
    user?: GraphCacheResolver<WithTypename<AssetReportTransferAssignment>, Record<string, never>, WithTypename<User> | string>
  },
  AssetReportTransferReport?: {
    from?: GraphCacheResolver<WithTypename<AssetReportTransferReport>, Record<string, never>, WithTypename<AssetReportTransferAssignment> | string>,
    to?: GraphCacheResolver<WithTypename<AssetReportTransferReport>, Record<string, never>, WithTypename<AssetReportTransferAssignment> | string>
  },
  AssetStatusChange?: {
    active?: GraphCacheResolver<WithTypename<AssetStatusChange>, Record<string, never>, Scalars['Boolean'] | string>,
    missingCount?: GraphCacheResolver<WithTypename<AssetStatusChange>, Record<string, never>, Scalars['Int'] | string>,
    status?: GraphCacheResolver<WithTypename<AssetStatusChange>, Record<string, never>, AssetStatus | string>
  },
  AssetVendorContact?: {
    email?: GraphCacheResolver<WithTypename<AssetVendorContact>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<AssetVendorContact>, Record<string, never>, Scalars['String'] | string>,
    phone?: GraphCacheResolver<WithTypename<AssetVendorContact>, Record<string, never>, Scalars['String'] | string>
  },
  AssignedAsset?: {
    active?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['Boolean'] | string>,
    assetChildCount?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['Int'] | string>,
    assetGroupId?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    assignableId?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    assignableType?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, AssetAssignableType | string>,
    assignedAsset?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, WithTypename<Asset> | string>,
    assignedAssetId?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    assignedTask?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, WithTypename<Task> | string>,
    assignedTaskId?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    assignedUser?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, WithTypename<User> | string>,
    assignedUserId?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    assignmentsCount?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['Int'] | string>,
    category?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, AssetCategory | string>,
    companyAssetNumber?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    compositeKey?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    deletedAt?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['DateTime'] | string>,
    depth?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['Int'] | string>,
    groupParentAsset?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, WithTypename<Asset> | string>,
    id?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    inferredOwner?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, WithTypename<User> | string>,
    inventoryRequirements?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, WithTypename<AssetInventoryRequirements> | string>,
    isAssetGroup?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['Boolean'] | string>,
    lastInventoriedAt?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['DateTime'] | string>,
    name?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    ownershipType?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Scalars['String'] | string>,
    quantizedAssetIds?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, Array<Scalars['String'] | string>>,
    status?: GraphCacheResolver<WithTypename<AssignedAsset>, Record<string, never>, AssetStatus | string>
  },
  BulkTaskCreationResult?: {
    createdSubTasksCount?: GraphCacheResolver<WithTypename<BulkTaskCreationResult>, Record<string, never>, Scalars['Int'] | string>,
    createdTaskGroupsCount?: GraphCacheResolver<WithTypename<BulkTaskCreationResult>, Record<string, never>, Scalars['Int'] | string>,
    createdTasksCount?: GraphCacheResolver<WithTypename<BulkTaskCreationResult>, Record<string, never>, Scalars['Int'] | string>,
    createdUnitGoalsCount?: GraphCacheResolver<WithTypename<BulkTaskCreationResult>, Record<string, never>, Scalars['Int'] | string>
  },
  BulkTimeEntryResponse?: {
    errors?: GraphCacheResolver<WithTypename<BulkTimeEntryResponse>, Record<string, never>, Array<WithTypename<TimeEntryError> | string>>,
    success?: GraphCacheResolver<WithTypename<BulkTimeEntryResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  ClockInData?: {
    fileUploadResponses?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, Array<WithTypename<FileUploadResponse> | string>>,
    imagePath?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, Scalars['String'] | string>,
    projectId?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, Scalars['String'] | string>,
    task?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, Scalars['String'] | string>,
    user?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, WithTypename<User> | string>,
    userId?: GraphCacheResolver<WithTypename<ClockInData>, Record<string, never>, Scalars['String'] | string>
  },
  ClockOutData?: {
    fileUploadResponses?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Array<WithTypename<FileUploadResponse> | string>>,
    imagePath?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars['String'] | string>,
    injured?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars['Boolean'] | string>,
    injuryNotes?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars['String'] | string>,
    injuryReportId?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars['String'] | string>,
    projectId?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars['String'] | string>,
    signaturePath?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars['String'] | string>,
    task?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars['String'] | string>,
    user?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, WithTypename<User> | string>,
    userId?: GraphCacheResolver<WithTypename<ClockOutData>, Record<string, never>, Scalars['String'] | string>
  },
  Contract?: {
    assetBillingClassifications?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Array<WithTypename<ContractAssetBillingClassification> | string>>,
    createdAt?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars['DateTime'] | string>,
    customerId?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars['String'] | string>,
    deliverableUnits?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Array<WithTypename<ContractDeliverableUnit> | string>>,
    description?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars['String'] | string>,
    number?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars['String'] | string>,
    organization?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, WithTypename<Organization> | string>,
    organizationId?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Scalars['DateTime'] | string>,
    userBillingClassifications?: GraphCacheResolver<WithTypename<Contract>, Record<string, never>, Array<WithTypename<ContractUserBillingClassification> | string>>
  },
  ContractAssetBillingClassification?: {
    assetBillingClassification?: GraphCacheResolver<WithTypename<ContractAssetBillingClassification>, Record<string, never>, WithTypename<AssetBillingClassification> | string>,
    assetBillingClassificationId?: GraphCacheResolver<WithTypename<ContractAssetBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    contract?: GraphCacheResolver<WithTypename<ContractAssetBillingClassification>, Record<string, never>, WithTypename<Contract> | string>,
    contractId?: GraphCacheResolver<WithTypename<ContractAssetBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<ContractAssetBillingClassification>, Record<string, never>, Scalars['DateTime'] | string>,
    customerDescription?: GraphCacheResolver<WithTypename<ContractAssetBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<ContractAssetBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    rate?: GraphCacheResolver<WithTypename<ContractAssetBillingClassification>, Record<string, never>, Scalars['Float'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<ContractAssetBillingClassification>, Record<string, never>, Scalars['DateTime'] | string>
  },
  ContractDeliverableUnit?: {
    contract?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, WithTypename<Contract> | string>,
    contractId?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, Scalars['DateTime'] | string>,
    customerDescription?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, Scalars['String'] | string>,
    deliverableUnit?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, WithTypename<DeliverableUnit> | string>,
    deliverableUnitId?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, Scalars['String'] | string>,
    rate?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, Scalars['Float'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<ContractDeliverableUnit>, Record<string, never>, Scalars['DateTime'] | string>
  },
  ContractUserBillingClassification?: {
    contract?: GraphCacheResolver<WithTypename<ContractUserBillingClassification>, Record<string, never>, WithTypename<Contract> | string>,
    contractId?: GraphCacheResolver<WithTypename<ContractUserBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<ContractUserBillingClassification>, Record<string, never>, Scalars['DateTime'] | string>,
    customerDescription?: GraphCacheResolver<WithTypename<ContractUserBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<ContractUserBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    rate?: GraphCacheResolver<WithTypename<ContractUserBillingClassification>, Record<string, never>, Scalars['Float'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<ContractUserBillingClassification>, Record<string, never>, Scalars['DateTime'] | string>,
    userBillingClassification?: GraphCacheResolver<WithTypename<ContractUserBillingClassification>, Record<string, never>, WithTypename<UserBillingClassification> | string>,
    userBillingClassificationId?: GraphCacheResolver<WithTypename<ContractUserBillingClassification>, Record<string, never>, Scalars['String'] | string>
  },
  CreateManyOfflineEventsResponsePayload?: {
    createdEvents?: GraphCacheResolver<WithTypename<CreateManyOfflineEventsResponsePayload>, Record<string, never>, Array<WithTypename<OfflineEvent> | string>>,
    failureCount?: GraphCacheResolver<WithTypename<CreateManyOfflineEventsResponsePayload>, Record<string, never>, Scalars['Int'] | string>,
    successCount?: GraphCacheResolver<WithTypename<CreateManyOfflineEventsResponsePayload>, Record<string, never>, Scalars['Int'] | string>
  },
  Customer?: {
    activeProjectCount?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['Int'] | string>,
    addressLine1?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    addressLine2?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    city?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    contracts?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Array<WithTypename<Contract> | string>>,
    createdAt?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['DateTime'] | string>,
    customerId?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    email?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    image?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    notes?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    phoneNumber?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    phoneNumberExt?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    state?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['DateTime'] | string>,
    zip?: GraphCacheResolver<WithTypename<Customer>, Record<string, never>, Scalars['String'] | string>
  },
  DeliverableUnit?: {
    archivedAt?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars['DateTime'] | string>,
    color?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars['DateTime'] | string>,
    deletedAt?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars['DateTime'] | string>,
    description?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars['String'] | string>,
    divisionDeliverableUnit?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, WithTypename<DivisionDeliverableUnit> | string>,
    id?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars['ID'] | string>,
    referenceNumber?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars['String'] | string>,
    unitOfMeasure?: GraphCacheResolver<WithTypename<DeliverableUnit>, Record<string, never>, Scalars['String'] | string>
  },
  DigestDataDetails?: {
    code?: GraphCacheResolver<WithTypename<DigestDataDetails>, Record<string, never>, Scalars['String'] | string>,
    dailyProgress?: GraphCacheResolver<WithTypename<DigestDataDetails>, Record<string, never>, Scalars['Int'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<DigestDataDetails>, Record<string, never>, Scalars['String'] | string>,
    isArchived?: GraphCacheResolver<WithTypename<DigestDataDetails>, Record<string, never>, Scalars['DateTime'] | string>,
    isComplete?: GraphCacheResolver<WithTypename<DigestDataDetails>, Record<string, never>, Scalars['DateTime'] | string>,
    name?: GraphCacheResolver<WithTypename<DigestDataDetails>, Record<string, never>, Scalars['String'] | string>,
    project?: GraphCacheResolver<WithTypename<DigestDataDetails>, Record<string, never>, WithTypename<Project> | string>,
    projectId?: GraphCacheResolver<WithTypename<DigestDataDetails>, Record<string, never>, Scalars['String'] | string>,
    tasksCompletedSum?: GraphCacheResolver<WithTypename<DigestDataDetails>, Record<string, never>, Scalars['Int'] | string>,
    timeEntriesSum?: GraphCacheResolver<WithTypename<DigestDataDetails>, Record<string, never>, Scalars['Float'] | string>
  },
  Division?: {
    activeProjectsCount?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Int'] | string>,
    activeUsersForDateRangeCount?: GraphCacheResolver<WithTypename<Division>, DivisionActiveUsersForDateRangeCountArgs, Scalars['Int'] | string>,
    address?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, WithTypename<OrganizationAddress> | string>,
    adminCount?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Int'] | string>,
    admins?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Array<WithTypename<User> | string>>,
    allowTeamMembersClockInOut?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Boolean'] | string>,
    archived?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Boolean'] | string>,
    assetCount?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Int'] | string>,
    billingPastDueAt?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['DateTime'] | string>,
    billingStatus?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, BillingStatus | string>,
    clockedInUserCount?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Int'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['DateTime'] | string>,
    dailyDigestData?: GraphCacheResolver<WithTypename<Division>, DivisionDailyDigestDataArgs, Array<WithTypename<DigestDataDetails> | string>>,
    dateCreated?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['DateTime'] | string>,
    divisionCount?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['String'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['String'] | string>,
    isCurrentAssignment?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Boolean'] | string>,
    isPrimary?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Boolean'] | string>,
    lastStripeInvoice?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, WithTypename<StripeInvoice> | string>,
    name?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['String'] | string>,
    notificationSettings?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['JSON'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['String'] | string>,
    overtimeType?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, OvertimeTypeEnum | string>,
    projectsCount?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Int'] | string>,
    requireInjuryReportOnClockout?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Boolean'] | string>,
    requireTimeEntryEvidence?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Boolean'] | string>,
    roles?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Array<WithTypename<Role> | string>>,
    schedule?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, WithTypename<Schedule> | string>,
    scheduledBreaks?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Array<WithTypename<ScheduledBreak> | string>>,
    secondsClockedForCurrentWeek?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Int'] | string>,
    stripeCustomerId?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['String'] | string>,
    stripeInvoices?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Array<WithTypename<StripeInvoice> | string>>,
    timezone?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['String'] | string>,
    unassignedProjectId?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['String'] | string>,
    unitsOfMeasure?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Array<WithTypename<UnitOfMeasure> | string>>,
    userCount?: GraphCacheResolver<WithTypename<Division>, Record<string, never>, Scalars['Int'] | string>
  },
  DivisionDeliverableUnit?: {
    active?: GraphCacheResolver<WithTypename<DivisionDeliverableUnit>, Record<string, never>, Scalars['Boolean'] | string>,
    assignedToTask?: GraphCacheResolver<WithTypename<DivisionDeliverableUnit>, Record<string, never>, Scalars['Boolean'] | string>,
    id?: GraphCacheResolver<WithTypename<DivisionDeliverableUnit>, Record<string, never>, Scalars['ID'] | string>
  },
  EditHistoryEvent?: {
    createdAt?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    data?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['JSON'] | string>,
    id?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['ID'] | string>,
    modelId?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['String'] | string>,
    modelType?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['String'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['ID'] | string>,
    project?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, WithTypename<Project> | string>,
    projectId?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['String'] | string>,
    task?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    user?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, WithTypename<User> | string>,
    userId?: GraphCacheResolver<WithTypename<EditHistoryEvent>, Record<string, never>, Scalars['String'] | string>
  },
  EndEvidence?: {
    imageUrl?: GraphCacheResolver<WithTypename<EndEvidence>, Record<string, never>, Scalars['String'] | string>,
    location?: GraphCacheResolver<WithTypename<EndEvidence>, Record<string, never>, WithTypename<GeolocationPosition> | string>,
    locationError?: GraphCacheResolver<WithTypename<EndEvidence>, Record<string, never>, Scalars['String'] | string>,
    signatureUrl?: GraphCacheResolver<WithTypename<EndEvidence>, Record<string, never>, Scalars['String'] | string>
  },
  File?: {
    assetId?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>,
    contentType?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['DateTime'] | string>,
    deletedAt?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['DateTime'] | string>,
    documentUrl?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>,
    expiresAt?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['DateTime'] | string>,
    fileName?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>,
    key?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>,
    objectVersion?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['Int'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>,
    projectId?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>,
    status?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>,
    userId?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>,
    vendorId?: GraphCacheResolver<WithTypename<File>, Record<string, never>, Scalars['String'] | string>
  },
  FileUpload?: {
    fileId?: GraphCacheResolver<WithTypename<FileUpload>, Record<string, never>, Scalars['String'] | string>,
    objectKey?: GraphCacheResolver<WithTypename<FileUpload>, Record<string, never>, Scalars['String'] | string>,
    uploadUrl?: GraphCacheResolver<WithTypename<FileUpload>, Record<string, never>, Scalars['String'] | string>,
    urlExpiresAt?: GraphCacheResolver<WithTypename<FileUpload>, Record<string, never>, Scalars['DateTime'] | string>
  },
  FileUploadResponse?: {
    fileId?: GraphCacheResolver<WithTypename<FileUploadResponse>, Record<string, never>, Scalars['String'] | string>,
    objectKey?: GraphCacheResolver<WithTypename<FileUploadResponse>, Record<string, never>, Scalars['String'] | string>,
    uploadUrl?: GraphCacheResolver<WithTypename<FileUploadResponse>, Record<string, never>, Scalars['String'] | string>,
    urlExpiresAt?: GraphCacheResolver<WithTypename<FileUploadResponse>, Record<string, never>, Scalars['DateTime'] | string>
  },
  FraudEvidenceData?: {
    currentUtcDateTime?: GraphCacheResolver<WithTypename<FraudEvidenceData>, Record<string, never>, Scalars['DateTime'] | string>,
    systemWallTime?: GraphCacheResolver<WithTypename<FraudEvidenceData>, Record<string, never>, Scalars['Int'] | string>
  },
  GanttProjectDetailsRef?: {
    endDate?: GraphCacheResolver<WithTypename<GanttProjectDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<GanttProjectDetailsRef>, Record<string, never>, Scalars['String'] | string>,
    isComplete?: GraphCacheResolver<WithTypename<GanttProjectDetailsRef>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<GanttProjectDetailsRef>, Record<string, never>, Scalars['String'] | string>,
    startDate?: GraphCacheResolver<WithTypename<GanttProjectDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    taskDependencies?: GraphCacheResolver<WithTypename<GanttProjectDetailsRef>, Record<string, never>, Array<WithTypename<GanttTaskDependencyRef> | string>>,
    taskGroups?: GraphCacheResolver<WithTypename<GanttProjectDetailsRef>, Record<string, never>, Array<WithTypename<GanttTaskGroupDetailsRef> | string>>,
    tasks?: GraphCacheResolver<WithTypename<GanttProjectDetailsRef>, Record<string, never>, Array<WithTypename<GanttTaskDetailsRef> | string>>
  },
  GanttTaskDependencyRef?: {
    dependentTaskEndDate?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['DateTime'] | string>,
    dependentTaskGroupId?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['String'] | string>,
    dependentTaskId?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['String'] | string>,
    dependentTaskSortOrder?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['Int'] | string>,
    dependentTaskStartDate?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['String'] | string>,
    metadata?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, WithTypename<TaskDependencyData> | string>,
    predecessorTaskEndDate?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['DateTime'] | string>,
    predecessorTaskGroupId?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['String'] | string>,
    predecessorTaskId?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['String'] | string>,
    predecessorTaskSortOrder?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['Int'] | string>,
    predecessorTaskStartDate?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, Scalars['DateTime'] | string>,
    type?: GraphCacheResolver<WithTypename<GanttTaskDependencyRef>, Record<string, never>, TaskDependencyType | string>
  },
  GanttTaskDetailsRef?: {
    actualEndDate?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    actualStartDate?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    constrainedEndDate?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    constrainedStartDate?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    groupId?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['String'] | string>,
    isComplete?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['String'] | string>,
    plannedEndDate?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    plannedStartDate?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    sortOrder?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['Int'] | string>,
    unitGoalsSumProgress?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['Float'] | string>,
    unitGoalsSumTargetQuantity?: GraphCacheResolver<WithTypename<GanttTaskDetailsRef>, Record<string, never>, Scalars['Float'] | string>
  },
  GanttTaskGroupDetailsRef?: {
    actualEndDate?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    actualStartDate?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    constrainedEndDate?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    constrainedStartDate?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['String'] | string>,
    isComplete?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['String'] | string>,
    plannedEndDate?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    plannedStartDate?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['DateTime'] | string>,
    sortOrder?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['Int'] | string>,
    tasks?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Array<WithTypename<GanttTaskDetailsRef> | string>>,
    unitProgress?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['Float'] | string>,
    unitTargetGoal?: GraphCacheResolver<WithTypename<GanttTaskGroupDetailsRef>, Record<string, never>, Scalars['Float'] | string>
  },
  GeolocationCoordinates?: {
    accuracy?: GraphCacheResolver<WithTypename<GeolocationCoordinates>, Record<string, never>, Scalars['Float'] | string>,
    altitude?: GraphCacheResolver<WithTypename<GeolocationCoordinates>, Record<string, never>, Scalars['Float'] | string>,
    altitudeAccuracy?: GraphCacheResolver<WithTypename<GeolocationCoordinates>, Record<string, never>, Scalars['Float'] | string>,
    heading?: GraphCacheResolver<WithTypename<GeolocationCoordinates>, Record<string, never>, Scalars['Float'] | string>,
    latitude?: GraphCacheResolver<WithTypename<GeolocationCoordinates>, Record<string, never>, Scalars['Float'] | string>,
    longitude?: GraphCacheResolver<WithTypename<GeolocationCoordinates>, Record<string, never>, Scalars['Float'] | string>,
    speed?: GraphCacheResolver<WithTypename<GeolocationCoordinates>, Record<string, never>, Scalars['Float'] | string>
  },
  GeolocationPosition?: {
    coords?: GraphCacheResolver<WithTypename<GeolocationPosition>, Record<string, never>, WithTypename<GeolocationCoordinates> | string>,
    isMocked?: GraphCacheResolver<WithTypename<GeolocationPosition>, Record<string, never>, Scalars['Boolean'] | string>,
    timestamp?: GraphCacheResolver<WithTypename<GeolocationPosition>, Record<string, never>, Scalars['DateTime'] | string>
  },
  InjuryReport?: {
    createdAt?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars['ID'] | string>,
    notes?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars['String'] | string>,
    project?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, WithTypename<Project> | string>,
    projectId?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars['String'] | string>,
    reporter?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, WithTypename<User> | string>,
    reporterId?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars['String'] | string>,
    signatureUrl?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars['String'] | string>,
    task?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars['DateTime'] | string>,
    user?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, WithTypename<User> | string>,
    userId?: GraphCacheResolver<WithTypename<InjuryReport>, Record<string, never>, Scalars['String'] | string>
  },
  InjuryReportData?: {
    fileUploadResponses?: GraphCacheResolver<WithTypename<InjuryReportData>, Record<string, never>, Array<WithTypename<FileUploadResponse> | string>>,
    injuryReportId?: GraphCacheResolver<WithTypename<InjuryReportData>, Record<string, never>, Scalars['String'] | string>,
    notes?: GraphCacheResolver<WithTypename<InjuryReportData>, Record<string, never>, Scalars['String'] | string>,
    signaturePath?: GraphCacheResolver<WithTypename<InjuryReportData>, Record<string, never>, Scalars['String'] | string>,
    task?: GraphCacheResolver<WithTypename<InjuryReportData>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<InjuryReportData>, Record<string, never>, Scalars['String'] | string>,
    user?: GraphCacheResolver<WithTypename<InjuryReportData>, Record<string, never>, WithTypename<User> | string>,
    userId?: GraphCacheResolver<WithTypename<InjuryReportData>, Record<string, never>, Scalars['String'] | string>
  },
  InspectionReportAggregations?: {
    falseCount?: GraphCacheResolver<WithTypename<InspectionReportAggregations>, Record<string, never>, Scalars['Int'] | string>,
    trueCount?: GraphCacheResolver<WithTypename<InspectionReportAggregations>, Record<string, never>, Scalars['Int'] | string>
  },
  LastExport?: {
    exportedAt?: GraphCacheResolver<WithTypename<LastExport>, Record<string, never>, Scalars['String'] | string>,
    userId?: GraphCacheResolver<WithTypename<LastExport>, Record<string, never>, Scalars['String'] | string>,
    userName?: GraphCacheResolver<WithTypename<LastExport>, Record<string, never>, Scalars['String'] | string>
  },
  LocationWithRadius?: {
    lat?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars['Float'] | string>,
    latitude?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars['Float'] | string>,
    lng?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars['Float'] | string>,
    longitude?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars['Float'] | string>,
    radius?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars['Float'] | string>,
    radiusInMeters?: GraphCacheResolver<WithTypename<LocationWithRadius>, Record<string, never>, Scalars['Float'] | string>
  },
  MetadataNote?: {
    content?: GraphCacheResolver<WithTypename<MetadataNote>, Record<string, never>, Scalars['String'] | string>,
    highlight?: GraphCacheResolver<WithTypename<MetadataNote>, Record<string, never>, Scalars['Boolean'] | string>,
    label?: GraphCacheResolver<WithTypename<MetadataNote>, Record<string, never>, Scalars['String'] | string>
  },
  OfflineEvent?: {
    actionPayload?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['String'] | string>,
    assetReportData?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['JSON'] | string>,
    clientCanceledAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    clientCreatedAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    clientUpdatedAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    clockInData?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, WithTypename<ClockInData> | string>,
    clockOutData?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, WithTypename<ClockOutData> | string>,
    createdAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    deviceInformation?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['JSON'] | string>,
    errors?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['String'] | string>,
    fraudDetected?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['Boolean'] | string>,
    fraudEvidence?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['ID'] | string>,
    injuryReportData?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, WithTypename<InjuryReportData> | string>,
    issues?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['String'] | string>,
    location?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, WithTypename<GeolocationPosition> | string>,
    markedAsResolvedAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    markedAsResolvedBy?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, WithTypename<User> | string>,
    markedAsResolvedById?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['String'] | string>,
    mobileApplicationIsVerified?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['Boolean'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['ID'] | string>,
    processedAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    reassignData?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, WithTypename<ReassignmentData> | string>,
    receivedAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    reconciledAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    reporterId?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['ID'] | string>,
    requestInformation?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['JSON'] | string>,
    taskProgressData?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, WithTypename<TaskProgressEventData> | string>,
    type?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, OfflineEventType | string>,
    updatedAt?: GraphCacheResolver<WithTypename<OfflineEvent>, Record<string, never>, Scalars['DateTime'] | string>
  },
  Organization?: {
    activeProjectsCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Int'] | string>,
    activeUsersForDateRangeCount?: GraphCacheResolver<WithTypename<Organization>, OrganizationActiveUsersForDateRangeCountArgs, Scalars['Int'] | string>,
    address?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, WithTypename<OrganizationAddress> | string>,
    adminCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Int'] | string>,
    admins?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Array<WithTypename<User> | string>>,
    allowTeamMembersClockInOut?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Boolean'] | string>,
    archived?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Boolean'] | string>,
    assetCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Int'] | string>,
    billingPastDueAt?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['DateTime'] | string>,
    billingStatus?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, BillingStatus | string>,
    clockedInUserCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Int'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['DateTime'] | string>,
    dailyDigestData?: GraphCacheResolver<WithTypename<Organization>, OrganizationDailyDigestDataArgs, Array<WithTypename<DigestDataDetails> | string>>,
    dateCreated?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['DateTime'] | string>,
    divisionCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Int'] | string>,
    divisions?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Array<WithTypename<Division> | string>>,
    featureToggleSegments?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Array<Scalars['String'] | string>>,
    id?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['String'] | string>,
    image?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['String'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['String'] | string>,
    isCurrentAssignment?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Boolean'] | string>,
    isPrimary?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Boolean'] | string>,
    lastStripeInvoice?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, WithTypename<StripeInvoice> | string>,
    name?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['String'] | string>,
    notificationSettings?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['JSON'] | string>,
    overtimeType?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, OvertimeTypeEnum | string>,
    projectsCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Int'] | string>,
    requireInjuryReportOnClockout?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Boolean'] | string>,
    requireTimeEntryEvidence?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Boolean'] | string>,
    roles?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Array<WithTypename<Role> | string>>,
    schedule?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, WithTypename<Schedule> | string>,
    scheduledBreaks?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Array<WithTypename<ScheduledBreak> | string>>,
    secondsClockedForCurrentWeek?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Int'] | string>,
    stripeCustomerId?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['String'] | string>,
    stripeInvoices?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Array<WithTypename<StripeInvoice> | string>>,
    timezone?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['String'] | string>,
    unassignedProjectId?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['String'] | string>,
    unitsOfMeasure?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Array<WithTypename<UnitOfMeasure> | string>>,
    userCount?: GraphCacheResolver<WithTypename<Organization>, Record<string, never>, Scalars['Int'] | string>
  },
  OrganizationAddress?: {
    addressLine1?: GraphCacheResolver<WithTypename<OrganizationAddress>, Record<string, never>, Scalars['String'] | string>,
    addressLine2?: GraphCacheResolver<WithTypename<OrganizationAddress>, Record<string, never>, Scalars['String'] | string>,
    city?: GraphCacheResolver<WithTypename<OrganizationAddress>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<OrganizationAddress>, Record<string, never>, Scalars['String'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<OrganizationAddress>, Record<string, never>, Scalars['String'] | string>,
    state?: GraphCacheResolver<WithTypename<OrganizationAddress>, Record<string, never>, Scalars['String'] | string>,
    zip?: GraphCacheResolver<WithTypename<OrganizationAddress>, Record<string, never>, Scalars['String'] | string>
  },
  PageInfo?: {
    endCursor?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['String'] | string>,
    hasNextPage?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['Boolean'] | string>,
    hasPreviousPage?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['Boolean'] | string>,
    startCursor?: GraphCacheResolver<WithTypename<PageInfo>, Record<string, never>, Scalars['String'] | string>
  },
  PayrollExportRow?: {
    code?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>,
    companyUserId?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>,
    dailyTotals?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Array<Scalars['String'] | string>>,
    daysWorked?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>,
    firstName?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>,
    jobTitle?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>,
    lastName?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>,
    overtime?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>,
    projectId?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>,
    regularTime?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>,
    totalPaidTime?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>,
    userId?: GraphCacheResolver<WithTypename<PayrollExportRow>, Record<string, never>, Scalars['String'] | string>
  },
  ProductionRateData?: {
    actual?: GraphCacheResolver<WithTypename<ProductionRateData>, Record<string, never>, Scalars['Float'] | string>,
    expected?: GraphCacheResolver<WithTypename<ProductionRateData>, Record<string, never>, Scalars['Float'] | string>,
    rate?: GraphCacheResolver<WithTypename<ProductionRateData>, Record<string, never>, Scalars['Float'] | string>
  },
  Project?: {
    activeTasksCount?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['Int'] | string>,
    address?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Address> | string>,
    assetCount?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['Int'] | string>,
    assets?: GraphCacheResolver<WithTypename<Project>, ProjectAssetsArgs, Array<WithTypename<Asset> | string>>,
    assetsCount?: GraphCacheResolver<WithTypename<Project>, ProjectAssetsCountArgs, Scalars['Int'] | string>,
    assignees?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<UserAssignment> | string>>,
    code?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    contract?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Contract> | string>,
    contractId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['DateTime'] | string>,
    customer?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Customer> | string>,
    customerId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    dateCreated?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['DateTime'] | string>,
    defaultTask?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Task> | string>,
    deliveredPrimaryGoalUnits?: GraphCacheResolver<WithTypename<Project>, ProjectDeliveredPrimaryGoalUnitsArgs, Scalars['Float'] | string>,
    description?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['HTML'] | string>,
    divisionId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    documents?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<File> | string>>,
    editHistoryEvents?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<EditHistoryEvent> | string>>,
    editHistoryEventsCount?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['Float'] | string>,
    endDate?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['DateTime'] | string>,
    estimatedHours?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['Float'] | string>,
    ganttDetails?: GraphCacheResolver<WithTypename<Project>, ProjectGanttDetailsArgs, WithTypename<GanttProjectDetailsRef> | string>,
    googlePlace?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['JSON'] | string>,
    hasReportableUnit?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['Boolean'] | string>,
    hoursDataByDateRangeType?: GraphCacheResolver<WithTypename<Project>, ProjectHoursDataByDateRangeTypeArgs, Scalars['JSON'] | string>,
    id?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    injuryReports?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<InjuryReport> | string>>,
    isArchived?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['DateTime'] | string>,
    isComplete?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['DateTime'] | string>,
    isDefault?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['Boolean'] | string>,
    lastExport?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['JSON'] | string>,
    location?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<LocationWithRadius> | string>,
    locationNotes?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['HTML'] | string>,
    locationUntyped?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['JSON'] | string>,
    metadata?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<MetadataNote> | string>>,
    metadataNotes?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<MetadataNote> | string>>,
    name?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    notes?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<MetadataNote> | string>>,
    orgScheduleId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    organization?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Organization> | string>,
    organizationId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    primaryGoalTargetQuantity?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['Float'] | string>,
    productionRate?: GraphCacheResolver<WithTypename<Project>, ProjectProductionRateArgs, Scalars['Float'] | string>,
    progressSummary?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<ProjectProgressSummary> | string>,
    schedule?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Schedule> | string>,
    scheduledBreaks?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<ScheduledBreak> | string>>,
    startDate?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['DateTime'] | string>,
    taskDependencies?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<TaskDependency> | string>>,
    taskGroups?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<TaskGroup> | string>>,
    taskProgressEventsWithUnitReports?: GraphCacheResolver<WithTypename<Project>, ProjectTaskProgressEventsWithUnitReportsArgs, Array<WithTypename<TaskProgressEvent> | string>>,
    tasks?: GraphCacheResolver<WithTypename<Project>, ProjectTasksArgs, Array<WithTypename<Task> | string>>,
    tasksCount?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['Int'] | string>,
    timeEntries?: GraphCacheResolver<WithTypename<Project>, ProjectTimeEntriesArgs, Array<WithTypename<TimeEntry> | string>>,
    timeEntriesSumDurationInSeconds?: GraphCacheResolver<WithTypename<Project>, ProjectTimeEntriesSumDurationInSecondsArgs, Scalars['Float'] | string>,
    unassignedTask?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<Task> | string>,
    unassignedTaskId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>,
    unitGoals?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<UnitGoal> | string>>,
    userCount?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['Int'] | string>,
    users?: GraphCacheResolver<WithTypename<Project>, ProjectUsersArgs, Array<WithTypename<User> | string>>,
    vendors?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Array<WithTypename<Vendor> | string>>,
    workersCompCode?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, WithTypename<WorkersCompCode> | string>,
    workersCompCodeId?: GraphCacheResolver<WithTypename<Project>, Record<string, never>, Scalars['String'] | string>
  },
  ProjectProgressSummary?: {
    completedHours?: GraphCacheResolver<WithTypename<ProjectProgressSummary>, Record<string, never>, Scalars['Float'] | string>,
    completedUnits?: GraphCacheResolver<WithTypename<ProjectProgressSummary>, Record<string, never>, Scalars['Float'] | string>,
    completionPercentage?: GraphCacheResolver<WithTypename<ProjectProgressSummary>, Record<string, never>, Scalars['Float'] | string>,
    noHours?: GraphCacheResolver<WithTypename<ProjectProgressSummary>, Record<string, never>, Scalars['Boolean'] | string>,
    targetHours?: GraphCacheResolver<WithTypename<ProjectProgressSummary>, Record<string, never>, Scalars['Float'] | string>,
    targetUnits?: GraphCacheResolver<WithTypename<ProjectProgressSummary>, Record<string, never>, Scalars['Float'] | string>
  },
  ProjectVendor?: {
    createdAt?: GraphCacheResolver<WithTypename<ProjectVendor>, Record<string, never>, Scalars['DateTime'] | string>,
    project?: GraphCacheResolver<WithTypename<ProjectVendor>, Record<string, never>, WithTypename<Project> | string>,
    vendor?: GraphCacheResolver<WithTypename<ProjectVendor>, Record<string, never>, WithTypename<Vendor> | string>
  },
  ProjectVendorInputData?: {
    projectId?: GraphCacheResolver<WithTypename<ProjectVendorInputData>, Record<string, never>, Scalars['String'] | string>,
    vendorId?: GraphCacheResolver<WithTypename<ProjectVendorInputData>, Record<string, never>, Scalars['String'] | string>
  },
  QueryActiveProjectsPaginatedConnection?: {
    edges?: GraphCacheResolver<WithTypename<QueryActiveProjectsPaginatedConnection>, Record<string, never>, Array<WithTypename<QueryActiveProjectsPaginatedConnectionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<QueryActiveProjectsPaginatedConnection>, Record<string, never>, WithTypename<PageInfo> | string>
  },
  QueryActiveProjectsPaginatedConnectionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<QueryActiveProjectsPaginatedConnectionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<QueryActiveProjectsPaginatedConnectionEdge>, Record<string, never>, WithTypename<Project> | string>
  },
  QueryArchivedAssetsConnection?: {
    edges?: GraphCacheResolver<WithTypename<QueryArchivedAssetsConnection>, Record<string, never>, Array<WithTypename<QueryArchivedAssetsConnectionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<QueryArchivedAssetsConnection>, Record<string, never>, WithTypename<PageInfo> | string>,
    totalCount?: GraphCacheResolver<WithTypename<QueryArchivedAssetsConnection>, Record<string, never>, Scalars['Int'] | string>
  },
  QueryArchivedAssetsConnectionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<QueryArchivedAssetsConnectionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<QueryArchivedAssetsConnectionEdge>, Record<string, never>, WithTypename<Asset> | string>
  },
  QueryAssets_2Connection?: {
    edges?: GraphCacheResolver<WithTypename<QueryAssets_2Connection>, Record<string, never>, Array<WithTypename<QueryAssets_2ConnectionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<QueryAssets_2Connection>, Record<string, never>, WithTypename<PageInfo> | string>,
    totalCount?: GraphCacheResolver<WithTypename<QueryAssets_2Connection>, Record<string, never>, Scalars['Int'] | string>
  },
  QueryAssets_2ConnectionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<QueryAssets_2ConnectionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<QueryAssets_2ConnectionEdge>, Record<string, never>, WithTypename<Asset> | string>
  },
  QueryProjectsPaginatedConnection?: {
    edges?: GraphCacheResolver<WithTypename<QueryProjectsPaginatedConnection>, Record<string, never>, Array<WithTypename<QueryProjectsPaginatedConnectionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<QueryProjectsPaginatedConnection>, Record<string, never>, WithTypename<PageInfo> | string>
  },
  QueryProjectsPaginatedConnectionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<QueryProjectsPaginatedConnectionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<QueryProjectsPaginatedConnectionEdge>, Record<string, never>, WithTypename<Project> | string>
  },
  QueryTasksPaginatedConnection?: {
    edges?: GraphCacheResolver<WithTypename<QueryTasksPaginatedConnection>, Record<string, never>, Array<WithTypename<QueryTasksPaginatedConnectionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<QueryTasksPaginatedConnection>, Record<string, never>, WithTypename<PageInfo> | string>
  },
  QueryTasksPaginatedConnectionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<QueryTasksPaginatedConnectionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<QueryTasksPaginatedConnectionEdge>, Record<string, never>, WithTypename<Task> | string>
  },
  QueryUsersConnection?: {
    edges?: GraphCacheResolver<WithTypename<QueryUsersConnection>, Record<string, never>, Array<WithTypename<QueryUsersConnectionEdge> | string>>,
    pageInfo?: GraphCacheResolver<WithTypename<QueryUsersConnection>, Record<string, never>, WithTypename<PageInfo> | string>,
    totalCount?: GraphCacheResolver<WithTypename<QueryUsersConnection>, Record<string, never>, Scalars['Int'] | string>
  },
  QueryUsersConnectionEdge?: {
    cursor?: GraphCacheResolver<WithTypename<QueryUsersConnectionEdge>, Record<string, never>, Scalars['String'] | string>,
    node?: GraphCacheResolver<WithTypename<QueryUsersConnectionEdge>, Record<string, never>, WithTypename<User> | string>
  },
  ReassignmentData?: {
    newProject?: GraphCacheResolver<WithTypename<ReassignmentData>, Record<string, never>, WithTypename<Project> | string>,
    newProjectId?: GraphCacheResolver<WithTypename<ReassignmentData>, Record<string, never>, Scalars['String'] | string>,
    newTask?: GraphCacheResolver<WithTypename<ReassignmentData>, Record<string, never>, WithTypename<Task> | string>,
    newTaskId?: GraphCacheResolver<WithTypename<ReassignmentData>, Record<string, never>, Scalars['String'] | string>,
    oldProject?: GraphCacheResolver<WithTypename<ReassignmentData>, Record<string, never>, WithTypename<Project> | string>,
    oldProjectId?: GraphCacheResolver<WithTypename<ReassignmentData>, Record<string, never>, Scalars['String'] | string>,
    oldTask?: GraphCacheResolver<WithTypename<ReassignmentData>, Record<string, never>, WithTypename<Task> | string>,
    oldTaskId?: GraphCacheResolver<WithTypename<ReassignmentData>, Record<string, never>, Scalars['String'] | string>,
    user?: GraphCacheResolver<WithTypename<ReassignmentData>, Record<string, never>, WithTypename<User> | string>,
    userId?: GraphCacheResolver<WithTypename<ReassignmentData>, Record<string, never>, Scalars['String'] | string>
  },
  RequestInfo?: {
    browser?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['String'] | string>,
    city?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['String'] | string>,
    country?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['String'] | string>,
    isBot?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['Boolean'] | string>,
    isCurl?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['Boolean'] | string>,
    isDesktop?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['Boolean'] | string>,
    isMobile?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['Boolean'] | string>,
    isTablet?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['Boolean'] | string>,
    os?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['String'] | string>,
    platform?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['String'] | string>,
    region?: GraphCacheResolver<WithTypename<RequestInfo>, Record<string, never>, Scalars['String'] | string>
  },
  Role?: {
    createdAt?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars['DateTime'] | string>,
    dateCreated?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars['DateTime'] | string>,
    description?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars['String'] | string>,
    order?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars['Int'] | string>,
    organization?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, WithTypename<Organization> | string>,
    organizationId?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars['String'] | string>,
    permissions?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Array<Scalars['String'] | string>>,
    widgets?: GraphCacheResolver<WithTypename<Role>, Record<string, never>, Scalars['JSON'] | string>
  },
  Schedule?: {
    id?: GraphCacheResolver<WithTypename<Schedule>, Record<string, never>, Scalars['String'] | string>,
    isDefault?: GraphCacheResolver<WithTypename<Schedule>, Record<string, never>, Scalars['Boolean'] | string>,
    nonWorkDays?: GraphCacheResolver<WithTypename<Schedule>, Record<string, never>, Array<WithTypename<ScheduleNonWorkDays> | string>>,
    workDays?: GraphCacheResolver<WithTypename<Schedule>, Record<string, never>, Array<WithTypename<ScheduleWorkDay> | string>>,
    workHours?: GraphCacheResolver<WithTypename<Schedule>, Record<string, never>, WithTypename<ScheduleWorkHours> | string>
  },
  ScheduleNonWorkDays?: {
    active?: GraphCacheResolver<WithTypename<ScheduleNonWorkDays>, Record<string, never>, Scalars['Boolean'] | string>,
    dateRange?: GraphCacheResolver<WithTypename<ScheduleNonWorkDays>, Record<string, never>, Array<Scalars['DateTime'] | string>>,
    id?: GraphCacheResolver<WithTypename<ScheduleNonWorkDays>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<ScheduleNonWorkDays>, Record<string, never>, Scalars['String'] | string>
  },
  ScheduleWorkDay?: {
    active?: GraphCacheResolver<WithTypename<ScheduleWorkDay>, Record<string, never>, Scalars['Boolean'] | string>,
    label?: GraphCacheResolver<WithTypename<ScheduleWorkDay>, Record<string, never>, Scalars['String'] | string>
  },
  ScheduleWorkHours?: {
    endTime?: GraphCacheResolver<WithTypename<ScheduleWorkHours>, Record<string, never>, Scalars['String'] | string>,
    hours?: GraphCacheResolver<WithTypename<ScheduleWorkHours>, Record<string, never>, Scalars['Int'] | string>,
    startTime?: GraphCacheResolver<WithTypename<ScheduleWorkHours>, Record<string, never>, Scalars['String'] | string>
  },
  ScheduledBreak?: {
    breakTask?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, WithTypename<Task> | string>,
    breakTaskId?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars['String'] | string>,
    durationInMinutes?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars['Int'] | string>,
    id?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars['String'] | string>,
    isActive?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars['Boolean'] | string>,
    localizedStartTime?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars['String'] | string>,
    projectId?: GraphCacheResolver<WithTypename<ScheduledBreak>, Record<string, never>, Scalars['String'] | string>
  },
  SecurityActivity?: {
    createdAt?: GraphCacheResolver<WithTypename<SecurityActivity>, Record<string, never>, Scalars['DateTime'] | string>,
    event?: GraphCacheResolver<WithTypename<SecurityActivity>, Record<string, never>, Scalars['String'] | string>,
    ip?: GraphCacheResolver<WithTypename<SecurityActivity>, Record<string, never>, Scalars['String'] | string>,
    userAgent?: GraphCacheResolver<WithTypename<SecurityActivity>, Record<string, never>, Scalars['String'] | string>
  },
  Settings?: {
    allowTeamMembersClockInOut?: GraphCacheResolver<WithTypename<Settings>, Record<string, never>, Scalars['Boolean'] | string>,
    id?: GraphCacheResolver<WithTypename<Settings>, Record<string, never>, Scalars['String'] | string>,
    notificationSettings?: GraphCacheResolver<WithTypename<Settings>, Record<string, never>, Scalars['JSON'] | string>,
    overtimeType?: GraphCacheResolver<WithTypename<Settings>, Record<string, never>, OvertimeTypeEnum | string>,
    requireInjuryReportOnClockout?: GraphCacheResolver<WithTypename<Settings>, Record<string, never>, Scalars['Boolean'] | string>,
    requireTimeEntryEvidence?: GraphCacheResolver<WithTypename<Settings>, Record<string, never>, Scalars['Boolean'] | string>
  },
  StartEvidence?: {
    imageUrl?: GraphCacheResolver<WithTypename<StartEvidence>, Record<string, never>, Scalars['String'] | string>,
    location?: GraphCacheResolver<WithTypename<StartEvidence>, Record<string, never>, WithTypename<GeolocationPosition> | string>,
    locationError?: GraphCacheResolver<WithTypename<StartEvidence>, Record<string, never>, Scalars['String'] | string>
  },
  StripeInvoice?: {
    accountName?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    amountDue?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Int'] | string>,
    amountPaid?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Int'] | string>,
    amountRemaining?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Int'] | string>,
    attemptCount?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Int'] | string>,
    attempted?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Boolean'] | string>,
    autoAdvance?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Boolean'] | string>,
    billingReason?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    collectionMethod?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    created?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['DateTime'] | string>,
    currency?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    customerEmail?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    customerName?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    customerPhone?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    description?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    dueDate?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['DateTime'] | string>,
    hostedInvoiceUrl?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    invoicePdf?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    livemode?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Boolean'] | string>,
    object?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    paid?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Boolean'] | string>,
    status?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['String'] | string>,
    subtotal?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Int'] | string>,
    subtotalExcludingTax?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Int'] | string>,
    tax?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Int'] | string>,
    total?: GraphCacheResolver<WithTypename<StripeInvoice>, Record<string, never>, Scalars['Int'] | string>
  },
  Task?: {
    archived?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Boolean'] | string>,
    assetCount?: GraphCacheResolver<WithTypename<Task>, TaskAssetCountArgs, Scalars['Int'] | string>,
    assignees?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<UserAssignment> | string>>,
    clockedInUserCount?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Int'] | string>,
    clockedOutUserCount?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Int'] | string>,
    completedHours?: GraphCacheResolver<WithTypename<Task>, TaskCompletedHoursArgs, Scalars['Float'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['DateTime'] | string>,
    dateCreated?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['DateTime'] | string>,
    dependentTaskDependencies?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<TaskDependency> | string>>,
    dependentTasks?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<Task> | string>>,
    description?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['HTML'] | string>,
    divisionId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>,
    driverTaskDependency?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, WithTypename<TaskDependency> | string>,
    endDate?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['DateTime'] | string>,
    estimatedHours?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Float'] | string>,
    estimatedWorkDays?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Float'] | string>,
    estimatedWorkHours?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Int'] | string>,
    group?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, WithTypename<TaskGroup> | string>,
    groupId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>,
    hasReportableUnit?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Boolean'] | string>,
    hoursDataByDateRangeType?: GraphCacheResolver<WithTypename<Task>, TaskHoursDataByDateRangeTypeArgs, Scalars['JSON'] | string>,
    id?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>,
    injuryReports?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<InjuryReport> | string>>,
    isComplete?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['DateTime'] | string>,
    isDefault?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Boolean'] | string>,
    isUnpaid?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Boolean'] | string>,
    lastExport?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['JSON'] | string>,
    metadata?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<MetadataNote> | string>>,
    metadataNotes?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<MetadataNote> | string>>,
    name?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>,
    notes?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<MetadataNote> | string>>,
    orgScheduleId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>,
    predecessorTaskDependencies?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<TaskDependency> | string>>,
    predecessorTasks?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<Task> | string>>,
    primaryUnitGoals?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<UnitGoal> | string>>,
    productionRate?: GraphCacheResolver<WithTypename<Task>, TaskProductionRateArgs, WithTypename<ProductionRateData> | string>,
    project?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, WithTypename<Project> | string>,
    projectId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>,
    projectScheduleId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>,
    schedule?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, WithTypename<Schedule> | string>,
    scheduledBreaks?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<ScheduledBreak> | string>>,
    sortOrder?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Int'] | string>,
    startDate?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['DateTime'] | string>,
    sumOfDeliveredUnits?: GraphCacheResolver<WithTypename<Task>, TaskSumOfDeliveredUnitsArgs, Scalars['Float'] | string>,
    taskProgressEvents?: GraphCacheResolver<WithTypename<Task>, TaskTaskProgressEventsArgs, Array<WithTypename<TaskProgressEvent> | string>>,
    taskProgressEventsWithUnitReports?: GraphCacheResolver<WithTypename<Task>, TaskTaskProgressEventsWithUnitReportsArgs, Array<WithTypename<TaskProgressEvent> | string>>,
    timeEntries?: GraphCacheResolver<WithTypename<Task>, TaskTimeEntriesArgs, Array<WithTypename<TimeEntry> | string>>,
    timeEntriesSumDurationInSeconds?: GraphCacheResolver<WithTypename<Task>, TaskTimeEntriesSumDurationInSecondsArgs, Scalars['Float'] | string>,
    unitGoals?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Array<WithTypename<UnitGoal> | string>>,
    unitGoalsSumProgress?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Float'] | string>,
    unitGoalsSumTargetQuantity?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Float'] | string>,
    userCount?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['Int'] | string>,
    users?: GraphCacheResolver<WithTypename<Task>, TaskUsersArgs, Array<WithTypename<User> | string>>,
    usersWhoClockedOrReportedCount?: GraphCacheResolver<WithTypename<Task>, TaskUsersWhoClockedOrReportedCountArgs, Scalars['Int'] | string>,
    visibilityLevel?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>,
    workersCompCode?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, WithTypename<WorkersCompCode> | string>,
    workersCompCodeId?: GraphCacheResolver<WithTypename<Task>, Record<string, never>, Scalars['String'] | string>
  },
  TaskDependency?: {
    dependentTaskId?: GraphCacheResolver<WithTypename<TaskDependency>, Record<string, never>, Scalars['ID'] | string>,
    id?: GraphCacheResolver<WithTypename<TaskDependency>, Record<string, never>, Scalars['String'] | string>,
    metadata?: GraphCacheResolver<WithTypename<TaskDependency>, Record<string, never>, WithTypename<TaskDependencyData> | string>,
    predecessorTaskId?: GraphCacheResolver<WithTypename<TaskDependency>, Record<string, never>, Scalars['ID'] | string>,
    projectId?: GraphCacheResolver<WithTypename<TaskDependency>, Record<string, never>, Scalars['ID'] | string>,
    type?: GraphCacheResolver<WithTypename<TaskDependency>, Record<string, never>, TaskDependencyType | string>
  },
  TaskDependencyData?: {
    offsetDays?: GraphCacheResolver<WithTypename<TaskDependencyData>, Record<string, never>, Scalars['Int'] | string>
  },
  TaskGroup?: {
    assetCount?: GraphCacheResolver<WithTypename<TaskGroup>, TaskGroupAssetCountArgs, Scalars['Int'] | string>,
    completedHours?: GraphCacheResolver<WithTypename<TaskGroup>, TaskGroupCompletedHoursArgs, Scalars['Float'] | string>,
    completedTaskCount?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['Int'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['DateTime'] | string>,
    description?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['HTML'] | string>,
    endDate?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['DateTime'] | string>,
    estimatedHours?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['Float'] | string>,
    id?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['ID'] | string>,
    isComplete?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['Boolean'] | string>,
    name?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['String'] | string>,
    notes?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Array<WithTypename<MetadataNote> | string>>,
    organizationId?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['ID'] | string>,
    productionRate?: GraphCacheResolver<WithTypename<TaskGroup>, TaskGroupProductionRateArgs, Scalars['Float'] | string>,
    projectId?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['String'] | string>,
    sortOrder?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['Int'] | string>,
    startDate?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['DateTime'] | string>,
    taskCount?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['Int'] | string>,
    tasks?: GraphCacheResolver<WithTypename<TaskGroup>, TaskGroupTasksArgs, Array<WithTypename<Task> | string>>,
    unitProgress?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['Float'] | string>,
    unitTargetGoal?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['Float'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<TaskGroup>, Record<string, never>, Scalars['DateTime'] | string>,
    userCount?: GraphCacheResolver<WithTypename<TaskGroup>, TaskGroupUserCountArgs, Scalars['Int'] | string>
  },
  TaskListItem?: {
    assetCount?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Int'] | string>,
    completedHours?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Float'] | string>,
    completedTaskCount?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Int'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['DateTime'] | string>,
    endDate?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['DateTime'] | string>,
    estimatedHours?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Float'] | string>,
    groupId?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['String'] | string>,
    isComplete?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Boolean'] | string>,
    isDefault?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Boolean'] | string>,
    metadata?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Array<WithTypename<MetadataNote> | string>>,
    name?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['String'] | string>,
    sortOrder?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Int'] | string>,
    startDate?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['DateTime'] | string>,
    taskCount?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Int'] | string>,
    taskGroupId?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['String'] | string>,
    taskId?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['String'] | string>,
    unitProgress?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Int'] | string>,
    unitTargetGoal?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Int'] | string>,
    userCount?: GraphCacheResolver<WithTypename<TaskListItem>, Record<string, never>, Scalars['Int'] | string>
  },
  TaskProgressEvent?: {
    createdAt?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars['String'] | string>,
    imageUrls?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Array<Scalars['String'] | string>>,
    note?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars['String'] | string>,
    reporter?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, WithTypename<User> | string>,
    reporterId?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars['String'] | string>,
    task?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars['String'] | string>,
    unitGoalProgressCount?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars['Int'] | string>,
    unitGoalProgressReports?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Array<WithTypename<UnitGoalProgress> | string>>,
    updatedAt?: GraphCacheResolver<WithTypename<TaskProgressEvent>, Record<string, never>, Scalars['DateTime'] | string>
  },
  TaskProgressEventData?: {
    fileUploadResponses?: GraphCacheResolver<WithTypename<TaskProgressEventData>, Record<string, never>, Array<WithTypename<FileUploadResponse> | string>>,
    fileUploads?: GraphCacheResolver<WithTypename<TaskProgressEventData>, Record<string, never>, Array<WithTypename<FileUpload> | string>>,
    note?: GraphCacheResolver<WithTypename<TaskProgressEventData>, Record<string, never>, Scalars['String'] | string>,
    task?: GraphCacheResolver<WithTypename<TaskProgressEventData>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<TaskProgressEventData>, Record<string, never>, Scalars['String'] | string>,
    taskProgressEvent?: GraphCacheResolver<WithTypename<TaskProgressEventData>, Record<string, never>, WithTypename<TaskProgressEvent> | string>,
    taskProgressEventId?: GraphCacheResolver<WithTypename<TaskProgressEventData>, Record<string, never>, Scalars['String'] | string>,
    unitGoalProgressInputs?: GraphCacheResolver<WithTypename<TaskProgressEventData>, Record<string, never>, Array<WithTypename<UnitGoalProgressInputData> | string>>
  },
  TimeCardWeek?: {
    days?: GraphCacheResolver<WithTypename<TimeCardWeek>, Record<string, never>, Array<WithTypename<TimeEntryDay> | string>>,
    overtime?: GraphCacheResolver<WithTypename<TimeCardWeek>, Record<string, never>, Scalars['Int'] | string>,
    regularTime?: GraphCacheResolver<WithTypename<TimeCardWeek>, Record<string, never>, Scalars['Int'] | string>,
    timezone?: GraphCacheResolver<WithTypename<TimeCardWeek>, Record<string, never>, Scalars['String'] | string>,
    totalEditedTimeInSeconds?: GraphCacheResolver<WithTypename<TimeCardWeek>, Record<string, never>, Scalars['Int'] | string>,
    totalPaidTimeInSeconds?: GraphCacheResolver<WithTypename<TimeCardWeek>, Record<string, never>, Scalars['Int'] | string>,
    totalTimeInSeconds?: GraphCacheResolver<WithTypename<TimeCardWeek>, Record<string, never>, Scalars['Int'] | string>
  },
  TimeEntry?: {
    createdAt?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['DateTime'] | string>,
    durationInSeconds?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['Float'] | string>,
    editData?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<TimeEntryWrapper> | string>,
    endAt?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['DateTime'] | string>,
    endAtEditor?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<User> | string>,
    endAtEditorId?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['String'] | string>,
    evidence?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['JSON'] | string>,
    evidenceType?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<TimeEntryEvidence> | string>,
    id?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['String'] | string>,
    isBreak?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['Boolean'] | string>,
    isUnpaid?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['Boolean'] | string>,
    organization?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<Organization> | string>,
    organizationId?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['String'] | string>,
    project?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<Project> | string>,
    projectId?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['String'] | string>,
    signInPhotoUrl?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['String'] | string>,
    signOutPhotoUrl?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['String'] | string>,
    startAt?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['DateTime'] | string>,
    startAtEditor?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<User> | string>,
    startAtEditorId?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['String'] | string>,
    task?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['DateTime'] | string>,
    user?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, WithTypename<User> | string>,
    userId?: GraphCacheResolver<WithTypename<TimeEntry>, Record<string, never>, Scalars['String'] | string>
  },
  TimeEntryChangeset?: {
    action?: GraphCacheResolver<WithTypename<TimeEntryChangeset>, Record<string, never>, ActionTypeEnum | string>,
    actor?: GraphCacheResolver<WithTypename<TimeEntryChangeset>, Record<string, never>, WithTypename<User> | string>,
    actorId?: GraphCacheResolver<WithTypename<TimeEntryChangeset>, Record<string, never>, Scalars['String'] | string>,
    changes?: GraphCacheResolver<WithTypename<TimeEntryChangeset>, Record<string, never>, WithTypename<TimeEntryChangesetChanges> | string>,
    id?: GraphCacheResolver<WithTypename<TimeEntryChangeset>, Record<string, never>, Scalars['Int'] | string>,
    operation?: GraphCacheResolver<WithTypename<TimeEntryChangeset>, Record<string, never>, OperationEnum | string>,
    timestamp?: GraphCacheResolver<WithTypename<TimeEntryChangeset>, Record<string, never>, Scalars['DateTime'] | string>,
    totalEditedTimeInSeconds?: GraphCacheResolver<WithTypename<TimeEntryChangeset>, Record<string, never>, Scalars['Int'] | string>,
    value?: GraphCacheResolver<WithTypename<TimeEntryChangeset>, Record<string, never>, WithTypename<TimeEntry> | string>
  },
  TimeEntryChangesetChanges?: {
    endAt?: GraphCacheResolver<WithTypename<TimeEntryChangesetChanges>, Record<string, never>, Scalars['DateTime'] | string>,
    startAt?: GraphCacheResolver<WithTypename<TimeEntryChangesetChanges>, Record<string, never>, Scalars['DateTime'] | string>
  },
  TimeEntryDay?: {
    id?: GraphCacheResolver<WithTypename<TimeEntryDay>, Record<string, never>, Scalars['String'] | string>,
    timeEntryWrappers?: GraphCacheResolver<WithTypename<TimeEntryDay>, Record<string, never>, Array<WithTypename<TimeEntryWrapper> | string>>,
    totalEditedTimeInSeconds?: GraphCacheResolver<WithTypename<TimeEntryDay>, Record<string, never>, Scalars['Int'] | string>,
    totalPaidTimeInSeconds?: GraphCacheResolver<WithTypename<TimeEntryDay>, Record<string, never>, Scalars['Int'] | string>,
    totalTimeInSeconds?: GraphCacheResolver<WithTypename<TimeEntryDay>, Record<string, never>, Scalars['Int'] | string>
  },
  TimeEntryDayRange?: {
    days?: GraphCacheResolver<WithTypename<TimeEntryDayRange>, Record<string, never>, Array<WithTypename<TimeEntryDay> | string>>,
    rangeTotalEditedTimeInSeconds?: GraphCacheResolver<WithTypename<TimeEntryDayRange>, Record<string, never>, Scalars['Int'] | string>,
    rangeTotalPaidTimeInSeconds?: GraphCacheResolver<WithTypename<TimeEntryDayRange>, Record<string, never>, Scalars['Int'] | string>,
    rangeTotalTimeInSeconds?: GraphCacheResolver<WithTypename<TimeEntryDayRange>, Record<string, never>, Scalars['Int'] | string>,
    timezone?: GraphCacheResolver<WithTypename<TimeEntryDayRange>, Record<string, never>, Scalars['String'] | string>
  },
  TimeEntryError?: {
    reason?: GraphCacheResolver<WithTypename<TimeEntryError>, Record<string, never>, TimeEntryErrorReason | string>,
    userId?: GraphCacheResolver<WithTypename<TimeEntryError>, Record<string, never>, Scalars['String'] | string>
  },
  TimeEntryEvidence?: {
    end?: GraphCacheResolver<WithTypename<TimeEntryEvidence>, Record<string, never>, WithTypename<EndEvidence> | string>,
    start?: GraphCacheResolver<WithTypename<TimeEntryEvidence>, Record<string, never>, WithTypename<StartEvidence> | string>
  },
  TimeEntryWrapper?: {
    actors?: GraphCacheResolver<WithTypename<TimeEntryWrapper>, Record<string, never>, Array<WithTypename<User> | string>>,
    history?: GraphCacheResolver<WithTypename<TimeEntryWrapper>, Record<string, never>, Array<WithTypename<TimeEntryChangeset> | string>>,
    id?: GraphCacheResolver<WithTypename<TimeEntryWrapper>, Record<string, never>, Scalars['String'] | string>,
    isBreak?: GraphCacheResolver<WithTypename<TimeEntryWrapper>, Record<string, never>, Scalars['Boolean'] | string>,
    splitEndAt?: GraphCacheResolver<WithTypename<TimeEntryWrapper>, Record<string, never>, Scalars['DateTime'] | string>,
    splitStartAt?: GraphCacheResolver<WithTypename<TimeEntryWrapper>, Record<string, never>, Scalars['DateTime'] | string>,
    timeEntry?: GraphCacheResolver<WithTypename<TimeEntryWrapper>, Record<string, never>, WithTypename<TimeEntry> | string>,
    totalEditedTimeInSeconds?: GraphCacheResolver<WithTypename<TimeEntryWrapper>, Record<string, never>, Scalars['Int'] | string>
  },
  UnitGoal?: {
    createdAt?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars['DateTime'] | string>,
    deliverableUnit?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, WithTypename<DeliverableUnit> | string>,
    deliverableUnitId?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars['ID'] | string>,
    id?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars['ID'] | string>,
    isPrimary?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars['Boolean'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars['ID'] | string>,
    previousProgress?: GraphCacheResolver<WithTypename<UnitGoal>, UnitGoalPreviousProgressArgs, Scalars['Float'] | string>,
    progressInDateRange?: GraphCacheResolver<WithTypename<UnitGoal>, UnitGoalProgressInDateRangeArgs, Scalars['Float'] | string>,
    project?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, WithTypename<Project> | string>,
    projectId?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars['ID'] | string>,
    targetQuantity?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars['Float'] | string>,
    task?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars['ID'] | string>,
    totalProgress?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars['Float'] | string>,
    unitGoalProgress?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Array<WithTypename<UnitGoalProgress> | string>>,
    updatedAt?: GraphCacheResolver<WithTypename<UnitGoal>, Record<string, never>, Scalars['DateTime'] | string>
  },
  UnitGoalProgress?: {
    createdAt?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, Scalars['String'] | string>,
    progress?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, Scalars['Float'] | string>,
    unitGoal?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, WithTypename<UnitGoal> | string>,
    unitGoalId?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<UnitGoalProgress>, Record<string, never>, Scalars['DateTime'] | string>
  },
  UnitGoalProgressInputData?: {
    progress?: GraphCacheResolver<WithTypename<UnitGoalProgressInputData>, Record<string, never>, Scalars['Float'] | string>,
    unitGoalId?: GraphCacheResolver<WithTypename<UnitGoalProgressInputData>, Record<string, never>, Scalars['String'] | string>
  },
  UnitOfMeasure?: {
    id?: GraphCacheResolver<WithTypename<UnitOfMeasure>, Record<string, never>, Scalars['Int'] | string>,
    name?: GraphCacheResolver<WithTypename<UnitOfMeasure>, Record<string, never>, Scalars['String'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<UnitOfMeasure>, Record<string, never>, Scalars['String'] | string>,
    symbol?: GraphCacheResolver<WithTypename<UnitOfMeasure>, Record<string, never>, Scalars['String'] | string>
  },
  UpdateSortOrderPayload?: {
    id?: GraphCacheResolver<WithTypename<UpdateSortOrderPayload>, Record<string, never>, Scalars['String'] | string>,
    sortOrder?: GraphCacheResolver<WithTypename<UpdateSortOrderPayload>, Record<string, never>, Scalars['Int'] | string>
  },
  User?: {
    archived?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Boolean'] | string>,
    assetCount?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Int'] | string>,
    assignedDivision?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Division> | string>,
    assignedProjectIds?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<Scalars['String'] | string>>,
    assignedTaskIds?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<Scalars['String'] | string>>,
    assignments?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<UserAssignment> | string>>,
    billingClassification?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<UserBillingClassification> | string>,
    billingClassificationId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    companyUserId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    currentAssignment?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<UserAssignment> | string>,
    currentProject?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Project> | string>,
    currentProjectId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    currentTask?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Task> | string>,
    currentTaskId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    defaultListVisibilityFilter?: GraphCacheResolver<WithTypename<User>, Record<string, never>, ListVisibilityFilter | string>,
    deviceSessions?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<UserDeviceSession> | string>>,
    divisionAccess?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<Division> | string>>,
    divisionId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    email?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    emailVerifiedAt?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['DateTime'] | string>,
    firstName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    fullName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    injuryReports?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<InjuryReport> | string>>,
    invitationPending?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Boolean'] | string>,
    isBlackthornEmployee?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Boolean'] | string>,
    isClockedIn?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Boolean'] | string>,
    isOnBreak?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Boolean'] | string>,
    jobTitle?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    lastName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    latestTimeEntry?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<TimeEntry> | string>,
    myFiles?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<File> | string>>,
    offlineEvents?: GraphCacheResolver<WithTypename<User>, UserOfflineEventsArgs, Array<WithTypename<OfflineEvent> | string>>,
    organization?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Organization> | string>,
    organizationId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    passwordUpdatedAt?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['DateTime'] | string>,
    payRate?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    payType?: GraphCacheResolver<WithTypename<User>, Record<string, never>, PayType | string>,
    phoneNumber?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    phoneNumberExt?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    project?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Project> | string>,
    projectId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    reportedUnitsInDateRangeByProjectOrTask?: GraphCacheResolver<WithTypename<User>, UserReportedUnitsInDateRangeByProjectOrTaskArgs, Array<WithTypename<TaskProgressEvent> | string>>,
    roles?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Array<WithTypename<Role> | string>>,
    secondsClockedInDateRangeOnProjectOrTask?: GraphCacheResolver<WithTypename<User>, UserSecondsClockedInDateRangeOnProjectOrTaskArgs, Scalars['Int'] | string>,
    secondsClockedSinceOrgMidnight?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Int'] | string>,
    secondsClockedThisWeek?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['Int'] | string>,
    securityActivity?: GraphCacheResolver<WithTypename<User>, UserSecurityActivityArgs, Array<WithTypename<SecurityActivity> | string>>,
    sortName?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    task?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>,
    timeEntries?: GraphCacheResolver<WithTypename<User>, UserTimeEntriesArgs, Array<WithTypename<TimeEntry> | string>>,
    totalEditedTimeInRange?: GraphCacheResolver<WithTypename<User>, UserTotalEditedTimeInRangeArgs, Scalars['Int'] | string>,
    workersCompCode?: GraphCacheResolver<WithTypename<User>, Record<string, never>, WithTypename<WorkersCompCode> | string>,
    workersCompCodeId?: GraphCacheResolver<WithTypename<User>, Record<string, never>, Scalars['String'] | string>
  },
  UserAssignment?: {
    active?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars['Boolean'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars['String'] | string>,
    isCurrentAssignment?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars['Boolean'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars['String'] | string>,
    project?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, WithTypename<Project> | string>,
    projectId?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars['String'] | string>,
    sortOrder?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars['Int'] | string>,
    task?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, WithTypename<Task> | string>,
    taskId?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars['DateTime'] | string>,
    user?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, WithTypename<User> | string>,
    userId?: GraphCacheResolver<WithTypename<UserAssignment>, Record<string, never>, Scalars['String'] | string>
  },
  UserAssignmentBatchResponse?: {
    count?: GraphCacheResolver<WithTypename<UserAssignmentBatchResponse>, Record<string, never>, Scalars['Int'] | string>
  },
  UserBillingClassification?: {
    createdAt?: GraphCacheResolver<WithTypename<UserBillingClassification>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UserBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<UserBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<UserBillingClassification>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<UserBillingClassification>, Record<string, never>, Scalars['DateTime'] | string>
  },
  UserDeviceSession?: {
    createdAt?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars['String'] | string>,
    ip?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars['String'] | string>,
    isSingleUse?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars['Boolean'] | string>,
    requestInfo?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, WithTypename<RequestInfo> | string>,
    updatedAt?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars['DateTime'] | string>,
    userAgent?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars['String'] | string>,
    userId?: GraphCacheResolver<WithTypename<UserDeviceSession>, Record<string, never>, Scalars['String'] | string>
  },
  UserNotification?: {
    asset?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, WithTypename<Asset> | string>,
    assignedUser?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, WithTypename<User> | string>,
    assignedUserId?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, Scalars['String'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, Scalars['ID'] | string>,
    markedReadAt?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, Scalars['DateTime'] | string>,
    modelType?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, UserNotificationModelType | string>,
    organizationId?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, Scalars['String'] | string>,
    type?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, UserNotificationType | string>,
    user?: GraphCacheResolver<WithTypename<UserNotification>, Record<string, never>, WithTypename<User> | string>
  },
  Vendor?: {
    activeProjectCount?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['Int'] | string>,
    addressLine1?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    addressLine2?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    city?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    contacts?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Array<WithTypename<VendorContact> | string>>,
    createdAt?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['DateTime'] | string>,
    email?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    files?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Array<WithTypename<File> | string>>,
    id?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    image?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    name?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    notes?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    phoneNumber?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    phoneNumberExt?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    projects?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Array<WithTypename<ProjectVendor> | string>>,
    state?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['DateTime'] | string>,
    vendorId?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>,
    zip?: GraphCacheResolver<WithTypename<Vendor>, Record<string, never>, Scalars['String'] | string>
  },
  VendorContact?: {
    email?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, Scalars['String'] | string>,
    firstName?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, Scalars['String'] | string>,
    id?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, Scalars['String'] | string>,
    image?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, Scalars['String'] | string>,
    imageUrl?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, Scalars['String'] | string>,
    lastName?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, Scalars['String'] | string>,
    phoneNumber?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, Scalars['String'] | string>,
    phoneNumberExt?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, Scalars['String'] | string>,
    role?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, Scalars['String'] | string>,
    vendor?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, WithTypename<Vendor> | string>,
    vendorId?: GraphCacheResolver<WithTypename<VendorContact>, Record<string, never>, Scalars['String'] | string>
  },
  WorkersCompCode?: {
    active?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars['Boolean'] | string>,
    createdAt?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars['DateTime'] | string>,
    id?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars['ID'] | string>,
    name?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars['String'] | string>,
    ncciCode?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars['String'] | string>,
    ncciDescription?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars['String'] | string>,
    organizationId?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars['ID'] | string>,
    override?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars['Boolean'] | string>,
    rate?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars['Float'] | string>,
    updatedAt?: GraphCacheResolver<WithTypename<WorkersCompCode>, Record<string, never>, Scalars['DateTime'] | string>
  },
  credentials?: {
    accessToken?: GraphCacheResolver<WithTypename<Credentials>, Record<string, never>, Scalars['String'] | string>
  }
};

export type GraphCacheOptimisticUpdaters = {
  activateOrganization?: GraphCacheOptimisticMutationResolver<MutationActivateOrganizationArgs, WithTypename<Organization>>,
  activateProject?: GraphCacheOptimisticMutationResolver<MutationActivateProjectArgs, WithTypename<Project>>,
  addOrUpdateNonWorkDay?: GraphCacheOptimisticMutationResolver<MutationAddOrUpdateNonWorkDayArgs, WithTypename<Schedule>>,
  addQuantitiesToGroup?: GraphCacheOptimisticMutationResolver<MutationAddQuantitiesToGroupArgs, Maybe<WithTypename<Asset>>>,
  archiveOrganization?: GraphCacheOptimisticMutationResolver<MutationArchiveOrganizationArgs, WithTypename<Organization>>,
  archiveProject?: GraphCacheOptimisticMutationResolver<MutationArchiveProjectArgs, WithTypename<Project>>,
  archiveQuantities?: GraphCacheOptimisticMutationResolver<MutationArchiveQuantitiesArgs, Array<WithTypename<Asset>>>,
  archiveWorkersCompCode?: GraphCacheOptimisticMutationResolver<MutationArchiveWorkersCompCodeArgs, Maybe<WithTypename<WorkersCompCode>>>,
  bulkClockIn?: GraphCacheOptimisticMutationResolver<MutationBulkClockInArgs, WithTypename<BulkTimeEntryResponse>>,
  bulkClockOut?: GraphCacheOptimisticMutationResolver<MutationBulkClockOutArgs, WithTypename<BulkTimeEntryResponse>>,
  bulkDeleteAssets?: GraphCacheOptimisticMutationResolver<MutationBulkDeleteAssetsArgs, Maybe<Array<WithTypename<Asset>>>>,
  bulkUpdateTaskSortOrder?: GraphCacheOptimisticMutationResolver<MutationBulkUpdateTaskSortOrderArgs, Array<WithTypename<UpdateSortOrderPayload>>>,
  bulkUpdateUserAssignments?: GraphCacheOptimisticMutationResolver<MutationBulkUpdateUserAssignmentsArgs, Maybe<WithTypename<UserAssignmentBatchResponse>>>,
  clockIn?: GraphCacheOptimisticMutationResolver<MutationClockInArgs, WithTypename<TimeEntry>>,
  clockOutUser?: GraphCacheOptimisticMutationResolver<MutationClockOutUserArgs, WithTypename<TimeEntry>>,
  completeProject?: GraphCacheOptimisticMutationResolver<MutationCompleteProjectArgs, WithTypename<Project>>,
  createAssetBillingClassification?: GraphCacheOptimisticMutationResolver<MutationCreateAssetBillingClassificationArgs, WithTypename<AssetBillingClassification>>,
  createCustomer?: GraphCacheOptimisticMutationResolver<MutationCreateCustomerArgs, Maybe<WithTypename<Customer>>>,
  createDeliverableUnit?: GraphCacheOptimisticMutationResolver<MutationCreateDeliverableUnitArgs, WithTypename<DeliverableUnit>>,
  createDivision?: GraphCacheOptimisticMutationResolver<MutationCreateDivisionArgs, WithTypename<Division>>,
  createInjuryReport?: GraphCacheOptimisticMutationResolver<MutationCreateInjuryReportArgs, WithTypename<InjuryReport>>,
  createManyTaskReports?: GraphCacheOptimisticMutationResolver<MutationCreateManyTaskReportsArgs, Maybe<Array<WithTypename<Task>>>>,
  createOfflineEvent?: GraphCacheOptimisticMutationResolver<MutationCreateOfflineEventArgs, WithTypename<OfflineEvent>>,
  createOfflineEvents?: GraphCacheOptimisticMutationResolver<MutationCreateOfflineEventsArgs, WithTypename<CreateManyOfflineEventsResponsePayload>>,
  createOneContract?: GraphCacheOptimisticMutationResolver<MutationCreateOneContractArgs, WithTypename<Contract>>,
  createSchedule?: GraphCacheOptimisticMutationResolver<MutationCreateScheduleArgs, WithTypename<Schedule>>,
  createTaskDependency?: GraphCacheOptimisticMutationResolver<MutationCreateTaskDependencyArgs, WithTypename<TaskDependency>>,
  createUnitGoal?: GraphCacheOptimisticMutationResolver<MutationCreateUnitGoalArgs, Maybe<WithTypename<UnitGoal>>>,
  createUnitOfMeasure?: GraphCacheOptimisticMutationResolver<MutationCreateUnitOfMeasureArgs, WithTypename<UnitOfMeasure>>,
  createUser?: GraphCacheOptimisticMutationResolver<MutationCreateUserArgs, Maybe<WithTypename<User>>>,
  createUserAssignment?: GraphCacheOptimisticMutationResolver<MutationCreateUserAssignmentArgs, Maybe<WithTypename<UserAssignment>>>,
  createUserBillingClassification?: GraphCacheOptimisticMutationResolver<MutationCreateUserBillingClassificationArgs, WithTypename<UserBillingClassification>>,
  createUserNotification?: GraphCacheOptimisticMutationResolver<MutationCreateUserNotificationArgs, Maybe<WithTypename<UserNotification>>>,
  createVendor?: GraphCacheOptimisticMutationResolver<MutationCreateVendorArgs, Maybe<WithTypename<Vendor>>>,
  createVendorContact?: GraphCacheOptimisticMutationResolver<MutationCreateVendorContactArgs, WithTypename<VendorContact>>,
  createWorkersCompCode?: GraphCacheOptimisticMutationResolver<MutationCreateWorkersCompCodeArgs, Maybe<WithTypename<WorkersCompCode>>>,
  deleteAssetBillingClassification?: GraphCacheOptimisticMutationResolver<MutationDeleteAssetBillingClassificationArgs, WithTypename<AssetBillingClassification>>,
  deleteCustomer?: GraphCacheOptimisticMutationResolver<MutationDeleteCustomerArgs, WithTypename<Customer>>,
  deleteDeliverableUnit?: GraphCacheOptimisticMutationResolver<MutationDeleteDeliverableUnitArgs, Maybe<WithTypename<DeliverableUnit>>>,
  deleteNonWorkDay?: GraphCacheOptimisticMutationResolver<MutationDeleteNonWorkDayArgs, WithTypename<Schedule>>,
  deleteOneAsset?: GraphCacheOptimisticMutationResolver<MutationDeleteOneAssetArgs, Maybe<WithTypename<Asset>>>,
  deleteOneContract?: GraphCacheOptimisticMutationResolver<MutationDeleteOneContractArgs, WithTypename<Contract>>,
  deleteOneFile?: GraphCacheOptimisticMutationResolver<MutationDeleteOneFileArgs, WithTypename<File>>,
  deleteOneTask?: GraphCacheOptimisticMutationResolver<MutationDeleteOneTaskArgs, Maybe<WithTypename<Task>>>,
  deleteOneTaskGroup?: GraphCacheOptimisticMutationResolver<MutationDeleteOneTaskGroupArgs, Maybe<WithTypename<TaskGroup>>>,
  deleteOneTimeEntry?: GraphCacheOptimisticMutationResolver<MutationDeleteOneTimeEntryArgs, WithTypename<TimeEntry>>,
  deleteOneUser?: GraphCacheOptimisticMutationResolver<MutationDeleteOneUserArgs, WithTypename<User>>,
  deleteProjectScheduleAndBreaks?: GraphCacheOptimisticMutationResolver<MutationDeleteProjectScheduleAndBreaksArgs, Scalars['String']>,
  deleteReportTemplate?: GraphCacheOptimisticMutationResolver<MutationDeleteReportTemplateArgs, WithTypename<AssetReportTemplate>>,
  deleteScheduledBreak?: GraphCacheOptimisticMutationResolver<MutationDeleteScheduledBreakArgs, WithTypename<ScheduledBreak>>,
  deleteTaskDependency?: GraphCacheOptimisticMutationResolver<MutationDeleteTaskDependencyArgs, WithTypename<TaskDependency>>,
  deleteTaskScheduleAndBreaks?: GraphCacheOptimisticMutationResolver<MutationDeleteTaskScheduleAndBreaksArgs, Scalars['String']>,
  deleteUnitGoal?: GraphCacheOptimisticMutationResolver<MutationDeleteUnitGoalArgs, Maybe<WithTypename<UnitGoal>>>,
  deleteUnitOfMeasure?: GraphCacheOptimisticMutationResolver<MutationDeleteUnitOfMeasureArgs, WithTypename<UnitOfMeasure>>,
  deleteUserAssignment?: GraphCacheOptimisticMutationResolver<MutationDeleteUserAssignmentArgs, WithTypename<UserAssignment>>,
  deleteUserBillingClassification?: GraphCacheOptimisticMutationResolver<MutationDeleteUserBillingClassificationArgs, WithTypename<UserBillingClassification>>,
  deleteUserDeviceSession?: GraphCacheOptimisticMutationResolver<MutationDeleteUserDeviceSessionArgs, Scalars['Boolean']>,
  deleteVendor?: GraphCacheOptimisticMutationResolver<MutationDeleteVendorArgs, WithTypename<Vendor>>,
  deleteVendorContact?: GraphCacheOptimisticMutationResolver<MutationDeleteVendorContactArgs, WithTypename<VendorContact>>,
  dismissAssetRepair?: GraphCacheOptimisticMutationResolver<MutationDismissAssetRepairArgs, WithTypename<AssetReport>>,
  duplicateReportTemplate?: GraphCacheOptimisticMutationResolver<MutationDuplicateReportTemplateArgs, WithTypename<AssetReportTemplate>>,
  duplicateSchedule?: GraphCacheOptimisticMutationResolver<MutationDuplicateScheduleArgs, WithTypename<Schedule>>,
  editDeliverableUnit?: GraphCacheOptimisticMutationResolver<MutationEditDeliverableUnitArgs, WithTypename<DeliverableUnit>>,
  editDivision?: GraphCacheOptimisticMutationResolver<MutationEditDivisionArgs, WithTypename<Division>>,
  editDivisionSettings?: GraphCacheOptimisticMutationResolver<MutationEditDivisionSettingsArgs, WithTypename<Settings>>,
  editOrganization?: GraphCacheOptimisticMutationResolver<MutationEditOrganizationArgs, WithTypename<Organization>>,
  editScheduledBreak?: GraphCacheOptimisticMutationResolver<MutationEditScheduledBreakArgs, WithTypename<ScheduledBreak>>,
  editUnitGoal?: GraphCacheOptimisticMutationResolver<MutationEditUnitGoalArgs, Maybe<WithTypename<UnitGoal>>>,
  editUnitOfMeasure?: GraphCacheOptimisticMutationResolver<MutationEditUnitOfMeasureArgs, WithTypename<UnitOfMeasure>>,
  endAllMasqueradeSessionsForUser?: GraphCacheOptimisticMutationResolver<MutationEndAllMasqueradeSessionsForUserArgs, Scalars['Boolean']>,
  grantDivisionAccessToUser?: GraphCacheOptimisticMutationResolver<MutationGrantDivisionAccessToUserArgs, WithTypename<User>>,
  insertManyAssetReports?: GraphCacheOptimisticMutationResolver<MutationInsertManyAssetReportsArgs, Array<WithTypename<AssetReport>>>,
  insertManyTasks?: GraphCacheOptimisticMutationResolver<MutationInsertManyTasksArgs, WithTypename<BulkTaskCreationResult>>,
  insertOneAsset?: GraphCacheOptimisticMutationResolver<MutationInsertOneAssetArgs, Maybe<WithTypename<Asset>>>,
  insertOneProject?: GraphCacheOptimisticMutationResolver<MutationInsertOneProjectArgs, WithTypename<Project>>,
  insertOneTask?: GraphCacheOptimisticMutationResolver<MutationInsertOneTaskArgs, WithTypename<Task>>,
  insertOneTaskGroup?: GraphCacheOptimisticMutationResolver<MutationInsertOneTaskGroupArgs, WithTypename<TaskGroup>>,
  insertOneTimeEntry?: GraphCacheOptimisticMutationResolver<MutationInsertOneTimeEntryArgs, WithTypename<TimeEntry>>,
  insertReportTemplate?: GraphCacheOptimisticMutationResolver<MutationInsertReportTemplateArgs, WithTypename<AssetReportTemplate>>,
  markAllNotificationsRead?: GraphCacheOptimisticMutationResolver<MutationMarkAllNotificationsReadArgs, Maybe<Array<WithTypename<UserNotification>>>>,
  markNotificationRead?: GraphCacheOptimisticMutationResolver<MutationMarkNotificationReadArgs, Maybe<WithTypename<UserNotification>>>,
  markNotificationsReadById?: GraphCacheOptimisticMutationResolver<MutationMarkNotificationsReadByIdArgs, Maybe<Array<WithTypename<UserNotification>>>>,
  markOrganizationNotPastDue?: GraphCacheOptimisticMutationResolver<MutationMarkOrganizationNotPastDueArgs, WithTypename<Organization>>,
  markOrganizationPastDue?: GraphCacheOptimisticMutationResolver<MutationMarkOrganizationPastDueArgs, WithTypename<Organization>>,
  markTaskCompletion?: GraphCacheOptimisticMutationResolver<MutationMarkTaskCompletionArgs, Maybe<WithTypename<Task>>>,
  masqueradeUser?: GraphCacheOptimisticMutationResolver<MutationMasqueradeUserArgs, WithTypename<Credentials>>,
  reassignUser?: GraphCacheOptimisticMutationResolver<MutationReassignUserArgs, WithTypename<User>>,
  reassignUsers?: GraphCacheOptimisticMutationResolver<MutationReassignUsersArgs, Array<WithTypename<User>>>,
  reportAssetRepair?: GraphCacheOptimisticMutationResolver<MutationReportAssetRepairArgs, WithTypename<AssetReport>>,
  reportTaskProgress?: GraphCacheOptimisticMutationResolver<MutationReportTaskProgressArgs, Maybe<WithTypename<Task>>>,
  resendUserInvites?: GraphCacheOptimisticMutationResolver<MutationResendUserInvitesArgs, Maybe<Scalars['Boolean']>>,
  resolveOfflineEvent?: GraphCacheOptimisticMutationResolver<MutationResolveOfflineEventArgs, WithTypename<OfflineEvent>>,
  restoreOneAsset?: GraphCacheOptimisticMutationResolver<MutationRestoreOneAssetArgs, Maybe<WithTypename<Asset>>>,
  restoreOneUser?: GraphCacheOptimisticMutationResolver<MutationRestoreOneUserArgs, WithTypename<User>>,
  returnQuantityToInventory?: GraphCacheOptimisticMutationResolver<MutationReturnQuantityToInventoryArgs, Array<WithTypename<Asset>>>,
  revokeDivisionAccessFromUser?: GraphCacheOptimisticMutationResolver<MutationRevokeDivisionAccessFromUserArgs, WithTypename<User>>,
  switchDivision?: GraphCacheOptimisticMutationResolver<MutationSwitchDivisionArgs, WithTypename<Credentials>>,
  switchDivisionAssignmentForUser?: GraphCacheOptimisticMutationResolver<MutationSwitchDivisionAssignmentForUserArgs, WithTypename<User>>,
  switchDivisionForAsset?: GraphCacheOptimisticMutationResolver<MutationSwitchDivisionForAssetArgs, WithTypename<Asset>>,
  toggleAssetReportTemplateState?: GraphCacheOptimisticMutationResolver<MutationToggleAssetReportTemplateStateArgs, WithTypename<AssetReportTemplate>>,
  transferAssets?: GraphCacheOptimisticMutationResolver<MutationTransferAssetsArgs, Array<WithTypename<Asset>>>,
  unarchiveQuantities?: GraphCacheOptimisticMutationResolver<MutationUnarchiveQuantitiesArgs, Array<WithTypename<Asset>>>,
  updateAssetBillingClassification?: GraphCacheOptimisticMutationResolver<MutationUpdateAssetBillingClassificationArgs, WithTypename<AssetBillingClassification>>,
  updateCustomer?: GraphCacheOptimisticMutationResolver<MutationUpdateCustomerArgs, Maybe<WithTypename<Customer>>>,
  updateLastExport?: GraphCacheOptimisticMutationResolver<MutationUpdateLastExportArgs, Maybe<WithTypename<LastExport>>>,
  updateOneAsset?: GraphCacheOptimisticMutationResolver<MutationUpdateOneAssetArgs, Maybe<WithTypename<Asset>>>,
  updateOneContract?: GraphCacheOptimisticMutationResolver<MutationUpdateOneContractArgs, WithTypename<Contract>>,
  updateOneFile?: GraphCacheOptimisticMutationResolver<MutationUpdateOneFileArgs, WithTypename<File>>,
  updateOneProject?: GraphCacheOptimisticMutationResolver<MutationUpdateOneProjectArgs, WithTypename<Project>>,
  updateOneTask?: GraphCacheOptimisticMutationResolver<MutationUpdateOneTaskArgs, WithTypename<Task>>,
  updateOneTaskGroup?: GraphCacheOptimisticMutationResolver<MutationUpdateOneTaskGroupArgs, WithTypename<TaskGroup>>,
  updateOneTimeEntry?: GraphCacheOptimisticMutationResolver<MutationUpdateOneTimeEntryArgs, WithTypename<TimeEntry>>,
  updateOneUser?: GraphCacheOptimisticMutationResolver<MutationUpdateOneUserArgs, WithTypename<User>>,
  updatePassword?: GraphCacheOptimisticMutationResolver<MutationUpdatePasswordArgs, Scalars['Boolean']>,
  updateReportTemplate?: GraphCacheOptimisticMutationResolver<MutationUpdateReportTemplateArgs, WithTypename<AssetReportTemplate>>,
  updateSchedule?: GraphCacheOptimisticMutationResolver<MutationUpdateScheduleArgs, WithTypename<Schedule>>,
  updateTaskDependency?: GraphCacheOptimisticMutationResolver<MutationUpdateTaskDependencyArgs, WithTypename<TaskDependency>>,
  updateTaskSortOrder?: GraphCacheOptimisticMutationResolver<MutationUpdateTaskSortOrderArgs, Maybe<WithTypename<UpdateSortOrderPayload>>>,
  updateUserAssignment?: GraphCacheOptimisticMutationResolver<MutationUpdateUserAssignmentArgs, Maybe<WithTypename<UserAssignment>>>,
  updateUserBillingClassification?: GraphCacheOptimisticMutationResolver<MutationUpdateUserBillingClassificationArgs, WithTypename<UserBillingClassification>>,
  updateVendor?: GraphCacheOptimisticMutationResolver<MutationUpdateVendorArgs, Maybe<WithTypename<Vendor>>>,
  updateVendorContact?: GraphCacheOptimisticMutationResolver<MutationUpdateVendorContactArgs, WithTypename<VendorContact>>,
  upsertDivisionDeliverableUnit?: GraphCacheOptimisticMutationResolver<MutationUpsertDivisionDeliverableUnitArgs, WithTypename<DivisionDeliverableUnit>>
};

export type GraphCacheUpdaters = {
  Query?: {
    activeProjectCount?: GraphCacheUpdateResolver<{ activeProjectCount: Scalars['Int'] }, Record<string, never>>,
    activeProjects?: GraphCacheUpdateResolver<{ activeProjects: Array<WithTypename<Project>> }, Record<string, never>>,
    activeProjectsPaginated?: GraphCacheUpdateResolver<{ activeProjectsPaginated: WithTypename<QueryActiveProjectsPaginatedConnection> }, QueryActiveProjectsPaginatedArgs>,
    archivedAssetGroups?: GraphCacheUpdateResolver<{ archivedAssetGroups: Array<WithTypename<AssetGroup>> }, QueryArchivedAssetGroupsArgs>,
    archivedAssets?: GraphCacheUpdateResolver<{ archivedAssets: WithTypename<QueryArchivedAssetsConnection> }, QueryArchivedAssetsArgs>,
    asset?: GraphCacheUpdateResolver<{ asset: WithTypename<Asset> }, QueryAssetArgs>,
    assetBillingClassification?: GraphCacheUpdateResolver<{ assetBillingClassification: Maybe<WithTypename<AssetBillingClassification>> }, QueryAssetBillingClassificationArgs>,
    assetBillingClassifications?: GraphCacheUpdateResolver<{ assetBillingClassifications: Array<WithTypename<AssetBillingClassification>> }, Record<string, never>>,
    assetGroups?: GraphCacheUpdateResolver<{ assetGroups: Array<WithTypename<AssetGroup>> }, QueryAssetGroupsArgs>,
    assetRepairRequest?: GraphCacheUpdateResolver<{ assetRepairRequest: WithTypename<AssetRepairRequest> }, QueryAssetRepairRequestArgs>,
    assetRepairRequests?: GraphCacheUpdateResolver<{ assetRepairRequests: Array<WithTypename<AssetRepairRequest>> }, QueryAssetRepairRequestsArgs>,
    assetReport?: GraphCacheUpdateResolver<{ assetReport: WithTypename<AssetReport> }, QueryAssetReportArgs>,
    assetReportTemplate?: GraphCacheUpdateResolver<{ assetReportTemplate: WithTypename<AssetReportTemplate> }, QueryAssetReportTemplateArgs>,
    assetReports?: GraphCacheUpdateResolver<{ assetReports: Array<WithTypename<AssetReport>> }, QueryAssetReportsArgs>,
    assets?: GraphCacheUpdateResolver<{ assets: Array<WithTypename<Asset>> }, QueryAssetsArgs>,
    assets_2?: GraphCacheUpdateResolver<{ assets_2: WithTypename<QueryAssets_2Connection> }, QueryAssets_2Args>,
    assignedAssets?: GraphCacheUpdateResolver<{ assignedAssets: Array<WithTypename<AssignedAsset>> }, QueryAssignedAssetsArgs>,
    contract?: GraphCacheUpdateResolver<{ contract: WithTypename<Contract> }, QueryContractArgs>,
    contracts?: GraphCacheUpdateResolver<{ contracts: Array<WithTypename<Contract>> }, QueryContractsArgs>,
    currentUser?: GraphCacheUpdateResolver<{ currentUser: WithTypename<User> }, Record<string, never>>,
    customer?: GraphCacheUpdateResolver<{ customer: WithTypename<Customer> }, QueryCustomerArgs>,
    customers?: GraphCacheUpdateResolver<{ customers: Array<WithTypename<Customer>> }, Record<string, never>>,
    deliverableUnit?: GraphCacheUpdateResolver<{ deliverableUnit: WithTypename<DeliverableUnit> }, QueryDeliverableUnitArgs>,
    deliverableUnits?: GraphCacheUpdateResolver<{ deliverableUnits: Array<WithTypename<DeliverableUnit>> }, QueryDeliverableUnitsArgs>,
    dependentTaskDependencies?: GraphCacheUpdateResolver<{ dependentTaskDependencies: Array<WithTypename<TaskDependency>> }, QueryDependentTaskDependenciesArgs>,
    dependentTasks?: GraphCacheUpdateResolver<{ dependentTasks: Array<WithTypename<Task>> }, QueryDependentTasksArgs>,
    division?: GraphCacheUpdateResolver<{ division: WithTypename<Division> }, QueryDivisionArgs>,
    divisions?: GraphCacheUpdateResolver<{ divisions: Array<WithTypename<Division>> }, QueryDivisionsArgs>,
    editHistoryEvent?: GraphCacheUpdateResolver<{ editHistoryEvent: WithTypename<EditHistoryEvent> }, QueryEditHistoryEventArgs>,
    editHistoryEvents?: GraphCacheUpdateResolver<{ editHistoryEvents: Array<WithTypename<EditHistoryEvent>> }, QueryEditHistoryEventsArgs>,
    getJobTitles?: GraphCacheUpdateResolver<{ getJobTitles: Array<Scalars['String']> }, Record<string, never>>,
    myDivision?: GraphCacheUpdateResolver<{ myDivision: WithTypename<Division> }, Record<string, never>>,
    myDivisions?: GraphCacheUpdateResolver<{ myDivisions: Maybe<Array<WithTypename<Division>>> }, Record<string, never>>,
    myNotifications?: GraphCacheUpdateResolver<{ myNotifications: Array<WithTypename<UserNotification>> }, QueryMyNotificationsArgs>,
    myOrganization?: GraphCacheUpdateResolver<{ myOrganization: WithTypename<Organization> }, Record<string, never>>,
    nestedAssets?: GraphCacheUpdateResolver<{ nestedAssets: Array<WithTypename<Asset>> }, QueryNestedAssetsArgs>,
    nestedAssetsForInventory?: GraphCacheUpdateResolver<{ nestedAssetsForInventory: Array<WithTypename<AssignedAsset>> }, QueryNestedAssetsForInventoryArgs>,
    offlineEvent?: GraphCacheUpdateResolver<{ offlineEvent: WithTypename<OfflineEvent> }, QueryOfflineEventArgs>,
    offlineEvents?: GraphCacheUpdateResolver<{ offlineEvents: Array<WithTypename<OfflineEvent>> }, QueryOfflineEventsArgs>,
    organization?: GraphCacheUpdateResolver<{ organization: WithTypename<Organization> }, QueryOrganizationArgs>,
    organizations?: GraphCacheUpdateResolver<{ organizations: Array<WithTypename<Organization>> }, QueryOrganizationsArgs>,
    payrollExportRows?: GraphCacheUpdateResolver<{ payrollExportRows: Array<WithTypename<PayrollExportRow>> }, QueryPayrollExportRowsArgs>,
    predecessorTaskDependencies?: GraphCacheUpdateResolver<{ predecessorTaskDependencies: Array<WithTypename<TaskDependency>> }, QueryPredecessorTaskDependenciesArgs>,
    predecessorTasks?: GraphCacheUpdateResolver<{ predecessorTasks: Array<WithTypename<Task>> }, QueryPredecessorTasksArgs>,
    project?: GraphCacheUpdateResolver<{ project: Maybe<WithTypename<Project>> }, QueryProjectArgs>,
    projects?: GraphCacheUpdateResolver<{ projects: Array<WithTypename<Project>> }, QueryProjectsArgs>,
    projectsByStatus?: GraphCacheUpdateResolver<{ projectsByStatus: Array<WithTypename<Project>> }, QueryProjectsByStatusArgs>,
    projectsPaginated?: GraphCacheUpdateResolver<{ projectsPaginated: WithTypename<QueryProjectsPaginatedConnection> }, QueryProjectsPaginatedArgs>,
    reusableAssetReportTemplates?: GraphCacheUpdateResolver<{ reusableAssetReportTemplates: Array<WithTypename<AssetReportTemplate>> }, QueryReusableAssetReportTemplatesArgs>,
    roles?: GraphCacheUpdateResolver<{ roles: Array<WithTypename<Role>> }, Record<string, never>>,
    scheduledBreaks?: GraphCacheUpdateResolver<{ scheduledBreaks: Array<WithTypename<ScheduledBreak>> }, QueryScheduledBreaksArgs>,
    schedules?: GraphCacheUpdateResolver<{ schedules: Array<WithTypename<Schedule>> }, QuerySchedulesArgs>,
    settings?: GraphCacheUpdateResolver<{ settings: WithTypename<Settings> }, Record<string, never>>,
    task?: GraphCacheUpdateResolver<{ task: WithTypename<Task> }, QueryTaskArgs>,
    taskDependency?: GraphCacheUpdateResolver<{ taskDependency: Maybe<WithTypename<TaskDependency>> }, QueryTaskDependencyArgs>,
    taskGroup?: GraphCacheUpdateResolver<{ taskGroup: Maybe<WithTypename<TaskGroup>> }, QueryTaskGroupArgs>,
    taskGroups?: GraphCacheUpdateResolver<{ taskGroups: Array<WithTypename<TaskGroup>> }, QueryTaskGroupsArgs>,
    taskList?: GraphCacheUpdateResolver<{ taskList: Array<WithTypename<TaskListItem>> }, QueryTaskListArgs>,
    taskProgressEvent?: GraphCacheUpdateResolver<{ taskProgressEvent: WithTypename<TaskProgressEvent> }, QueryTaskProgressEventArgs>,
    taskProgressEvents?: GraphCacheUpdateResolver<{ taskProgressEvents: Array<WithTypename<TaskProgressEvent>> }, QueryTaskProgressEventsArgs>,
    tasks?: GraphCacheUpdateResolver<{ tasks: Array<WithTypename<Task>> }, QueryTasksArgs>,
    tasksForGroupId?: GraphCacheUpdateResolver<{ tasksForGroupId: Array<WithTypename<Task>> }, QueryTasksForGroupIdArgs>,
    tasksPaginated?: GraphCacheUpdateResolver<{ tasksPaginated: WithTypename<QueryTasksPaginatedConnection> }, QueryTasksPaginatedArgs>,
    timeCardWeek?: GraphCacheUpdateResolver<{ timeCardWeek: WithTypename<TimeCardWeek> }, QueryTimeCardWeekArgs>,
    timeEntryDay?: GraphCacheUpdateResolver<{ timeEntryDay: WithTypename<TimeEntryDay> }, QueryTimeEntryDayArgs>,
    timeEntryDays?: GraphCacheUpdateResolver<{ timeEntryDays: WithTypename<TimeEntryDayRange> }, QueryTimeEntryDaysArgs>,
    unitGoal?: GraphCacheUpdateResolver<{ unitGoal: Maybe<WithTypename<UnitGoal>> }, QueryUnitGoalArgs>,
    unitGoals?: GraphCacheUpdateResolver<{ unitGoals: Array<WithTypename<UnitGoal>> }, QueryUnitGoalsArgs>,
    user?: GraphCacheUpdateResolver<{ user: WithTypename<User> }, QueryUserArgs>,
    userAssignments?: GraphCacheUpdateResolver<{ userAssignments: Array<WithTypename<UserAssignment>> }, QueryUserAssignmentsArgs>,
    userBillingClassification?: GraphCacheUpdateResolver<{ userBillingClassification: Maybe<WithTypename<UserBillingClassification>> }, QueryUserBillingClassificationArgs>,
    userBillingClassifications?: GraphCacheUpdateResolver<{ userBillingClassifications: Array<WithTypename<UserBillingClassification>> }, Record<string, never>>,
    users?: GraphCacheUpdateResolver<{ users: WithTypename<QueryUsersConnection> }, QueryUsersArgs>,
    usersForSummarySection?: GraphCacheUpdateResolver<{ usersForSummarySection: Array<WithTypename<User>> }, QueryUsersForSummarySectionArgs>,
    usersList?: GraphCacheUpdateResolver<{ usersList: Array<WithTypename<User>> }, QueryUsersListArgs>,
    vendor?: GraphCacheUpdateResolver<{ vendor: WithTypename<Vendor> }, QueryVendorArgs>,
    vendorContact?: GraphCacheUpdateResolver<{ vendorContact: WithTypename<VendorContact> }, QueryVendorContactArgs>,
    vendorContacts?: GraphCacheUpdateResolver<{ vendorContacts: Array<WithTypename<VendorContact>> }, QueryVendorContactsArgs>,
    vendors?: GraphCacheUpdateResolver<{ vendors: Array<WithTypename<Vendor>> }, Record<string, never>>,
    workersCompCodes?: GraphCacheUpdateResolver<{ workersCompCodes: Array<WithTypename<WorkersCompCode>> }, Record<string, never>>
  },
  Mutation?: {
    activateOrganization?: GraphCacheUpdateResolver<{ activateOrganization: WithTypename<Organization> }, MutationActivateOrganizationArgs>,
    activateProject?: GraphCacheUpdateResolver<{ activateProject: WithTypename<Project> }, MutationActivateProjectArgs>,
    addOrUpdateNonWorkDay?: GraphCacheUpdateResolver<{ addOrUpdateNonWorkDay: WithTypename<Schedule> }, MutationAddOrUpdateNonWorkDayArgs>,
    addQuantitiesToGroup?: GraphCacheUpdateResolver<{ addQuantitiesToGroup: Maybe<WithTypename<Asset>> }, MutationAddQuantitiesToGroupArgs>,
    archiveOrganization?: GraphCacheUpdateResolver<{ archiveOrganization: WithTypename<Organization> }, MutationArchiveOrganizationArgs>,
    archiveProject?: GraphCacheUpdateResolver<{ archiveProject: WithTypename<Project> }, MutationArchiveProjectArgs>,
    archiveQuantities?: GraphCacheUpdateResolver<{ archiveQuantities: Array<WithTypename<Asset>> }, MutationArchiveQuantitiesArgs>,
    archiveWorkersCompCode?: GraphCacheUpdateResolver<{ archiveWorkersCompCode: Maybe<WithTypename<WorkersCompCode>> }, MutationArchiveWorkersCompCodeArgs>,
    bulkClockIn?: GraphCacheUpdateResolver<{ bulkClockIn: WithTypename<BulkTimeEntryResponse> }, MutationBulkClockInArgs>,
    bulkClockOut?: GraphCacheUpdateResolver<{ bulkClockOut: WithTypename<BulkTimeEntryResponse> }, MutationBulkClockOutArgs>,
    bulkDeleteAssets?: GraphCacheUpdateResolver<{ bulkDeleteAssets: Maybe<Array<WithTypename<Asset>>> }, MutationBulkDeleteAssetsArgs>,
    bulkUpdateTaskSortOrder?: GraphCacheUpdateResolver<{ bulkUpdateTaskSortOrder: Array<WithTypename<UpdateSortOrderPayload>> }, MutationBulkUpdateTaskSortOrderArgs>,
    bulkUpdateUserAssignments?: GraphCacheUpdateResolver<{ bulkUpdateUserAssignments: Maybe<WithTypename<UserAssignmentBatchResponse>> }, MutationBulkUpdateUserAssignmentsArgs>,
    clockIn?: GraphCacheUpdateResolver<{ clockIn: WithTypename<TimeEntry> }, MutationClockInArgs>,
    clockOutUser?: GraphCacheUpdateResolver<{ clockOutUser: WithTypename<TimeEntry> }, MutationClockOutUserArgs>,
    completeProject?: GraphCacheUpdateResolver<{ completeProject: WithTypename<Project> }, MutationCompleteProjectArgs>,
    createAssetBillingClassification?: GraphCacheUpdateResolver<{ createAssetBillingClassification: WithTypename<AssetBillingClassification> }, MutationCreateAssetBillingClassificationArgs>,
    createCustomer?: GraphCacheUpdateResolver<{ createCustomer: Maybe<WithTypename<Customer>> }, MutationCreateCustomerArgs>,
    createDeliverableUnit?: GraphCacheUpdateResolver<{ createDeliverableUnit: WithTypename<DeliverableUnit> }, MutationCreateDeliverableUnitArgs>,
    createDivision?: GraphCacheUpdateResolver<{ createDivision: WithTypename<Division> }, MutationCreateDivisionArgs>,
    createInjuryReport?: GraphCacheUpdateResolver<{ createInjuryReport: WithTypename<InjuryReport> }, MutationCreateInjuryReportArgs>,
    createManyTaskReports?: GraphCacheUpdateResolver<{ createManyTaskReports: Maybe<Array<WithTypename<Task>>> }, MutationCreateManyTaskReportsArgs>,
    createOfflineEvent?: GraphCacheUpdateResolver<{ createOfflineEvent: WithTypename<OfflineEvent> }, MutationCreateOfflineEventArgs>,
    createOfflineEvents?: GraphCacheUpdateResolver<{ createOfflineEvents: WithTypename<CreateManyOfflineEventsResponsePayload> }, MutationCreateOfflineEventsArgs>,
    createOneContract?: GraphCacheUpdateResolver<{ createOneContract: WithTypename<Contract> }, MutationCreateOneContractArgs>,
    createSchedule?: GraphCacheUpdateResolver<{ createSchedule: WithTypename<Schedule> }, MutationCreateScheduleArgs>,
    createTaskDependency?: GraphCacheUpdateResolver<{ createTaskDependency: WithTypename<TaskDependency> }, MutationCreateTaskDependencyArgs>,
    createUnitGoal?: GraphCacheUpdateResolver<{ createUnitGoal: Maybe<WithTypename<UnitGoal>> }, MutationCreateUnitGoalArgs>,
    createUnitOfMeasure?: GraphCacheUpdateResolver<{ createUnitOfMeasure: WithTypename<UnitOfMeasure> }, MutationCreateUnitOfMeasureArgs>,
    createUser?: GraphCacheUpdateResolver<{ createUser: Maybe<WithTypename<User>> }, MutationCreateUserArgs>,
    createUserAssignment?: GraphCacheUpdateResolver<{ createUserAssignment: Maybe<WithTypename<UserAssignment>> }, MutationCreateUserAssignmentArgs>,
    createUserBillingClassification?: GraphCacheUpdateResolver<{ createUserBillingClassification: WithTypename<UserBillingClassification> }, MutationCreateUserBillingClassificationArgs>,
    createUserNotification?: GraphCacheUpdateResolver<{ createUserNotification: Maybe<WithTypename<UserNotification>> }, MutationCreateUserNotificationArgs>,
    createVendor?: GraphCacheUpdateResolver<{ createVendor: Maybe<WithTypename<Vendor>> }, MutationCreateVendorArgs>,
    createVendorContact?: GraphCacheUpdateResolver<{ createVendorContact: WithTypename<VendorContact> }, MutationCreateVendorContactArgs>,
    createWorkersCompCode?: GraphCacheUpdateResolver<{ createWorkersCompCode: Maybe<WithTypename<WorkersCompCode>> }, MutationCreateWorkersCompCodeArgs>,
    deleteAssetBillingClassification?: GraphCacheUpdateResolver<{ deleteAssetBillingClassification: WithTypename<AssetBillingClassification> }, MutationDeleteAssetBillingClassificationArgs>,
    deleteCustomer?: GraphCacheUpdateResolver<{ deleteCustomer: WithTypename<Customer> }, MutationDeleteCustomerArgs>,
    deleteDeliverableUnit?: GraphCacheUpdateResolver<{ deleteDeliverableUnit: Maybe<WithTypename<DeliverableUnit>> }, MutationDeleteDeliverableUnitArgs>,
    deleteNonWorkDay?: GraphCacheUpdateResolver<{ deleteNonWorkDay: WithTypename<Schedule> }, MutationDeleteNonWorkDayArgs>,
    deleteOneAsset?: GraphCacheUpdateResolver<{ deleteOneAsset: Maybe<WithTypename<Asset>> }, MutationDeleteOneAssetArgs>,
    deleteOneContract?: GraphCacheUpdateResolver<{ deleteOneContract: WithTypename<Contract> }, MutationDeleteOneContractArgs>,
    deleteOneFile?: GraphCacheUpdateResolver<{ deleteOneFile: WithTypename<File> }, MutationDeleteOneFileArgs>,
    deleteOneTask?: GraphCacheUpdateResolver<{ deleteOneTask: Maybe<WithTypename<Task>> }, MutationDeleteOneTaskArgs>,
    deleteOneTaskGroup?: GraphCacheUpdateResolver<{ deleteOneTaskGroup: Maybe<WithTypename<TaskGroup>> }, MutationDeleteOneTaskGroupArgs>,
    deleteOneTimeEntry?: GraphCacheUpdateResolver<{ deleteOneTimeEntry: WithTypename<TimeEntry> }, MutationDeleteOneTimeEntryArgs>,
    deleteOneUser?: GraphCacheUpdateResolver<{ deleteOneUser: WithTypename<User> }, MutationDeleteOneUserArgs>,
    deleteProjectScheduleAndBreaks?: GraphCacheUpdateResolver<{ deleteProjectScheduleAndBreaks: Scalars['String'] }, MutationDeleteProjectScheduleAndBreaksArgs>,
    deleteReportTemplate?: GraphCacheUpdateResolver<{ deleteReportTemplate: WithTypename<AssetReportTemplate> }, MutationDeleteReportTemplateArgs>,
    deleteScheduledBreak?: GraphCacheUpdateResolver<{ deleteScheduledBreak: WithTypename<ScheduledBreak> }, MutationDeleteScheduledBreakArgs>,
    deleteTaskDependency?: GraphCacheUpdateResolver<{ deleteTaskDependency: WithTypename<TaskDependency> }, MutationDeleteTaskDependencyArgs>,
    deleteTaskScheduleAndBreaks?: GraphCacheUpdateResolver<{ deleteTaskScheduleAndBreaks: Scalars['String'] }, MutationDeleteTaskScheduleAndBreaksArgs>,
    deleteUnitGoal?: GraphCacheUpdateResolver<{ deleteUnitGoal: Maybe<WithTypename<UnitGoal>> }, MutationDeleteUnitGoalArgs>,
    deleteUnitOfMeasure?: GraphCacheUpdateResolver<{ deleteUnitOfMeasure: WithTypename<UnitOfMeasure> }, MutationDeleteUnitOfMeasureArgs>,
    deleteUserAssignment?: GraphCacheUpdateResolver<{ deleteUserAssignment: WithTypename<UserAssignment> }, MutationDeleteUserAssignmentArgs>,
    deleteUserBillingClassification?: GraphCacheUpdateResolver<{ deleteUserBillingClassification: WithTypename<UserBillingClassification> }, MutationDeleteUserBillingClassificationArgs>,
    deleteUserDeviceSession?: GraphCacheUpdateResolver<{ deleteUserDeviceSession: Scalars['Boolean'] }, MutationDeleteUserDeviceSessionArgs>,
    deleteVendor?: GraphCacheUpdateResolver<{ deleteVendor: WithTypename<Vendor> }, MutationDeleteVendorArgs>,
    deleteVendorContact?: GraphCacheUpdateResolver<{ deleteVendorContact: WithTypename<VendorContact> }, MutationDeleteVendorContactArgs>,
    dismissAssetRepair?: GraphCacheUpdateResolver<{ dismissAssetRepair: WithTypename<AssetReport> }, MutationDismissAssetRepairArgs>,
    duplicateReportTemplate?: GraphCacheUpdateResolver<{ duplicateReportTemplate: WithTypename<AssetReportTemplate> }, MutationDuplicateReportTemplateArgs>,
    duplicateSchedule?: GraphCacheUpdateResolver<{ duplicateSchedule: WithTypename<Schedule> }, MutationDuplicateScheduleArgs>,
    editDeliverableUnit?: GraphCacheUpdateResolver<{ editDeliverableUnit: WithTypename<DeliverableUnit> }, MutationEditDeliverableUnitArgs>,
    editDivision?: GraphCacheUpdateResolver<{ editDivision: WithTypename<Division> }, MutationEditDivisionArgs>,
    editDivisionSettings?: GraphCacheUpdateResolver<{ editDivisionSettings: WithTypename<Settings> }, MutationEditDivisionSettingsArgs>,
    editOrganization?: GraphCacheUpdateResolver<{ editOrganization: WithTypename<Organization> }, MutationEditOrganizationArgs>,
    editScheduledBreak?: GraphCacheUpdateResolver<{ editScheduledBreak: WithTypename<ScheduledBreak> }, MutationEditScheduledBreakArgs>,
    editUnitGoal?: GraphCacheUpdateResolver<{ editUnitGoal: Maybe<WithTypename<UnitGoal>> }, MutationEditUnitGoalArgs>,
    editUnitOfMeasure?: GraphCacheUpdateResolver<{ editUnitOfMeasure: WithTypename<UnitOfMeasure> }, MutationEditUnitOfMeasureArgs>,
    endAllMasqueradeSessionsForUser?: GraphCacheUpdateResolver<{ endAllMasqueradeSessionsForUser: Scalars['Boolean'] }, MutationEndAllMasqueradeSessionsForUserArgs>,
    grantDivisionAccessToUser?: GraphCacheUpdateResolver<{ grantDivisionAccessToUser: WithTypename<User> }, MutationGrantDivisionAccessToUserArgs>,
    insertManyAssetReports?: GraphCacheUpdateResolver<{ insertManyAssetReports: Array<WithTypename<AssetReport>> }, MutationInsertManyAssetReportsArgs>,
    insertManyTasks?: GraphCacheUpdateResolver<{ insertManyTasks: WithTypename<BulkTaskCreationResult> }, MutationInsertManyTasksArgs>,
    insertOneAsset?: GraphCacheUpdateResolver<{ insertOneAsset: Maybe<WithTypename<Asset>> }, MutationInsertOneAssetArgs>,
    insertOneProject?: GraphCacheUpdateResolver<{ insertOneProject: WithTypename<Project> }, MutationInsertOneProjectArgs>,
    insertOneTask?: GraphCacheUpdateResolver<{ insertOneTask: WithTypename<Task> }, MutationInsertOneTaskArgs>,
    insertOneTaskGroup?: GraphCacheUpdateResolver<{ insertOneTaskGroup: WithTypename<TaskGroup> }, MutationInsertOneTaskGroupArgs>,
    insertOneTimeEntry?: GraphCacheUpdateResolver<{ insertOneTimeEntry: WithTypename<TimeEntry> }, MutationInsertOneTimeEntryArgs>,
    insertReportTemplate?: GraphCacheUpdateResolver<{ insertReportTemplate: WithTypename<AssetReportTemplate> }, MutationInsertReportTemplateArgs>,
    markAllNotificationsRead?: GraphCacheUpdateResolver<{ markAllNotificationsRead: Maybe<Array<WithTypename<UserNotification>>> }, MutationMarkAllNotificationsReadArgs>,
    markNotificationRead?: GraphCacheUpdateResolver<{ markNotificationRead: Maybe<WithTypename<UserNotification>> }, MutationMarkNotificationReadArgs>,
    markNotificationsReadById?: GraphCacheUpdateResolver<{ markNotificationsReadById: Maybe<Array<WithTypename<UserNotification>>> }, MutationMarkNotificationsReadByIdArgs>,
    markOrganizationNotPastDue?: GraphCacheUpdateResolver<{ markOrganizationNotPastDue: WithTypename<Organization> }, MutationMarkOrganizationNotPastDueArgs>,
    markOrganizationPastDue?: GraphCacheUpdateResolver<{ markOrganizationPastDue: WithTypename<Organization> }, MutationMarkOrganizationPastDueArgs>,
    markTaskCompletion?: GraphCacheUpdateResolver<{ markTaskCompletion: Maybe<WithTypename<Task>> }, MutationMarkTaskCompletionArgs>,
    masqueradeUser?: GraphCacheUpdateResolver<{ masqueradeUser: WithTypename<Credentials> }, MutationMasqueradeUserArgs>,
    reassignUser?: GraphCacheUpdateResolver<{ reassignUser: WithTypename<User> }, MutationReassignUserArgs>,
    reassignUsers?: GraphCacheUpdateResolver<{ reassignUsers: Array<WithTypename<User>> }, MutationReassignUsersArgs>,
    reportAssetRepair?: GraphCacheUpdateResolver<{ reportAssetRepair: WithTypename<AssetReport> }, MutationReportAssetRepairArgs>,
    reportTaskProgress?: GraphCacheUpdateResolver<{ reportTaskProgress: Maybe<WithTypename<Task>> }, MutationReportTaskProgressArgs>,
    resendUserInvites?: GraphCacheUpdateResolver<{ resendUserInvites: Maybe<Scalars['Boolean']> }, MutationResendUserInvitesArgs>,
    resolveOfflineEvent?: GraphCacheUpdateResolver<{ resolveOfflineEvent: WithTypename<OfflineEvent> }, MutationResolveOfflineEventArgs>,
    restoreOneAsset?: GraphCacheUpdateResolver<{ restoreOneAsset: Maybe<WithTypename<Asset>> }, MutationRestoreOneAssetArgs>,
    restoreOneUser?: GraphCacheUpdateResolver<{ restoreOneUser: WithTypename<User> }, MutationRestoreOneUserArgs>,
    returnQuantityToInventory?: GraphCacheUpdateResolver<{ returnQuantityToInventory: Array<WithTypename<Asset>> }, MutationReturnQuantityToInventoryArgs>,
    revokeDivisionAccessFromUser?: GraphCacheUpdateResolver<{ revokeDivisionAccessFromUser: WithTypename<User> }, MutationRevokeDivisionAccessFromUserArgs>,
    switchDivision?: GraphCacheUpdateResolver<{ switchDivision: WithTypename<Credentials> }, MutationSwitchDivisionArgs>,
    switchDivisionAssignmentForUser?: GraphCacheUpdateResolver<{ switchDivisionAssignmentForUser: WithTypename<User> }, MutationSwitchDivisionAssignmentForUserArgs>,
    switchDivisionForAsset?: GraphCacheUpdateResolver<{ switchDivisionForAsset: WithTypename<Asset> }, MutationSwitchDivisionForAssetArgs>,
    toggleAssetReportTemplateState?: GraphCacheUpdateResolver<{ toggleAssetReportTemplateState: WithTypename<AssetReportTemplate> }, MutationToggleAssetReportTemplateStateArgs>,
    transferAssets?: GraphCacheUpdateResolver<{ transferAssets: Array<WithTypename<Asset>> }, MutationTransferAssetsArgs>,
    unarchiveQuantities?: GraphCacheUpdateResolver<{ unarchiveQuantities: Array<WithTypename<Asset>> }, MutationUnarchiveQuantitiesArgs>,
    updateAssetBillingClassification?: GraphCacheUpdateResolver<{ updateAssetBillingClassification: WithTypename<AssetBillingClassification> }, MutationUpdateAssetBillingClassificationArgs>,
    updateCustomer?: GraphCacheUpdateResolver<{ updateCustomer: Maybe<WithTypename<Customer>> }, MutationUpdateCustomerArgs>,
    updateLastExport?: GraphCacheUpdateResolver<{ updateLastExport: Maybe<WithTypename<LastExport>> }, MutationUpdateLastExportArgs>,
    updateOneAsset?: GraphCacheUpdateResolver<{ updateOneAsset: Maybe<WithTypename<Asset>> }, MutationUpdateOneAssetArgs>,
    updateOneContract?: GraphCacheUpdateResolver<{ updateOneContract: WithTypename<Contract> }, MutationUpdateOneContractArgs>,
    updateOneFile?: GraphCacheUpdateResolver<{ updateOneFile: WithTypename<File> }, MutationUpdateOneFileArgs>,
    updateOneProject?: GraphCacheUpdateResolver<{ updateOneProject: WithTypename<Project> }, MutationUpdateOneProjectArgs>,
    updateOneTask?: GraphCacheUpdateResolver<{ updateOneTask: WithTypename<Task> }, MutationUpdateOneTaskArgs>,
    updateOneTaskGroup?: GraphCacheUpdateResolver<{ updateOneTaskGroup: WithTypename<TaskGroup> }, MutationUpdateOneTaskGroupArgs>,
    updateOneTimeEntry?: GraphCacheUpdateResolver<{ updateOneTimeEntry: WithTypename<TimeEntry> }, MutationUpdateOneTimeEntryArgs>,
    updateOneUser?: GraphCacheUpdateResolver<{ updateOneUser: WithTypename<User> }, MutationUpdateOneUserArgs>,
    updatePassword?: GraphCacheUpdateResolver<{ updatePassword: Scalars['Boolean'] }, MutationUpdatePasswordArgs>,
    updateReportTemplate?: GraphCacheUpdateResolver<{ updateReportTemplate: WithTypename<AssetReportTemplate> }, MutationUpdateReportTemplateArgs>,
    updateSchedule?: GraphCacheUpdateResolver<{ updateSchedule: WithTypename<Schedule> }, MutationUpdateScheduleArgs>,
    updateTaskDependency?: GraphCacheUpdateResolver<{ updateTaskDependency: WithTypename<TaskDependency> }, MutationUpdateTaskDependencyArgs>,
    updateTaskSortOrder?: GraphCacheUpdateResolver<{ updateTaskSortOrder: Maybe<WithTypename<UpdateSortOrderPayload>> }, MutationUpdateTaskSortOrderArgs>,
    updateUserAssignment?: GraphCacheUpdateResolver<{ updateUserAssignment: Maybe<WithTypename<UserAssignment>> }, MutationUpdateUserAssignmentArgs>,
    updateUserBillingClassification?: GraphCacheUpdateResolver<{ updateUserBillingClassification: WithTypename<UserBillingClassification> }, MutationUpdateUserBillingClassificationArgs>,
    updateVendor?: GraphCacheUpdateResolver<{ updateVendor: Maybe<WithTypename<Vendor>> }, MutationUpdateVendorArgs>,
    updateVendorContact?: GraphCacheUpdateResolver<{ updateVendorContact: WithTypename<VendorContact> }, MutationUpdateVendorContactArgs>,
    upsertDivisionDeliverableUnit?: GraphCacheUpdateResolver<{ upsertDivisionDeliverableUnit: WithTypename<DivisionDeliverableUnit> }, MutationUpsertDivisionDeliverableUnitArgs>
  },
  Subscription?: {},
  Address?: {
    addressComponents?: GraphCacheUpdateResolver<Maybe<WithTypename<Address>>, Record<string, never>>,
    formattedAddress?: GraphCacheUpdateResolver<Maybe<WithTypename<Address>>, Record<string, never>>
  },
  AddressComponent?: {
    longName?: GraphCacheUpdateResolver<Maybe<WithTypename<AddressComponent>>, Record<string, never>>,
    shortName?: GraphCacheUpdateResolver<Maybe<WithTypename<AddressComponent>>, Record<string, never>>,
    types?: GraphCacheUpdateResolver<Maybe<WithTypename<AddressComponent>>, Record<string, never>>
  },
  Asset?: {
    active?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    activeChangedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assetChildCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assetGroupId?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assetGroupMemberCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assignableId?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assignableType?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assignedAsset?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assignedAssetId?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assignedTask?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assignedTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assignedUser?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assignedUserId?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    assignmentsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    billingClassification?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    billingClassificationId?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    category?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    childAssetGroups?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    childAssets?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    companyAssetNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    compositeKey?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    deletedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    division?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    divisionId?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    files?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    groupQuantity?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    inferredOwner?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    inferredProjectId?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    inspectionTemplatesAssignments?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    inventoryRequirements?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    isArchived?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    isAssetGroup?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    lastInventoriedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    manufacturer?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    organization?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    ownershipType?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    photo?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    photoId?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    purchaseDetails?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    rentalAgreement?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    repairRequestCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    reportCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    reportTemplates?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    reports?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>,
    vendorContact?: GraphCacheUpdateResolver<Maybe<WithTypename<Asset>>, Record<string, never>>
  },
  AssetBillingClassification?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetBillingClassification>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetBillingClassification>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetBillingClassification>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetBillingClassification>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetBillingClassification>>, Record<string, never>>
  },
  AssetGroup?: {
    assetGroupId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    assignableId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    assignableType?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    assignedAsset?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    assignedAssetId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    assignedTask?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    assignedTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    assignedUser?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    assignedUserId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    compositeKey?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    count?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    groupParent?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetGroup>>, Record<string, never>>
  },
  AssetInspectionRequirements?: {
    intervalInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionRequirements>>, Record<string, never>>,
    startDate?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionRequirements>>, Record<string, never>>
  },
  AssetInspectionTemplateAssignments?: {
    asset?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionTemplateAssignments>>, Record<string, never>>,
    assetId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionTemplateAssignments>>, Record<string, never>>,
    assetReportTemplateId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionTemplateAssignments>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionTemplateAssignments>>, Record<string, never>>,
    inspectionTemplate?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionTemplateAssignments>>, Record<string, never>>,
    intervalInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionTemplateAssignments>>, Record<string, never>>,
    intervalRequired?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionTemplateAssignments>>, Record<string, never>>,
    lastReportedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionTemplateAssignments>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionTemplateAssignments>>, Record<string, never>>,
    startDate?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInspectionTemplateAssignments>>, Record<string, never>>
  },
  AssetInventoryRequirements?: {
    intervalInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInventoryRequirements>>, Record<string, never>>,
    photoRequired?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetInventoryRequirements>>, Record<string, never>>
  },
  AssetManufacturer?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetManufacturer>>, Record<string, never>>,
    make?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetManufacturer>>, Record<string, never>>,
    model?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetManufacturer>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetManufacturer>>, Record<string, never>>,
    year?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetManufacturer>>, Record<string, never>>
  },
  AssetPurchaseDetails?: {
    date?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetPurchaseDetails>>, Record<string, never>>,
    price?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetPurchaseDetails>>, Record<string, never>>
  },
  AssetRentalAgreement?: {
    endOn?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRentalAgreement>>, Record<string, never>>,
    rate?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRentalAgreement>>, Record<string, never>>,
    startOn?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRentalAgreement>>, Record<string, never>>
  },
  AssetRentalAgreementRate?: {
    daily?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRentalAgreementRate>>, Record<string, never>>,
    monthly?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRentalAgreementRate>>, Record<string, never>>,
    weekly?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRentalAgreementRate>>, Record<string, never>>
  },
  AssetRepairRequest?: {
    asset?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>,
    assetId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>,
    assetReport?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>,
    inspectionFieldId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>,
    label?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>,
    organization?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>,
    reporter?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>,
    reporterId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetRepairRequest>>, Record<string, never>>
  },
  AssetReport?: {
    asset?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    assetId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    inspectionReport?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    inspectionReportAggregations?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    inventoryReport?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    notes?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    organization?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    quantityReported?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    reporter?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    reporterId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    statusChange?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    transferReport?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReport>>, Record<string, never>>
  },
  AssetReportInspectionSubmission?: {
    failedStatus?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>,
    fileIds?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>,
    input?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>,
    label?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>,
    photoLabel?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>,
    photoRequired?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>,
    photoUrls?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>,
    required?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>,
    rule?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInspectionSubmission>>, Record<string, never>>
  },
  AssetReportInventoryReport?: {
    fileIds?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInventoryReport>>, Record<string, never>>,
    note?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInventoryReport>>, Record<string, never>>,
    photoUrls?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportInventoryReport>>, Record<string, never>>
  },
  AssetReportTemplate?: {
    active?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    assets?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    assetsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    deletedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    fields?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    organization?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    reusable?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    universal?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplate>>, Record<string, never>>
  },
  AssetReportTemplateField?: {
    failedStatus?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplateField>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplateField>>, Record<string, never>>,
    label?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplateField>>, Record<string, never>>,
    photoLabel?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplateField>>, Record<string, never>>,
    photoRequired?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplateField>>, Record<string, never>>,
    required?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplateField>>, Record<string, never>>,
    rule?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplateField>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTemplateField>>, Record<string, never>>
  },
  AssetReportTransferAssignment?: {
    asset?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTransferAssignment>>, Record<string, never>>,
    assignableId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTransferAssignment>>, Record<string, never>>,
    assignableType?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTransferAssignment>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTransferAssignment>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTransferAssignment>>, Record<string, never>>
  },
  AssetReportTransferReport?: {
    from?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTransferReport>>, Record<string, never>>,
    to?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetReportTransferReport>>, Record<string, never>>
  },
  AssetStatusChange?: {
    active?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetStatusChange>>, Record<string, never>>,
    missingCount?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetStatusChange>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetStatusChange>>, Record<string, never>>
  },
  AssetVendorContact?: {
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetVendorContact>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetVendorContact>>, Record<string, never>>,
    phone?: GraphCacheUpdateResolver<Maybe<WithTypename<AssetVendorContact>>, Record<string, never>>
  },
  AssignedAsset?: {
    active?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assetChildCount?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assetGroupId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assignableId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assignableType?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assignedAsset?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assignedAssetId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assignedTask?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assignedTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assignedUser?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assignedUserId?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    assignmentsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    category?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    companyAssetNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    compositeKey?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    deletedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    depth?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    groupParentAsset?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    inferredOwner?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    inventoryRequirements?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    isAssetGroup?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    lastInventoriedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    ownershipType?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    quantizedAssetIds?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<AssignedAsset>>, Record<string, never>>
  },
  BulkTaskCreationResult?: {
    createdSubTasksCount?: GraphCacheUpdateResolver<Maybe<WithTypename<BulkTaskCreationResult>>, Record<string, never>>,
    createdTaskGroupsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<BulkTaskCreationResult>>, Record<string, never>>,
    createdTasksCount?: GraphCacheUpdateResolver<Maybe<WithTypename<BulkTaskCreationResult>>, Record<string, never>>,
    createdUnitGoalsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<BulkTaskCreationResult>>, Record<string, never>>
  },
  BulkTimeEntryResponse?: {
    errors?: GraphCacheUpdateResolver<Maybe<WithTypename<BulkTimeEntryResponse>>, Record<string, never>>,
    success?: GraphCacheUpdateResolver<Maybe<WithTypename<BulkTimeEntryResponse>>, Record<string, never>>
  },
  ClockInData?: {
    fileUploadResponses?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockInData>>, Record<string, never>>,
    imagePath?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockInData>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockInData>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockInData>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockInData>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockInData>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockInData>>, Record<string, never>>
  },
  ClockOutData?: {
    fileUploadResponses?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>,
    imagePath?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>,
    injured?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>,
    injuryNotes?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>,
    injuryReportId?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>,
    signaturePath?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<ClockOutData>>, Record<string, never>>
  },
  Contract?: {
    assetBillingClassifications?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    customerId?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    deliverableUnits?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    number?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    organization?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>,
    userBillingClassifications?: GraphCacheUpdateResolver<Maybe<WithTypename<Contract>>, Record<string, never>>
  },
  ContractAssetBillingClassification?: {
    assetBillingClassification?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractAssetBillingClassification>>, Record<string, never>>,
    assetBillingClassificationId?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractAssetBillingClassification>>, Record<string, never>>,
    contract?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractAssetBillingClassification>>, Record<string, never>>,
    contractId?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractAssetBillingClassification>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractAssetBillingClassification>>, Record<string, never>>,
    customerDescription?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractAssetBillingClassification>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractAssetBillingClassification>>, Record<string, never>>,
    rate?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractAssetBillingClassification>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractAssetBillingClassification>>, Record<string, never>>
  },
  ContractDeliverableUnit?: {
    contract?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractDeliverableUnit>>, Record<string, never>>,
    contractId?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractDeliverableUnit>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractDeliverableUnit>>, Record<string, never>>,
    customerDescription?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractDeliverableUnit>>, Record<string, never>>,
    deliverableUnit?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractDeliverableUnit>>, Record<string, never>>,
    deliverableUnitId?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractDeliverableUnit>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractDeliverableUnit>>, Record<string, never>>,
    rate?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractDeliverableUnit>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractDeliverableUnit>>, Record<string, never>>
  },
  ContractUserBillingClassification?: {
    contract?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractUserBillingClassification>>, Record<string, never>>,
    contractId?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractUserBillingClassification>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractUserBillingClassification>>, Record<string, never>>,
    customerDescription?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractUserBillingClassification>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractUserBillingClassification>>, Record<string, never>>,
    rate?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractUserBillingClassification>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractUserBillingClassification>>, Record<string, never>>,
    userBillingClassification?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractUserBillingClassification>>, Record<string, never>>,
    userBillingClassificationId?: GraphCacheUpdateResolver<Maybe<WithTypename<ContractUserBillingClassification>>, Record<string, never>>
  },
  CreateManyOfflineEventsResponsePayload?: {
    createdEvents?: GraphCacheUpdateResolver<Maybe<WithTypename<CreateManyOfflineEventsResponsePayload>>, Record<string, never>>,
    failureCount?: GraphCacheUpdateResolver<Maybe<WithTypename<CreateManyOfflineEventsResponsePayload>>, Record<string, never>>,
    successCount?: GraphCacheUpdateResolver<Maybe<WithTypename<CreateManyOfflineEventsResponsePayload>>, Record<string, never>>
  },
  Customer?: {
    activeProjectCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    addressLine1?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    addressLine2?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    city?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    contracts?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    customerId?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    image?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    notes?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    phoneNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    phoneNumberExt?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    state?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>,
    zip?: GraphCacheUpdateResolver<Maybe<WithTypename<Customer>>, Record<string, never>>
  },
  DeliverableUnit?: {
    archivedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<DeliverableUnit>>, Record<string, never>>,
    color?: GraphCacheUpdateResolver<Maybe<WithTypename<DeliverableUnit>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<DeliverableUnit>>, Record<string, never>>,
    deletedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<DeliverableUnit>>, Record<string, never>>,
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<DeliverableUnit>>, Record<string, never>>,
    divisionDeliverableUnit?: GraphCacheUpdateResolver<Maybe<WithTypename<DeliverableUnit>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<DeliverableUnit>>, Record<string, never>>,
    referenceNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<DeliverableUnit>>, Record<string, never>>,
    unitOfMeasure?: GraphCacheUpdateResolver<Maybe<WithTypename<DeliverableUnit>>, Record<string, never>>
  },
  DigestDataDetails?: {
    code?: GraphCacheUpdateResolver<Maybe<WithTypename<DigestDataDetails>>, Record<string, never>>,
    dailyProgress?: GraphCacheUpdateResolver<Maybe<WithTypename<DigestDataDetails>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<DigestDataDetails>>, Record<string, never>>,
    isArchived?: GraphCacheUpdateResolver<Maybe<WithTypename<DigestDataDetails>>, Record<string, never>>,
    isComplete?: GraphCacheUpdateResolver<Maybe<WithTypename<DigestDataDetails>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<DigestDataDetails>>, Record<string, never>>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<DigestDataDetails>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<DigestDataDetails>>, Record<string, never>>,
    tasksCompletedSum?: GraphCacheUpdateResolver<Maybe<WithTypename<DigestDataDetails>>, Record<string, never>>,
    timeEntriesSum?: GraphCacheUpdateResolver<Maybe<WithTypename<DigestDataDetails>>, Record<string, never>>
  },
  Division?: {
    activeProjectsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    activeUsersForDateRangeCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, DivisionActiveUsersForDateRangeCountArgs>,
    address?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    adminCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    admins?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    allowTeamMembersClockInOut?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    archived?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    assetCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    billingPastDueAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    billingStatus?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    clockedInUserCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    dailyDigestData?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, DivisionDailyDigestDataArgs>,
    dateCreated?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    divisionCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    isCurrentAssignment?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    isPrimary?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    lastStripeInvoice?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    notificationSettings?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    overtimeType?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    projectsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    requireInjuryReportOnClockout?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    requireTimeEntryEvidence?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    roles?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    schedule?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    scheduledBreaks?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    secondsClockedForCurrentWeek?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    stripeCustomerId?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    stripeInvoices?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    timezone?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    unassignedProjectId?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    unitsOfMeasure?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>,
    userCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Division>>, Record<string, never>>
  },
  DivisionDeliverableUnit?: {
    active?: GraphCacheUpdateResolver<Maybe<WithTypename<DivisionDeliverableUnit>>, Record<string, never>>,
    assignedToTask?: GraphCacheUpdateResolver<Maybe<WithTypename<DivisionDeliverableUnit>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<DivisionDeliverableUnit>>, Record<string, never>>
  },
  EditHistoryEvent?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    data?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    modelId?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    modelType?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<EditHistoryEvent>>, Record<string, never>>
  },
  EndEvidence?: {
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<EndEvidence>>, Record<string, never>>,
    location?: GraphCacheUpdateResolver<Maybe<WithTypename<EndEvidence>>, Record<string, never>>,
    locationError?: GraphCacheUpdateResolver<Maybe<WithTypename<EndEvidence>>, Record<string, never>>,
    signatureUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<EndEvidence>>, Record<string, never>>
  },
  File?: {
    assetId?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    contentType?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    deletedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    documentUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    expiresAt?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    fileName?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    key?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    objectVersion?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>,
    vendorId?: GraphCacheUpdateResolver<Maybe<WithTypename<File>>, Record<string, never>>
  },
  FileUpload?: {
    fileId?: GraphCacheUpdateResolver<Maybe<WithTypename<FileUpload>>, Record<string, never>>,
    objectKey?: GraphCacheUpdateResolver<Maybe<WithTypename<FileUpload>>, Record<string, never>>,
    uploadUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<FileUpload>>, Record<string, never>>,
    urlExpiresAt?: GraphCacheUpdateResolver<Maybe<WithTypename<FileUpload>>, Record<string, never>>
  },
  FileUploadResponse?: {
    fileId?: GraphCacheUpdateResolver<Maybe<WithTypename<FileUploadResponse>>, Record<string, never>>,
    objectKey?: GraphCacheUpdateResolver<Maybe<WithTypename<FileUploadResponse>>, Record<string, never>>,
    uploadUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<FileUploadResponse>>, Record<string, never>>,
    urlExpiresAt?: GraphCacheUpdateResolver<Maybe<WithTypename<FileUploadResponse>>, Record<string, never>>
  },
  FraudEvidenceData?: {
    currentUtcDateTime?: GraphCacheUpdateResolver<Maybe<WithTypename<FraudEvidenceData>>, Record<string, never>>,
    systemWallTime?: GraphCacheUpdateResolver<Maybe<WithTypename<FraudEvidenceData>>, Record<string, never>>
  },
  GanttProjectDetailsRef?: {
    endDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttProjectDetailsRef>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttProjectDetailsRef>>, Record<string, never>>,
    isComplete?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttProjectDetailsRef>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttProjectDetailsRef>>, Record<string, never>>,
    startDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttProjectDetailsRef>>, Record<string, never>>,
    taskDependencies?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttProjectDetailsRef>>, Record<string, never>>,
    taskGroups?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttProjectDetailsRef>>, Record<string, never>>,
    tasks?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttProjectDetailsRef>>, Record<string, never>>
  },
  GanttTaskDependencyRef?: {
    dependentTaskEndDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    dependentTaskGroupId?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    dependentTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    dependentTaskSortOrder?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    dependentTaskStartDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    metadata?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    predecessorTaskEndDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    predecessorTaskGroupId?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    predecessorTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    predecessorTaskSortOrder?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    predecessorTaskStartDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDependencyRef>>, Record<string, never>>
  },
  GanttTaskDetailsRef?: {
    actualEndDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    actualStartDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    constrainedEndDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    constrainedStartDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    groupId?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    isComplete?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    plannedEndDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    plannedStartDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    sortOrder?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    unitGoalsSumProgress?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>,
    unitGoalsSumTargetQuantity?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskDetailsRef>>, Record<string, never>>
  },
  GanttTaskGroupDetailsRef?: {
    actualEndDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    actualStartDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    constrainedEndDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    constrainedStartDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    isComplete?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    plannedEndDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    plannedStartDate?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    sortOrder?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    tasks?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    unitProgress?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>,
    unitTargetGoal?: GraphCacheUpdateResolver<Maybe<WithTypename<GanttTaskGroupDetailsRef>>, Record<string, never>>
  },
  GeolocationCoordinates?: {
    accuracy?: GraphCacheUpdateResolver<Maybe<WithTypename<GeolocationCoordinates>>, Record<string, never>>,
    altitude?: GraphCacheUpdateResolver<Maybe<WithTypename<GeolocationCoordinates>>, Record<string, never>>,
    altitudeAccuracy?: GraphCacheUpdateResolver<Maybe<WithTypename<GeolocationCoordinates>>, Record<string, never>>,
    heading?: GraphCacheUpdateResolver<Maybe<WithTypename<GeolocationCoordinates>>, Record<string, never>>,
    latitude?: GraphCacheUpdateResolver<Maybe<WithTypename<GeolocationCoordinates>>, Record<string, never>>,
    longitude?: GraphCacheUpdateResolver<Maybe<WithTypename<GeolocationCoordinates>>, Record<string, never>>,
    speed?: GraphCacheUpdateResolver<Maybe<WithTypename<GeolocationCoordinates>>, Record<string, never>>
  },
  GeolocationPosition?: {
    coords?: GraphCacheUpdateResolver<Maybe<WithTypename<GeolocationPosition>>, Record<string, never>>,
    isMocked?: GraphCacheUpdateResolver<Maybe<WithTypename<GeolocationPosition>>, Record<string, never>>,
    timestamp?: GraphCacheUpdateResolver<Maybe<WithTypename<GeolocationPosition>>, Record<string, never>>
  },
  InjuryReport?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    notes?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    reporter?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    reporterId?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    signatureUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReport>>, Record<string, never>>
  },
  InjuryReportData?: {
    fileUploadResponses?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReportData>>, Record<string, never>>,
    injuryReportId?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReportData>>, Record<string, never>>,
    notes?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReportData>>, Record<string, never>>,
    signaturePath?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReportData>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReportData>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReportData>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReportData>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<InjuryReportData>>, Record<string, never>>
  },
  InspectionReportAggregations?: {
    falseCount?: GraphCacheUpdateResolver<Maybe<WithTypename<InspectionReportAggregations>>, Record<string, never>>,
    trueCount?: GraphCacheUpdateResolver<Maybe<WithTypename<InspectionReportAggregations>>, Record<string, never>>
  },
  LastExport?: {
    exportedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<LastExport>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<LastExport>>, Record<string, never>>,
    userName?: GraphCacheUpdateResolver<Maybe<WithTypename<LastExport>>, Record<string, never>>
  },
  LocationWithRadius?: {
    lat?: GraphCacheUpdateResolver<Maybe<WithTypename<LocationWithRadius>>, Record<string, never>>,
    latitude?: GraphCacheUpdateResolver<Maybe<WithTypename<LocationWithRadius>>, Record<string, never>>,
    lng?: GraphCacheUpdateResolver<Maybe<WithTypename<LocationWithRadius>>, Record<string, never>>,
    longitude?: GraphCacheUpdateResolver<Maybe<WithTypename<LocationWithRadius>>, Record<string, never>>,
    radius?: GraphCacheUpdateResolver<Maybe<WithTypename<LocationWithRadius>>, Record<string, never>>,
    radiusInMeters?: GraphCacheUpdateResolver<Maybe<WithTypename<LocationWithRadius>>, Record<string, never>>
  },
  MetadataNote?: {
    content?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataNote>>, Record<string, never>>,
    highlight?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataNote>>, Record<string, never>>,
    label?: GraphCacheUpdateResolver<Maybe<WithTypename<MetadataNote>>, Record<string, never>>
  },
  OfflineEvent?: {
    actionPayload?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    assetReportData?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    clientCanceledAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    clientCreatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    clientUpdatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    clockInData?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    clockOutData?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    deviceInformation?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    errors?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    fraudDetected?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    fraudEvidence?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    injuryReportData?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    issues?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    location?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    markedAsResolvedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    markedAsResolvedBy?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    markedAsResolvedById?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    mobileApplicationIsVerified?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    processedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    reassignData?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    receivedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    reconciledAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    reporterId?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    requestInformation?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    taskProgressData?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<OfflineEvent>>, Record<string, never>>
  },
  Organization?: {
    activeProjectsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    activeUsersForDateRangeCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, OrganizationActiveUsersForDateRangeCountArgs>,
    address?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    adminCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    admins?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    allowTeamMembersClockInOut?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    archived?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    assetCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    billingPastDueAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    billingStatus?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    clockedInUserCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    dailyDigestData?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, OrganizationDailyDigestDataArgs>,
    dateCreated?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    divisionCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    divisions?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    featureToggleSegments?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    image?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    isCurrentAssignment?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    isPrimary?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    lastStripeInvoice?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    notificationSettings?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    overtimeType?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    projectsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    requireInjuryReportOnClockout?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    requireTimeEntryEvidence?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    roles?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    schedule?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    scheduledBreaks?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    secondsClockedForCurrentWeek?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    stripeCustomerId?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    stripeInvoices?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    timezone?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    unassignedProjectId?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    unitsOfMeasure?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>,
    userCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Organization>>, Record<string, never>>
  },
  OrganizationAddress?: {
    addressLine1?: GraphCacheUpdateResolver<Maybe<WithTypename<OrganizationAddress>>, Record<string, never>>,
    addressLine2?: GraphCacheUpdateResolver<Maybe<WithTypename<OrganizationAddress>>, Record<string, never>>,
    city?: GraphCacheUpdateResolver<Maybe<WithTypename<OrganizationAddress>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<OrganizationAddress>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<OrganizationAddress>>, Record<string, never>>,
    state?: GraphCacheUpdateResolver<Maybe<WithTypename<OrganizationAddress>>, Record<string, never>>,
    zip?: GraphCacheUpdateResolver<Maybe<WithTypename<OrganizationAddress>>, Record<string, never>>
  },
  PageInfo?: {
    endCursor?: GraphCacheUpdateResolver<Maybe<WithTypename<PageInfo>>, Record<string, never>>,
    hasNextPage?: GraphCacheUpdateResolver<Maybe<WithTypename<PageInfo>>, Record<string, never>>,
    hasPreviousPage?: GraphCacheUpdateResolver<Maybe<WithTypename<PageInfo>>, Record<string, never>>,
    startCursor?: GraphCacheUpdateResolver<Maybe<WithTypename<PageInfo>>, Record<string, never>>
  },
  PayrollExportRow?: {
    code?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    companyUserId?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    dailyTotals?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    daysWorked?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    firstName?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    jobTitle?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    lastName?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    overtime?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    regularTime?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    totalPaidTime?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<PayrollExportRow>>, Record<string, never>>
  },
  ProductionRateData?: {
    actual?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductionRateData>>, Record<string, never>>,
    expected?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductionRateData>>, Record<string, never>>,
    rate?: GraphCacheUpdateResolver<Maybe<WithTypename<ProductionRateData>>, Record<string, never>>
  },
  Project?: {
    activeTasksCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    address?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    assetCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    assets?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectAssetsArgs>,
    assetsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectAssetsCountArgs>,
    assignees?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    code?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    contract?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    contractId?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    customer?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    customerId?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    dateCreated?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    defaultTask?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    deliveredPrimaryGoalUnits?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectDeliveredPrimaryGoalUnitsArgs>,
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    divisionId?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    documents?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    editHistoryEvents?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    editHistoryEventsCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    endDate?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    estimatedHours?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    ganttDetails?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectGanttDetailsArgs>,
    googlePlace?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    hasReportableUnit?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    hoursDataByDateRangeType?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectHoursDataByDateRangeTypeArgs>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    injuryReports?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    isArchived?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    isComplete?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    isDefault?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    lastExport?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    location?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    locationNotes?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    locationUntyped?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    metadata?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    metadataNotes?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    notes?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    orgScheduleId?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    organization?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    primaryGoalTargetQuantity?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    productionRate?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectProductionRateArgs>,
    progressSummary?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    schedule?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    scheduledBreaks?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    startDate?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    taskDependencies?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    taskGroups?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    taskProgressEventsWithUnitReports?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectTaskProgressEventsWithUnitReportsArgs>,
    tasks?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectTasksArgs>,
    tasksCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    timeEntries?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectTimeEntriesArgs>,
    timeEntriesSumDurationInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectTimeEntriesSumDurationInSecondsArgs>,
    unassignedTask?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    unassignedTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    unitGoals?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    userCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    users?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, ProjectUsersArgs>,
    vendors?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    workersCompCode?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>,
    workersCompCodeId?: GraphCacheUpdateResolver<Maybe<WithTypename<Project>>, Record<string, never>>
  },
  ProjectProgressSummary?: {
    completedHours?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectProgressSummary>>, Record<string, never>>,
    completedUnits?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectProgressSummary>>, Record<string, never>>,
    completionPercentage?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectProgressSummary>>, Record<string, never>>,
    noHours?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectProgressSummary>>, Record<string, never>>,
    targetHours?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectProgressSummary>>, Record<string, never>>,
    targetUnits?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectProgressSummary>>, Record<string, never>>
  },
  ProjectVendor?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectVendor>>, Record<string, never>>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectVendor>>, Record<string, never>>,
    vendor?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectVendor>>, Record<string, never>>
  },
  ProjectVendorInputData?: {
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectVendorInputData>>, Record<string, never>>,
    vendorId?: GraphCacheUpdateResolver<Maybe<WithTypename<ProjectVendorInputData>>, Record<string, never>>
  },
  QueryActiveProjectsPaginatedConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryActiveProjectsPaginatedConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryActiveProjectsPaginatedConnection>>, Record<string, never>>
  },
  QueryActiveProjectsPaginatedConnectionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryActiveProjectsPaginatedConnectionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryActiveProjectsPaginatedConnectionEdge>>, Record<string, never>>
  },
  QueryArchivedAssetsConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryArchivedAssetsConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryArchivedAssetsConnection>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryArchivedAssetsConnection>>, Record<string, never>>
  },
  QueryArchivedAssetsConnectionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryArchivedAssetsConnectionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryArchivedAssetsConnectionEdge>>, Record<string, never>>
  },
  QueryAssets_2Connection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryAssets_2Connection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryAssets_2Connection>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryAssets_2Connection>>, Record<string, never>>
  },
  QueryAssets_2ConnectionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryAssets_2ConnectionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryAssets_2ConnectionEdge>>, Record<string, never>>
  },
  QueryProjectsPaginatedConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryProjectsPaginatedConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryProjectsPaginatedConnection>>, Record<string, never>>
  },
  QueryProjectsPaginatedConnectionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryProjectsPaginatedConnectionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryProjectsPaginatedConnectionEdge>>, Record<string, never>>
  },
  QueryTasksPaginatedConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryTasksPaginatedConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryTasksPaginatedConnection>>, Record<string, never>>
  },
  QueryTasksPaginatedConnectionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryTasksPaginatedConnectionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryTasksPaginatedConnectionEdge>>, Record<string, never>>
  },
  QueryUsersConnection?: {
    edges?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryUsersConnection>>, Record<string, never>>,
    pageInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryUsersConnection>>, Record<string, never>>,
    totalCount?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryUsersConnection>>, Record<string, never>>
  },
  QueryUsersConnectionEdge?: {
    cursor?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryUsersConnectionEdge>>, Record<string, never>>,
    node?: GraphCacheUpdateResolver<Maybe<WithTypename<QueryUsersConnectionEdge>>, Record<string, never>>
  },
  ReassignmentData?: {
    newProject?: GraphCacheUpdateResolver<Maybe<WithTypename<ReassignmentData>>, Record<string, never>>,
    newProjectId?: GraphCacheUpdateResolver<Maybe<WithTypename<ReassignmentData>>, Record<string, never>>,
    newTask?: GraphCacheUpdateResolver<Maybe<WithTypename<ReassignmentData>>, Record<string, never>>,
    newTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<ReassignmentData>>, Record<string, never>>,
    oldProject?: GraphCacheUpdateResolver<Maybe<WithTypename<ReassignmentData>>, Record<string, never>>,
    oldProjectId?: GraphCacheUpdateResolver<Maybe<WithTypename<ReassignmentData>>, Record<string, never>>,
    oldTask?: GraphCacheUpdateResolver<Maybe<WithTypename<ReassignmentData>>, Record<string, never>>,
    oldTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<ReassignmentData>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<ReassignmentData>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<ReassignmentData>>, Record<string, never>>
  },
  RequestInfo?: {
    browser?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>,
    city?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>,
    country?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>,
    isBot?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>,
    isCurl?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>,
    isDesktop?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>,
    isMobile?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>,
    isTablet?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>,
    os?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>,
    platform?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>,
    region?: GraphCacheUpdateResolver<Maybe<WithTypename<RequestInfo>>, Record<string, never>>
  },
  Role?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Role>>, Record<string, never>>,
    dateCreated?: GraphCacheUpdateResolver<Maybe<WithTypename<Role>>, Record<string, never>>,
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<Role>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Role>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Role>>, Record<string, never>>,
    order?: GraphCacheUpdateResolver<Maybe<WithTypename<Role>>, Record<string, never>>,
    organization?: GraphCacheUpdateResolver<Maybe<WithTypename<Role>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<Role>>, Record<string, never>>,
    permissions?: GraphCacheUpdateResolver<Maybe<WithTypename<Role>>, Record<string, never>>,
    widgets?: GraphCacheUpdateResolver<Maybe<WithTypename<Role>>, Record<string, never>>
  },
  Schedule?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Schedule>>, Record<string, never>>,
    isDefault?: GraphCacheUpdateResolver<Maybe<WithTypename<Schedule>>, Record<string, never>>,
    nonWorkDays?: GraphCacheUpdateResolver<Maybe<WithTypename<Schedule>>, Record<string, never>>,
    workDays?: GraphCacheUpdateResolver<Maybe<WithTypename<Schedule>>, Record<string, never>>,
    workHours?: GraphCacheUpdateResolver<Maybe<WithTypename<Schedule>>, Record<string, never>>
  },
  ScheduleNonWorkDays?: {
    active?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduleNonWorkDays>>, Record<string, never>>,
    dateRange?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduleNonWorkDays>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduleNonWorkDays>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduleNonWorkDays>>, Record<string, never>>
  },
  ScheduleWorkDay?: {
    active?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduleWorkDay>>, Record<string, never>>,
    label?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduleWorkDay>>, Record<string, never>>
  },
  ScheduleWorkHours?: {
    endTime?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduleWorkHours>>, Record<string, never>>,
    hours?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduleWorkHours>>, Record<string, never>>,
    startTime?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduleWorkHours>>, Record<string, never>>
  },
  ScheduledBreak?: {
    breakTask?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduledBreak>>, Record<string, never>>,
    breakTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduledBreak>>, Record<string, never>>,
    durationInMinutes?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduledBreak>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduledBreak>>, Record<string, never>>,
    isActive?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduledBreak>>, Record<string, never>>,
    localizedStartTime?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduledBreak>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduledBreak>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<ScheduledBreak>>, Record<string, never>>
  },
  SecurityActivity?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<SecurityActivity>>, Record<string, never>>,
    event?: GraphCacheUpdateResolver<Maybe<WithTypename<SecurityActivity>>, Record<string, never>>,
    ip?: GraphCacheUpdateResolver<Maybe<WithTypename<SecurityActivity>>, Record<string, never>>,
    userAgent?: GraphCacheUpdateResolver<Maybe<WithTypename<SecurityActivity>>, Record<string, never>>
  },
  Settings?: {
    allowTeamMembersClockInOut?: GraphCacheUpdateResolver<Maybe<WithTypename<Settings>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Settings>>, Record<string, never>>,
    notificationSettings?: GraphCacheUpdateResolver<Maybe<WithTypename<Settings>>, Record<string, never>>,
    overtimeType?: GraphCacheUpdateResolver<Maybe<WithTypename<Settings>>, Record<string, never>>,
    requireInjuryReportOnClockout?: GraphCacheUpdateResolver<Maybe<WithTypename<Settings>>, Record<string, never>>,
    requireTimeEntryEvidence?: GraphCacheUpdateResolver<Maybe<WithTypename<Settings>>, Record<string, never>>
  },
  StartEvidence?: {
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<StartEvidence>>, Record<string, never>>,
    location?: GraphCacheUpdateResolver<Maybe<WithTypename<StartEvidence>>, Record<string, never>>,
    locationError?: GraphCacheUpdateResolver<Maybe<WithTypename<StartEvidence>>, Record<string, never>>
  },
  StripeInvoice?: {
    accountName?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    amountDue?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    amountPaid?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    amountRemaining?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    attemptCount?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    attempted?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    autoAdvance?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    billingReason?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    collectionMethod?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    created?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    currency?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    customerEmail?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    customerName?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    customerPhone?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    dueDate?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    hostedInvoiceUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    invoicePdf?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    livemode?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    object?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    paid?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    status?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    subtotal?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    subtotalExcludingTax?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    tax?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>,
    total?: GraphCacheUpdateResolver<Maybe<WithTypename<StripeInvoice>>, Record<string, never>>
  },
  Task?: {
    archived?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    assetCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskAssetCountArgs>,
    assignees?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    clockedInUserCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    clockedOutUserCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    completedHours?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskCompletedHoursArgs>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    dateCreated?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    dependentTaskDependencies?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    dependentTasks?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    divisionId?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    driverTaskDependency?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    endDate?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    estimatedHours?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    estimatedWorkDays?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    estimatedWorkHours?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    group?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    groupId?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    hasReportableUnit?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    hoursDataByDateRangeType?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskHoursDataByDateRangeTypeArgs>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    injuryReports?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    isComplete?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    isDefault?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    isUnpaid?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    lastExport?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    metadata?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    metadataNotes?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    notes?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    orgScheduleId?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    predecessorTaskDependencies?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    predecessorTasks?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    primaryUnitGoals?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    productionRate?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskProductionRateArgs>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    projectScheduleId?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    schedule?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    scheduledBreaks?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    sortOrder?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    startDate?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    sumOfDeliveredUnits?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskSumOfDeliveredUnitsArgs>,
    taskProgressEvents?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskTaskProgressEventsArgs>,
    taskProgressEventsWithUnitReports?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskTaskProgressEventsWithUnitReportsArgs>,
    timeEntries?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskTimeEntriesArgs>,
    timeEntriesSumDurationInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskTimeEntriesSumDurationInSecondsArgs>,
    unitGoals?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    unitGoalsSumProgress?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    unitGoalsSumTargetQuantity?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    userCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    users?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskUsersArgs>,
    usersWhoClockedOrReportedCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, TaskUsersWhoClockedOrReportedCountArgs>,
    visibilityLevel?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    workersCompCode?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>,
    workersCompCodeId?: GraphCacheUpdateResolver<Maybe<WithTypename<Task>>, Record<string, never>>
  },
  TaskDependency?: {
    dependentTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskDependency>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskDependency>>, Record<string, never>>,
    metadata?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskDependency>>, Record<string, never>>,
    predecessorTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskDependency>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskDependency>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskDependency>>, Record<string, never>>
  },
  TaskDependencyData?: {
    offsetDays?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskDependencyData>>, Record<string, never>>
  },
  TaskGroup?: {
    assetCount?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, TaskGroupAssetCountArgs>,
    completedHours?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, TaskGroupCompletedHoursArgs>,
    completedTaskCount?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    description?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    endDate?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    estimatedHours?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    isComplete?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    notes?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    productionRate?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, TaskGroupProductionRateArgs>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    sortOrder?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    startDate?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    taskCount?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    tasks?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, TaskGroupTasksArgs>,
    unitProgress?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    unitTargetGoal?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, Record<string, never>>,
    userCount?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskGroup>>, TaskGroupUserCountArgs>
  },
  TaskListItem?: {
    assetCount?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    completedHours?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    completedTaskCount?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    endDate?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    estimatedHours?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    groupId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    isComplete?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    isDefault?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    metadata?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    sortOrder?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    startDate?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    taskCount?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    taskGroupId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    unitProgress?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    unitTargetGoal?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>,
    userCount?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskListItem>>, Record<string, never>>
  },
  TaskProgressEvent?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>,
    imageUrls?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>,
    note?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>,
    reporter?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>,
    reporterId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>,
    unitGoalProgressCount?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>,
    unitGoalProgressReports?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEvent>>, Record<string, never>>
  },
  TaskProgressEventData?: {
    fileUploadResponses?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEventData>>, Record<string, never>>,
    fileUploads?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEventData>>, Record<string, never>>,
    note?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEventData>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEventData>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEventData>>, Record<string, never>>,
    taskProgressEvent?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEventData>>, Record<string, never>>,
    taskProgressEventId?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEventData>>, Record<string, never>>,
    unitGoalProgressInputs?: GraphCacheUpdateResolver<Maybe<WithTypename<TaskProgressEventData>>, Record<string, never>>
  },
  TimeCardWeek?: {
    days?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeCardWeek>>, Record<string, never>>,
    overtime?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeCardWeek>>, Record<string, never>>,
    regularTime?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeCardWeek>>, Record<string, never>>,
    timezone?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeCardWeek>>, Record<string, never>>,
    totalEditedTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeCardWeek>>, Record<string, never>>,
    totalPaidTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeCardWeek>>, Record<string, never>>,
    totalTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeCardWeek>>, Record<string, never>>
  },
  TimeEntry?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    durationInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    editData?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    endAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    endAtEditor?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    endAtEditorId?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    evidence?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    evidenceType?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    isBreak?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    isUnpaid?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    organization?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    signInPhotoUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    signOutPhotoUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    startAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    startAtEditor?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    startAtEditorId?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntry>>, Record<string, never>>
  },
  TimeEntryChangeset?: {
    action?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangeset>>, Record<string, never>>,
    actor?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangeset>>, Record<string, never>>,
    actorId?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangeset>>, Record<string, never>>,
    changes?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangeset>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangeset>>, Record<string, never>>,
    operation?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangeset>>, Record<string, never>>,
    timestamp?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangeset>>, Record<string, never>>,
    totalEditedTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangeset>>, Record<string, never>>,
    value?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangeset>>, Record<string, never>>
  },
  TimeEntryChangesetChanges?: {
    endAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangesetChanges>>, Record<string, never>>,
    startAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryChangesetChanges>>, Record<string, never>>
  },
  TimeEntryDay?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryDay>>, Record<string, never>>,
    timeEntryWrappers?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryDay>>, Record<string, never>>,
    totalEditedTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryDay>>, Record<string, never>>,
    totalPaidTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryDay>>, Record<string, never>>,
    totalTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryDay>>, Record<string, never>>
  },
  TimeEntryDayRange?: {
    days?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryDayRange>>, Record<string, never>>,
    rangeTotalEditedTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryDayRange>>, Record<string, never>>,
    rangeTotalPaidTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryDayRange>>, Record<string, never>>,
    rangeTotalTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryDayRange>>, Record<string, never>>,
    timezone?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryDayRange>>, Record<string, never>>
  },
  TimeEntryError?: {
    reason?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryError>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryError>>, Record<string, never>>
  },
  TimeEntryEvidence?: {
    end?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryEvidence>>, Record<string, never>>,
    start?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryEvidence>>, Record<string, never>>
  },
  TimeEntryWrapper?: {
    actors?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryWrapper>>, Record<string, never>>,
    history?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryWrapper>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryWrapper>>, Record<string, never>>,
    isBreak?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryWrapper>>, Record<string, never>>,
    splitEndAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryWrapper>>, Record<string, never>>,
    splitStartAt?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryWrapper>>, Record<string, never>>,
    timeEntry?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryWrapper>>, Record<string, never>>,
    totalEditedTimeInSeconds?: GraphCacheUpdateResolver<Maybe<WithTypename<TimeEntryWrapper>>, Record<string, never>>
  },
  UnitGoal?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    deliverableUnit?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    deliverableUnitId?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    isPrimary?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    previousProgress?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, UnitGoalPreviousProgressArgs>,
    progressInDateRange?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, UnitGoalProgressInDateRangeArgs>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    targetQuantity?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    totalProgress?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    unitGoalProgress?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoal>>, Record<string, never>>
  },
  UnitGoalProgress?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoalProgress>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoalProgress>>, Record<string, never>>,
    progress?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoalProgress>>, Record<string, never>>,
    unitGoal?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoalProgress>>, Record<string, never>>,
    unitGoalId?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoalProgress>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoalProgress>>, Record<string, never>>
  },
  UnitGoalProgressInputData?: {
    progress?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoalProgressInputData>>, Record<string, never>>,
    unitGoalId?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitGoalProgressInputData>>, Record<string, never>>
  },
  UnitOfMeasure?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitOfMeasure>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitOfMeasure>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitOfMeasure>>, Record<string, never>>,
    symbol?: GraphCacheUpdateResolver<Maybe<WithTypename<UnitOfMeasure>>, Record<string, never>>
  },
  UpdateSortOrderPayload?: {
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UpdateSortOrderPayload>>, Record<string, never>>,
    sortOrder?: GraphCacheUpdateResolver<Maybe<WithTypename<UpdateSortOrderPayload>>, Record<string, never>>
  },
  User?: {
    archived?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    assetCount?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    assignedDivision?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    assignedProjectIds?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    assignedTaskIds?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    assignments?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    billingClassification?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    billingClassificationId?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    companyUserId?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    currentAssignment?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    currentProject?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    currentProjectId?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    currentTask?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    currentTaskId?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    defaultListVisibilityFilter?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    deviceSessions?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    divisionAccess?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    divisionId?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    emailVerifiedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    firstName?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    fullName?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    injuryReports?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    invitationPending?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    isBlackthornEmployee?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    isClockedIn?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    isOnBreak?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    jobTitle?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    lastName?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    latestTimeEntry?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    myFiles?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    offlineEvents?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, UserOfflineEventsArgs>,
    organization?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    passwordUpdatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    payRate?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    payType?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    phoneNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    phoneNumberExt?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    reportedUnitsInDateRangeByProjectOrTask?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, UserReportedUnitsInDateRangeByProjectOrTaskArgs>,
    roles?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    secondsClockedInDateRangeOnProjectOrTask?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, UserSecondsClockedInDateRangeOnProjectOrTaskArgs>,
    secondsClockedSinceOrgMidnight?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    secondsClockedThisWeek?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    securityActivity?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, UserSecurityActivityArgs>,
    sortName?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    timeEntries?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, UserTimeEntriesArgs>,
    totalEditedTimeInRange?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, UserTotalEditedTimeInRangeArgs>,
    workersCompCode?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>,
    workersCompCodeId?: GraphCacheUpdateResolver<Maybe<WithTypename<User>>, Record<string, never>>
  },
  UserAssignment?: {
    active?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    isCurrentAssignment?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    project?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    projectId?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    sortOrder?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    task?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    taskId?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignment>>, Record<string, never>>
  },
  UserAssignmentBatchResponse?: {
    count?: GraphCacheUpdateResolver<Maybe<WithTypename<UserAssignmentBatchResponse>>, Record<string, never>>
  },
  UserBillingClassification?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserBillingClassification>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UserBillingClassification>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<UserBillingClassification>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<UserBillingClassification>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserBillingClassification>>, Record<string, never>>
  },
  UserDeviceSession?: {
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserDeviceSession>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UserDeviceSession>>, Record<string, never>>,
    ip?: GraphCacheUpdateResolver<Maybe<WithTypename<UserDeviceSession>>, Record<string, never>>,
    isSingleUse?: GraphCacheUpdateResolver<Maybe<WithTypename<UserDeviceSession>>, Record<string, never>>,
    requestInfo?: GraphCacheUpdateResolver<Maybe<WithTypename<UserDeviceSession>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserDeviceSession>>, Record<string, never>>,
    userAgent?: GraphCacheUpdateResolver<Maybe<WithTypename<UserDeviceSession>>, Record<string, never>>,
    userId?: GraphCacheUpdateResolver<Maybe<WithTypename<UserDeviceSession>>, Record<string, never>>
  },
  UserNotification?: {
    asset?: GraphCacheUpdateResolver<Maybe<WithTypename<UserNotification>>, Record<string, never>>,
    assignedUser?: GraphCacheUpdateResolver<Maybe<WithTypename<UserNotification>>, Record<string, never>>,
    assignedUserId?: GraphCacheUpdateResolver<Maybe<WithTypename<UserNotification>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserNotification>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<UserNotification>>, Record<string, never>>,
    markedReadAt?: GraphCacheUpdateResolver<Maybe<WithTypename<UserNotification>>, Record<string, never>>,
    modelType?: GraphCacheUpdateResolver<Maybe<WithTypename<UserNotification>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<UserNotification>>, Record<string, never>>,
    type?: GraphCacheUpdateResolver<Maybe<WithTypename<UserNotification>>, Record<string, never>>,
    user?: GraphCacheUpdateResolver<Maybe<WithTypename<UserNotification>>, Record<string, never>>
  },
  Vendor?: {
    activeProjectCount?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    addressLine1?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    addressLine2?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    city?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    contacts?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    files?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    image?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    notes?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    phoneNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    phoneNumberExt?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    projects?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    state?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    vendorId?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>,
    zip?: GraphCacheUpdateResolver<Maybe<WithTypename<Vendor>>, Record<string, never>>
  },
  VendorContact?: {
    email?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>,
    firstName?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>,
    image?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>,
    imageUrl?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>,
    lastName?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>,
    phoneNumber?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>,
    phoneNumberExt?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>,
    role?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>,
    vendor?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>,
    vendorId?: GraphCacheUpdateResolver<Maybe<WithTypename<VendorContact>>, Record<string, never>>
  },
  WorkersCompCode?: {
    active?: GraphCacheUpdateResolver<Maybe<WithTypename<WorkersCompCode>>, Record<string, never>>,
    createdAt?: GraphCacheUpdateResolver<Maybe<WithTypename<WorkersCompCode>>, Record<string, never>>,
    id?: GraphCacheUpdateResolver<Maybe<WithTypename<WorkersCompCode>>, Record<string, never>>,
    name?: GraphCacheUpdateResolver<Maybe<WithTypename<WorkersCompCode>>, Record<string, never>>,
    ncciCode?: GraphCacheUpdateResolver<Maybe<WithTypename<WorkersCompCode>>, Record<string, never>>,
    ncciDescription?: GraphCacheUpdateResolver<Maybe<WithTypename<WorkersCompCode>>, Record<string, never>>,
    organizationId?: GraphCacheUpdateResolver<Maybe<WithTypename<WorkersCompCode>>, Record<string, never>>,
    override?: GraphCacheUpdateResolver<Maybe<WithTypename<WorkersCompCode>>, Record<string, never>>,
    rate?: GraphCacheUpdateResolver<Maybe<WithTypename<WorkersCompCode>>, Record<string, never>>,
    updatedAt?: GraphCacheUpdateResolver<Maybe<WithTypename<WorkersCompCode>>, Record<string, never>>
  },
  credentials?: {
    accessToken?: GraphCacheUpdateResolver<Maybe<WithTypename<Credentials>>, Record<string, never>>
  },
};

export type GraphCacheConfig = Parameters<typeof cacheExchange>[0] & {
  updates?: GraphCacheUpdaters,
  keys?: GraphCacheKeysConfig,
  optimistic?: GraphCacheOptimisticUpdaters,
  resolvers?: GraphCacheResolvers,
};