import { FC, useContext, useMemo } from "react"

import { useQuery } from "urql"
import { useIsOrganizationMenuActive } from "../hooks/useIsOrganizationMenuActive"
import { NextRouter, useRouter } from "next/router"
import { BiCog, BiFile, BiGroup, BiHive, BiUserCircle } from "react-icons/bi"

import { Organization, DivisionLinkMenuDataQuery } from "../graphql/generated/client-types-and-hooks"
import { URLs } from "../urls.config"
import { MenuItem } from "./QuickMenuMui"
import { graphql } from "../graphql/generated/gql"
import { QuickMenu } from "./QuickMenu"
import Logo from "./Layout/Navbar/Logo"
import { DivisionBadge } from "./DivisionBadge"
import { DevelopmentFeatureFlagContext } from "../providers/DevelopmentFeatureFlagProvider"
import { useSwitchDivision } from "../hooks/useSwitchDivision"

const getControlsList = (organizationId: Organization["id"], router: NextRouter) => {
  return [
    {
      value: "Company Details",
      onClick: () => router.push(URLs.companyDetails),
      Icon: BiFile,
    },
    {
      value: "Divisions",
      onClick: () => router.push(URLs.divisions),
      Icon: BiHive,
      requiredPermission: "divisions:update",
      requiredFeatureFlag: "Divisions",
    },
    {
      value: "Admins",
      onClick: () => router.push(URLs.admins),
      Icon: BiUserCircle,
      requiredPermission: "fe-organizationAdmins:read",
    },
    {
      value: "Roles",
      onClick: () => router.push(URLs.roles),
      Icon: BiGroup,
      requiredPermission: "fe-organizationRoles:read",
    },
    {
      value: "Settings",
      onClick: () => router.push(URLs.settings),
      Icon: BiCog,
      requiredPermission: "organization:update",
      requiredPermissionContext: { organizationId },
    },
  ] as MenuItem[]
}

const getInitialMenuItems = (
  organizationId: Organization["id"],
  router: NextRouter,
  myDivision?: DivisionLinkMenuDataQuery["myDivision"] | null
) => {
  const showTypeLabel = (myDivision?.divisionCount ?? 0) > 0
  return [
    {
      value: <DivisionBadge division={myDivision} showCurrentAssignment showDivisionTypeLabel={showTypeLabel} />,
      onClick: () => router.push(URLs.companyDetails),
      className: "px-4 py-2",
    },
    ...getControlsList(organizationId, router),
  ] as MenuItem[]
}

export const buildOrgLinks = (
  isDivisionFeatureEnabled: boolean,
  currentDivisionId: string | null,
  organizationId: Organization["id"],
  router: NextRouter,
  isLoading: boolean,
  handleSwitchDivisions: (selectedDivisionId: string | null) => void,
  data?: DivisionLinkMenuDataQuery | null
) => {
  const { myDivision, myDivisions: divisions } = data ?? {}
  if (!divisions || divisions.length <= 1 || !isDivisionFeatureEnabled) {
    return [getControlsList(organizationId, router)]
  }

  const orgLinks = divisions?.reduce(
    (menuItems, division) => {
      if ((!currentDivisionId && division.isPrimary) || division.id === currentDivisionId) {
        return menuItems
      }
      const divisionId = organizationId === division.id ? null : division.id ?? null

      const divisionMenuItem: MenuItem = {
        value: <DivisionBadge division={division} showCurrentAssignment showDivisionTypeLabel />,
        isDisabled: isLoading,
        onClick: () => handleSwitchDivisions(divisionId),
        requiredFeatureFlag: "Divisions",
        className: "px-4 py-2",
      } as MenuItem

      const firstMenuItemList = menuItems[0] || []
      const lastMenuItemList = menuItems[1] || []

      return [firstMenuItemList, [...lastMenuItemList, divisionMenuItem]]
    },
    [getInitialMenuItems(organizationId, router, myDivision), [], []] as MenuItem[][]
  )

  return orgLinks
}

type DivisionSelectionMenuProps = {
  currentDivisionId: string | null
  organizationId: string
}

export type DivisionSelect = {
  selectedDivisionId: string
}

const DivisionLinkMenuQueryDocument = graphql(`
  query DivisionLinkMenuData {
    myDivision {
      id
      name
      imageUrl
      isCurrentAssignment
      isPrimary
      divisionCount
    }
    myDivisions {
      id
      name
      imageUrl
      isCurrentAssignment
      isPrimary
    }
  }
`)

const DivisionLinkMenu: FC<DivisionSelectionMenuProps> = ({ organizationId, currentDivisionId }) => {
  const { isLoading, handleSwitchDivisions } = useSwitchDivision()
  const { flagIsEnabled } = useContext(DevelopmentFeatureFlagContext)

  const { isOrganizationMenuActive } = useIsOrganizationMenuActive()
  const router = useRouter()
  const [{ data }] = useQuery({
    query: DivisionLinkMenuQueryDocument,
    requestPolicy: "cache-and-network",
  })

  const isDivisionFeatureEnabled = flagIsEnabled("Divisions")

  const menuItems = useMemo(
    () =>
      buildOrgLinks(
        isDivisionFeatureEnabled,
        currentDivisionId,
        organizationId,
        router,
        isLoading,
        handleSwitchDivisions,
        data
      ),
    [isDivisionFeatureEnabled, currentDivisionId, data, organizationId, isLoading, router, handleSwitchDivisions]
  )

  return (
    <QuickMenu
      className="max-w-full xl:w-fit"
      menuClassName="mt-1 max-w-[20vw] min-w-[370px]"
      menuButtonClassName="flex-1 flex-start justify-start items-center h-10 mx-0 xl:w-fit"
      items={menuItems}
    >
      <div className="flex justify-start items-center max-w-full rounded-md transition-colors group hover:bg-gray-100">
        <Logo className="max-w-full" labelClassName={isOrganizationMenuActive ? "text-blue-600" : "text-gray-800"} />
      </div>
    </QuickMenu>
  )
}

export { DivisionLinkMenu }
